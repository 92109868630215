import ComunaSelector from '@/components/ComunaSelector'
import RegionSelector from '@/components/RegionSelector'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import type { Candidato } from '@/components/Web/ElectosTab'
import ElectosTab from '@/components/Web/ElectosTab'
import { PACTOS } from '@/constants/pactos'
import { jointCandidatosColor } from '@/lib/dataProcess'
import useElectionData from '@/stores/useElectionData'
import useRouting from '@/stores/useRouting'
import useTerritorios, { regiones } from '@/stores/useTerritorios'
import { ElectionType } from '@/types/Elections'
import { Search, X } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MonoLabel } from '../ParticipationBanner'
import { getPartidoDisplayName } from '@/lib/dataProcess'

export default function CElectoComuna() {
  const { currentRegion, currentComuna } = useRouting()
  const [filteredData, setFilteredData] = useState<Candidato[]>([])
  const [isSearch, setIsSearch] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const cregion = regiones.find((region) => region.codigo === currentRegion)
  const {
    files: { concejales },
    static: { escanos_concejales },
  } = useElectionData()

  const totalElectos = escanos_concejales && currentComuna ? escanos_concejales[currentComuna] : 0

  const navigate = useNavigate()

  const orderedConcejales = useMemo(
    () =>
      concejales
        ? concejales
            .filter((c) => c.cod_comuna.toString() === (currentComuna ?? '').toString())
            .sort((a, b) => b.porcentaje - a.porcentaje)
        : [],
    [concejales, currentComuna]
  )

  const processedData = useMemo(() => {
    if (orderedConcejales.length === 0) {
      return []
    }
    return jointCandidatosColor(orderedConcejales, ElectionType.CONCEJALES).map((electo, i) => ({
      ...electo,
      pacto: PACTOS[ElectionType.CONCEJALES][electo.cod_pacto]?.name || electo.cod_pacto,
      order: i + 1,
      imagen: electo.imagen || '/userProfile.png',
      region: cregion?.nombreCorto || cregion?.nombre || '',
      comuna: electo.comuna,
      partido: getPartidoDisplayName(electo.partido),
    }))
  }, [orderedConcejales])

  const { comunasColorDict, setComunaColor } = useTerritorios()

  useEffect(() => {
    if (!comunasColorDict[currentComuna as number] && processedData.length > 0) {
      setComunaColor({
        ...comunasColorDict,
        [currentComuna as number]: processedData.length > 1 ? processedData[0]?.color : '',
      })
    }
  }, [comunasColorDict, setComunaColor, processedData, currentComuna])

  const handleRegion = (region: string) => {
    const toRegion = regiones.find((r) => r.codigo === Number(region))
    navigate(`/${ElectionType.CONCEJALES}/territorio/${region}/${toRegion?.comunaDefecto}`)
  }

  const handleComuna = (comuna: string) => {
    navigate(`/${ElectionType.CONCEJALES}/territorio/${currentRegion}/${comuna}`)
  }

  useEffect(() => {
    if (searchValue !== '') {
      const filtered = processedData.filter((c) =>
        c.nombre.toLowerCase().includes(searchValue.toLowerCase())
      )
      setFilteredData(filtered)
    } else {
      setFilteredData(processedData)
    }
  }, [searchValue, processedData])

  return (
    <ElectosTab
      selection={
        <div className="w-full">
          <div className="flex gap-3 w-full">
            <RegionSelector
              className="hidden xl:flex xl:w-[50%]"
              onClick={handleRegion}
              value={String(currentRegion)}
            />
            <ComunaSelector
              className="hidden xl:flex xl:w-[50%]"
              region={currentRegion}
              onClick={handleComuna}
              value={String(currentComuna)}
              eleccion={ElectionType.CONCEJALES}
            />
          </div>

          {isSearch ? (
            <div className="relative w-full h-[27px] mt-1">
              <Search size={12} className="scale-x-[-1] left-2 absolute top-1/2 -translate-y-1/2" />
              <Input
                className="border-none shadow-none my-1 pl-7 font-mono uppercase text-[12px] h-7"
                placeholder="Buscar por nombre"
                autoFocus
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <X
                size={12}
                className="right-2 absolute top-1/2 -translate-y-1/2 cursor-pointer"
                onClick={() => {
                  setIsSearch(false)
                  setSearchValue('')
                }}
              />
            </div>
          ) : (
            <div className="flex justify-between mt-1 items-center w-full h-[27px]">
              <MonoLabel className="text-[11px] font-light text-gray3 uppercase">
                Candidatos {processedData.length} / SE ELIGEN {totalElectos}
              </MonoLabel>

              <Button
                variant={'ghost'}
                className="gap-1"
                size={'ghostSmall'}
                onClick={() => setIsSearch(true)}
              >
                <Search size={12} className="scale-x-[-1] text-gray3" />
                <MonoLabel className="font-light text-gray3 uppercase">Buscar</MonoLabel>
              </Button>
            </div>
          )}
        </div>
      }
      data={filteredData}
      title={'Electos por Región'}
    />
  )
}
