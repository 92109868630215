import GCambioMantienePacto, { type Data } from '@/components/Web/GORE/CambioMantienePacto'
import { buildCambioMantienePactoGORE } from '@/lib/buildDataViews'
import useElectionData from '@/stores/useElectionData'
import { regiones } from '@/stores/useTerritorios'
import { useMemo } from 'react'
import gore2021 from '@/assets/files/gore2021.json'
import { ElectionType } from '@/types/Elections'

export default function GoreCambioPacto() {
  const {
    files: { gore },
    segunda_vuelta: { resultados: resultados2V },
  } = useElectionData()

  const data: Data[] = useMemo(() => {
    const firstRound = buildCambioMantienePactoGORE(
      gore || [],
      gore2021,
      'cambia',
      19,
      ElectionType.GORE,
      true
    )
    const secondRound = buildCambioMantienePactoGORE(
      resultados2V || [],
      gore2021,
      'cambia',
      19,
      ElectionType.GORE_2V,
      false
    )
    return [...secondRound, ...firstRound]
  }, [gore, resultados2V])

  const regionesData = regiones
    .filter((region) => region.codigo !== 17)
    .sort((a, b) => {
      if (a.orden && b.orden) {
        return a.orden - b.orden
      }
      return 0
    })
    .map((region) => {
      const electo = data.find((item) => item.region_codigo === region.codigo)
      return {
        code: region.codigo,
        name: region.acronimo || region.nombre,
        realName: region.nombre || '',
        selectedColor: electo?.electo_2024.color || '',
        reverseBorderBackground: electo?.primera_vuelta || false,
      }
    })

  return (
    <GCambioMantienePacto
      title="Regiones que cambian sector político"
      data={data}
      regiones={regionesData}
      isMantiene={false}
    />
  )
}
