import MReelecto from '@/components/MagicWall/GORE/Reelecto'
import { formatFullName } from '@/helpers/formatters'
import useElectionData from '@/stores/useElectionData'
import { regiones } from '@/stores/useTerritorios'
import { useMemo } from 'react'
import { PACTOS } from '@/constants'
import { ElectionType } from '@/types/Elections'
import gore2021 from '@/assets/files/gore2021.json'

const REELECTOS_1V_REGIONES = [1, 16, 14, 12]

export default function GReelecto() {
  const {
    files: { gore },
    segunda_vuelta: { resultados: resultados2V },
  } = useElectionData()

  const data = useMemo(() => {
    const reelectos1V = (gore || []).filter((candidato) => candidato.reelecto)
    const reelectos2V = (resultados2V || []).filter((candidato) => candidato.reelecto)
    const reelectos = [...reelectos2V, ...reelectos1V].map((electo) => {
      const pacto = PACTOS[ElectionType.GORE_2V][electo.cod_pacto] || {}
      const electo2021 = gore2021[electo.cod_region.toString() as keyof typeof gore2021] || {}

      const percentage2021 = electo2021?.porcentaje || 0
      const region = regiones.find((region) => region.codigo === electo.cod_region)
      return {
        region_code: Number(electo.cod_region) || 0,
        region: region?.nombreCorto || region?.nombre || electo?.region || '',
        region_pacto: pacto.shortName || electo?.cod_pacto || '',
        color: pacto.color || '',
        imagen: electo.imagen || '/userProfile.png',
        electo_2024: {
          nombre: formatFullName(electo.nombre || ''),
          imagen: electo.imagen || '/userProfile.png',
          color: pacto.color || '',
          partido: electo.cod_partido,
        },
        porcentaje_2024: (electo?.porcentaje || 0).toFixed(1).replace('.', ',') + '%',
        porcentaje_2021: percentage2021.toFixed(1).replace('.', ',') + '%',
        primera_vuelta: reelectos1V.includes(electo) || false,
      }
    })
    return reelectos
  }, [gore, resultados2V])

  const regionesData = useMemo(() => {
    const orderedRegiones = regiones
      .filter((region) => region.codigo !== 17)
      .sort((a, b) => {
        if (a.orden && b.orden) {
          return a.orden - b.orden
        }
        return 0
      })

    return orderedRegiones.map((region) => {
      const reelectoRegion = data.find((item) => item.region_code === region.codigo)
      const regionElectos1V = REELECTOS_1V_REGIONES.includes(region.codigo)
      return {
        code: region.codigo,
        name: region.acronimo || region.nombre,
        selectedColor: reelectoRegion?.color || '',
        reverseBorderBackground: regionElectos1V,
      }
    })
  }, [data])

  return <MReelecto pageSize={4} data={data} regiones={regionesData} segundaVuelta={true} />
}
