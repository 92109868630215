import styled from 'styled-components'
import React from 'react'
import CheckIconSvg from '@/assets/checkIcon.svg'
import SegundaVSvg from '@/assets/2V.svg'
import { colors } from '@/constants'
import { cn } from '@/lib/utils'
import ReelectoBadge from '@/components/ReelectoBadge'
import { getCandidateDisplayName, getCandidateDisplayNameSeprated } from '@/lib/utils'
import { Skeleton } from '../ui/skeleton'
import FirstRoundSVG from '@/assets/1V.svg'

type ICandidateBadge = {
  imagen: string
  color: string
  upperCandidateLabel: React.ReactNode
  lowerCandidateLabel: React.ReactNode
  small?: boolean
  winner?: boolean
  firstRound?: boolean
}

type ICandidateBigBadge = {
  className?: string
  electo: Electo
  winner?: boolean
  segundaVuelta?: boolean
  reelecto?: boolean
  monoLabel: string
  labeled?: boolean
  full?: boolean
  isBlank?: boolean
  showSkeleton?: boolean
}

type ICandidateBadgeElect = ICandidateBadge & {
  winner?: boolean
  firstRound?: boolean
}

type Electo = {
  nombre: string
  partido: string
  pacto?: string
  cod_pacto?: string
  cod_partido?: string
  comuna?: string
  imagen: string
  color: string
  darkColor?: string
  electo?: boolean
  primera_vuelta?: boolean
}

export default function CandidateBadge({
  imagen,
  color,
  upperCandidateLabel,
  lowerCandidateLabel,
  small = false,
  winner,
  firstRound = false,
}: ICandidateBadge) {
  return (
    <div className="flex gap-1 items-center w-full">
      <div className="relative min-w-[30px]">
        <CandidateImage src={imagen} $borderColor={color} $small={small} />
        {winner && <CheckIconSmall />}
        {firstRound && (
          <FirstRoundSVG
            className={cn('absolute', {
              'scale[0.8]': !small,
              'w-[12px] right-0 top-[5px]': small,
            })}
          />
        )}
      </div>

      <div className="flex flex-col w-full ml-1">
        {upperCandidateLabel}
        {lowerCandidateLabel}
      </div>
    </div>
  )
}

export function CandidateBadgeWithCheck({
  imagen,
  color,
  upperCandidateLabel,
  lowerCandidateLabel,
  small = false,
  winner = false,
  firstRound = false,
}: ICandidateBadgeElect) {
  return (
    <div className="flex gap-2.5">
      <div className="relative overflow-visible">
        <CandidateImage src={imagen} $borderColor={color} $small={small} />
        {firstRound ? <FirstRoundSVG className="absolute right-0 top-[6px] w-[13px]" /> : null}
        {winner && !firstRound ? <CheckIconSmall /> : null}
      </div>
      <div className="flex flex-col justify-center">
        {upperCandidateLabel}
        {lowerCandidateLabel}
      </div>
    </div>
  )
}

export function ElectCandidateBadge({
  electo,
  small = false,
  isBlank = false,
  firstRound = false,
}: {
  electo: Electo
  small?: boolean
  isBlank?: boolean
  firstRound?: boolean
}) {
  const { nombre, pacto, imagen, color, partido } = electo

  const nameFormat = (nombreCompleto: string) => {
    const split = nombreCompleto.trim().split(' ')
    if (split.length > 1) {
      const inicialNombre = split[0].charAt(0) + '.'
      const apellido = split[1]
      return `${inicialNombre} ${apellido}`
    }
    return nombreCompleto
  }

  return (
    <CandidateBadge
      imagen={imagen}
      color={color}
      small={small}
      firstRound={firstRound}
      upperCandidateLabel={
        isBlank ? (
          <Skeleton className="w-[80%] h-2" />
        ) : (
          <div
            className={cn('font-medium', {
              'text-[13px]': small === false,
              'xl:text-[10px] text-[9px]': small === true,
            })}
          >
            {pacto || partido}
          </div>
        )
      }
      lowerCandidateLabel={
        isBlank ? (
          <Skeleton className="w-[70%] h-2 mt-1" />
        ) : (
          <div
            className={cn('font-light', {
              'text-[12px]': small === false,
              'xl:text-[9px] text-[8px]': small === true,
            })}
          >
            {nameFormat(nombre)}
          </div>
        )
      }
    />
  )
}

export function NameCandidateBadge({
  electo,
  small = false,
  isBlank = false,
}: {
  electo: Electo
  small?: boolean
  isBlank?: boolean
}) {
  const { nombre, imagen, color } = electo

  const { firstName, lastName, secondLastName } = getCandidateDisplayNameSeprated(nombre)

  return (
    <CandidateBadge
      imagen={imagen}
      color={color}
      small={small}
      upperCandidateLabel={
        isBlank ? (
          <Skeleton className="w-[80%] h-2" />
        ) : (
          <CandidateLabel className="font-light text-[11px]" $small={small}>
            {firstName}
          </CandidateLabel>
        )
      }
      lowerCandidateLabel={
        isBlank ? (
          <Skeleton className="w-[70%] h-2 mt-1" />
        ) : (
          <CandidateLabel className="font-medium text-[13px] capitalize" $small={small}>
            {lastName} {secondLastName.charAt(0)}.
          </CandidateLabel>
        )
      }
    />
  )
}

export function FullCandidateBadge({
  electo,
  small = false,
  size = 'normal',
  winner = false,
  monoLabel,
  isColored = true,
  showMonoLabel = true,
  isBlank = false,
}: {
  electo: Electo
  small?: boolean
  size?: 'normal' | 'big'
  winner?: boolean
  monoLabel?: string
  showMonoLabel?: boolean
  isColored?: boolean
  isBlank?: boolean
}) {
  const { nombre, cod_partido, imagen, pacto, color, comuna } = electo

  return (
    <CandidateBadge
      imagen={imagen}
      color={color}
      winner={winner}
      small={small}
      upperCandidateLabel={
        isBlank ? (
          <Skeleton className="w-[80%] h-3" />
        ) : (
          <>
            <div
              className={cn(
                'font-medium text-[10.5px] xl:text-[12px] min-w-[124px] leading-[13px] w-full',
                {
                  'text-[10.5px] xl:text-[13px] w-auto': size === 'big',
                }
              )}
            >
              {getCandidateDisplayName(nombre, true)}
            </div>
            {size === 'normal' && showMonoLabel && (
              <MonoLabelSmall className="text-[7.5px]">{monoLabel || comuna}</MonoLabelSmall>
            )}
          </>
        )
      }
      lowerCandidateLabel={
        isBlank ? (
          <Skeleton className="w-[80%] h-2 mt-2" />
        ) : isColored ? (
          <TagSmall
            $color={color}
            className={cn('text-[7px] rounded-[2px] px-1', {
              'text-[9px] py-[1px]': size === 'big',
            })}
          >{`${pacto} | ${cod_partido}`}</TagSmall>
        ) : (
          <div
            className={cn('text-[10px] rounded-[2px] font-light', {
              'text-[10px] font-light -mt-1 xl:mt-0': size === 'big',
            })}
          >
            {`${pacto} - ${cod_partido}`}
          </div>
        )
      }
    />
  )
}

export function SmCandidateBadge({
  electo,
  isRelected = false,
}: {
  electo: Electo
  isRelected?: boolean
}) {
  const { nombre, imagen, color, partido } = electo

  return (
    <CandidateBadge
      imagen={imagen}
      color={color}
      winner={false}
      small={false}
      upperCandidateLabel={
        <div
          className={cn('font-medium text-[12px] leading-[13px] w-full flex gap-1 items-center')}
        >
          {getCandidateDisplayName(nombre, true)}
          {isRelected && <RelectoBageSm $color={color}>REELECTO</RelectoBageSm>}
        </div>
      }
      lowerCandidateLabel={
        <div className="flex gap-1 items-center">
          <ColoredTag $color={color} className={cn('text-[9px]')}>{`${partido}`}</ColoredTag>
        </div>
      }
    />
  )
}

export function CandidateBigBadge({
  className,
  electo,
  winner,
  reelecto,
  monoLabel,
  labeled = false,
  full = false,
  isBlank = false,
  showSkeleton = false,
}: ICandidateBigBadge) {
  const { nombre, imagen, color, pacto, darkColor } = electo
  const { firstName, lastName, secondLastName } = getCandidateDisplayNameSeprated(nombre)
  return (
    <div className={`flex flex-col items-center gap-[3px] ${className}`}>
      <div className="relative flex justify-center items-center">
        <BigCandidateImage src={imagen} $borderColor={color} $full={full} />
        {winner && <CheckIconSm />}
        {reelecto && <ReelectoBadge id={`${color}`} color={color} />}
      </div>
      {isBlank ? (
        showSkeleton ? (
          <>
            <Skeleton className="w-[60%] h-2 mt-1" />
            <Skeleton className="w-[65%] h-3" />
            <Skeleton className="w-[45%] h-2" />
            <Skeleton className="w-[75%] h-4" />
          </>
        ) : (
          <div className="h-1"></div>
        )
      ) : (
        <>
          <div>
            <div className="lowercase first-letter:uppercase text-center text-[11px]">
              {firstName}
            </div>
            <div className="lowercase first-letter:uppercase text-[12px] mt-[-5px] mb-[-1px] font-medium">
              {lastName.slice(0, 13)} {lastName.length > 13 && '.'}
              <span className="capitalize">{secondLastName.charAt(0)}.</span>
            </div>
          </div>
          <MonoLabel>{monoLabel}</MonoLabel>
          {labeled && (
            <Tag $color={color} $darkColor={darkColor || color}>
              {pacto}
            </Tag>
          )}
        </>
      )}
    </div>
  )
}

export function RankingCandidateBigBadge({
  className,
  electo,
  winner,
  reelecto,
  monoLabel,
  labeled = false,
  full = false,
  segundaVuelta = false,
  isBlank = false,
}: ICandidateBigBadge) {
  const { nombre, imagen, color, pacto, darkColor } = electo

  const { firstName, lastName, secondLastName } = getCandidateDisplayNameSeprated(nombre)
  return (
    <div className={`flex flex-col items-center gap-1 ${className}`}>
      <div className="relative flex justify-center items-center">
        <BigCandidateImage src={imagen} $borderColor={color} $full={full} />
        {winner && <CheckIcon />}
        {reelecto && (
          <ReelectoBadge
            id={`ranking-${color}`}
            color={color}
            className="absolute -bottom-9 w-[66px] left-[2px]"
          />
        )}
        {segundaVuelta && <SVIcon />}
      </div>
      {isBlank ? (
        <div className="h-1" />
      ) : (
        <>
          <div>
            <div className="lowercase first-letter:uppercase text-center text-[11px]">
              {firstName}
            </div>
            <div className="capitalize text-[11px] mt-[-5px] mb-[-1px] font-medium text-nowrap overflow-hidden">
              {lastName.slice(0, 12)}
              {lastName.length > 12 && '.'}{' '}
              <span className="capitalize">{secondLastName.charAt(0)}.</span>
            </div>
          </div>
          <MonoLabel>{monoLabel}</MonoLabel>
          {labeled && (
            <Tag $color={color} $darkColor={darkColor || color}>
              {pacto}
            </Tag>
          )}
        </>
      )}
    </div>
  )
}

type ICandidateImage = { $small?: boolean; $borderColor: string }
const CandidateImage = styled.img.attrs<ICandidateImage>(({ $small }) => ({
  className: 'w-[28px] h-[28px] border-[2px]',
}))<ICandidateImage>`
  border-radius: 50%;
  object-fit: cover;
  border-color: ${({ $borderColor }) => $borderColor};
`

type ICandidateLabel = { $small?: boolean }
const CandidateLabel = styled.div<ICandidateLabel>(({ $small }) => ({
  className: $small ? 'text-[0.9em]' : 'text-[1em]',
}))

const BigCandidateImage = styled.img<{ $borderColor: string; $full: boolean }>`
  border-radius: 50%;
  width: ${({ $full }) => ($full ? '95%' : '75%')};
  max-width: 85px;
  max-height: 85px;
  object-fit: cover;
  border-color: ${({ $borderColor }) => $borderColor};
  border-width: 2px;
`

const CheckIcon = styled(CheckIconSvg)`
  position: absolute;
  width: 30%;
  right: -6%;
  bottom: -12%;
`

const CheckIconSm = styled(CheckIconSvg)`
  position: absolute;
  width: 25%;
  right: 3%;
  bottom: -12%;
`

const SVIcon = styled(SegundaVSvg)`
  position: absolute;
  width: 30%;
  right: 10%;
  bottom: -5%;
`

const CheckIconSmall = styled(CheckIconSvg)`
  position: absolute;
  width: 12px;
  right: 0;
  top: -3px;
`

const MonoLabel = styled.div`
  font-family: 'JetBrains Mono';
  text-transform: uppercase;
  text-align: center;
  font-size: 10px;
  color: ${colors.gray3};
  margin-bottom: 4px;
  height: 24px;
`

const MonoLabelSmall = styled.div`
  font-family: 'JetBrains Mono';
  text-transform: uppercase;
  font-size: 7.5px;
`

const Tag = styled.div.attrs<{ $color: string; $darkColor: string }>({
  className: 'text-[10px] min-w-[114px] w-full mt-auto',
})`
  height: fit-content;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  background-color: ${({ $color }) => `${$color}50`};
  color: ${({ $darkColor }) => $darkColor};
`

const TagSmall = styled.div<{ $color: string }>`
  height: fit-content;
  width: fit-content;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  background-color: ${({ $color }) => `${$color}50`};
  color: ${({ $color }) => $color};
`

const ColoredTag = styled.div<{ $color: string }>`
  height: fit-content;
  width: fit-content;
  text-align: center;
  font-weight: 500;
  color: ${({ $color }) => $color};
`

const RelectoBageSm = styled.div<{ $color: string }>`
  height: fit-content;
  width: fit-content;
  text-align: center;
  font-weight: 500;
  font-family: 'JetBrains Mono';
  color: white;
  padding: 0px 5px;
  border-radius: 10px;
  font-size: 8px;
  background-color: ${({ $color }) => $color};
`
