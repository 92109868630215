import Error from '@/components/Error'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Layout from '../components/Layout'
import IframeRouter from './Iframe/Router'
import IframeRouter2 from './Iframe2Vuelta/Router'
import MagicWallRouter from './MagicWall/Router'
import WebRouter from './Web/Router'

const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <Error />,
    children: [...WebRouter, ...IframeRouter, ...IframeRouter2, ...MagicWallRouter],
  },
])

export function Router() {
  return <RouterProvider router={router} />
}
