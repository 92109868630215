import HorizontalMap from '@/components/HorizontalMap'
import { colors } from '@/constants'
import PaginatedCard from '../PaginatedCard'
import React, { useState } from 'react'
import styled from 'styled-components'
import { ElectCandidateBadge } from '../CandidateBadget'

type Electo = {
  nombre: string
  imagen: string
  color: string
  partido: string
}

export type Data = {
  region: string
  region_codigo: number
  region_pacto?: string
  electo_2024: Electo
  electo_2021: Electo
  primera_vuelta?: boolean
}

type CambioPacto = {
  data: Data[]
  pageSize: number
  title: string
  regiones: {
    name: string
    selectedColor: string
    code: number
  }[]
}

export default function CambioMantienePacto({ title, data, pageSize, regiones }: CambioPacto) {
  const [currentPage, setCurrentPage] = useState(0)

  const totalPages = Math.min(Math.ceil(data.length / pageSize), 8)
  const paginatedData = data.slice(currentPage * pageSize, (currentPage + 1) * pageSize)

  return (
    <PaginatedCard
      title={title}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      totalPages={totalPages}
      separatorColor="#CCCCCC"
    >
      <Wrapper>
        <MapWrapper>
          <HorizontalMap
            selectionProperty="cod_comuna"
            position="center"
            regionResults={regiones}
          />
          <div className="flex w-full h-full justify-center gap-2 items-center">
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="10.5"
                cy="10.5"
                r="9.18979"
                fill="#FAFAFA"
                stroke="#4F4F4F"
                stroke-width="2.62042"
              />
            </svg>
            <TextMap>Electos en 1ra v.</TextMap>
          </div>
        </MapWrapper>
        <Table>
          <HeaderCell>REGIÓN</HeaderCell>
          <HeaderCell>2024</HeaderCell>
          <HeaderCell>2021</HeaderCell>
          <HeaderCell>REGIÓN</HeaderCell>
          <HeaderCell>2024</HeaderCell>
          <HeaderCell>2021</HeaderCell>
          {paginatedData.map((item, index) => (
            <React.Fragment key={`gore-tabla-${index}`}>
              <BodyCellRegion>
                {item.region}
                {item.region_pacto && <div className="font-normal">{item.region_pacto}</div>}
              </BodyCellRegion>
              <BodyCell>
                <ElectCandidateBadge
                  small
                  electo={item.electo_2024}
                  primera_vuelta={item.primera_vuelta}
                />
              </BodyCell>
              <BodyCell>
                <ElectCandidateBadge small electo={item.electo_2021} />
              </BodyCell>
            </React.Fragment>
          ))}
        </Table>
      </Wrapper>
    </PaginatedCard>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid: 1fr min-content / 1fr;
  height: 100%;
  width: 100%;
`

const MapWrapper = styled.div.attrs({
  className: 'mgw:py-[20px] mgw:px-[100px] px-[60px] py-[10px]',
})`
  height: 100%;
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
`

const Table = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(150px, 0.7fr) minmax(150px, 1.15fr) minmax(150px, 1.15fr));
  grid-template-rows: minmax(0, min-content) repeat(2, minmax(110px, min-content));

  & > :nth-of-type(3n) {
    background-color: ${colors.gray1Dch};
  }

  & > :nth-of-type(3n):not(:nth-of-type(2n + 1)) {
    padding-right: 20px;
  }

  & > :nth-of-type(3n):not(:nth-of-type(6n)) {
    border-right: 2px solid ${colors.gray3Dch};
  }

  & > :nth-of-type(3n + 1):not(:nth-of-type(2n)) {
    padding-left: 20px;
  }

  & > :not(:nth-child(n + 13)) {
    border-bottom: 2px solid ${colors.gray3Dch};
  }
`

const HeaderCell = styled.div.attrs({
  className: 'mgw:text-[24px] text-[18px] mgw:py-[20px] mgw:pl-[20px] p-[15px]',
})`
  font-family: 'JetBrains Mono';
  font-weight: 600;
  line-height: 32px;
  color: ${colors.blackDch};
  min-height: 76px;
`

const BodyCellRegion = styled.div.attrs({
  className: 'mgw:text-[24px] text-[18px] mgw:p-[20px] p-[15px]',
})`
  font-weight: 700;
  line-height: 32px;
  color: ${colors.blackDch};
  min-height: 112px;
`

const BodyCell = styled.div.attrs({
  className: 'w-full h-full mgw:p-[20px] p-[15px]',
})`
  min-height: 112px;
`

const TextMap = styled.div`
  font-size: 18px;
  color: ${colors.blackDch};
  font-weight: 500;
  text-transform: uppercase;
`
