import styled from 'styled-components'
import { colors } from '@/constants/colors'
import LogoDch from '@/assets/logoDch.svg'
import { useEffect, useState } from 'react'
import Switch from '@/components/Iframe/Switch'
import GobernadoresGanadores from '@/components/Iframe2Vuelta/GobernadoresGanadores'
import GobernadoresPactos from '@/components/Iframe2Vuelta/GobernadoresPactos'
import Arrow from '@/assets/arrow.svg'
import useElectionData from '@/stores/useElectionData'
import { getGoreVersusSummary } from '@/helpers/iframe'
import { numberToPercent } from '@/helpers/formatters'
import Countdown from '@/components/Iframe2Vuelta/Countdown'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

ReactGA.initialize(import.meta.env.VITE_GA_MEASUREMENT_ID)

export default function Iframe() {
  const currentDateTime = new Date()
  const votationStartDt = new Date(Date.UTC(2024, 10, 24, 11, 0, 0)) // At 8 AM UTC-3
  const [hasVotationStarted, setHasVotationStarted] = useState(currentDateTime >= votationStartDt)

  const location = useLocation()
  const shouldBypass = Boolean(new URLSearchParams(location.search).get('bypass'))
  const medio = new URLSearchParams(location.search).get('medio') || 'no_medio'

  const [isPactosSelected, setIsPactosSelected] = useState(false)
  const { updated_at, files, comparador, participation, segunda_vuelta } = useElectionData()
  const date = new Date(segunda_vuelta.updated_at || Date.now())
  const formattedUpdatedAt = date.toTimeString().split(' ')[0].slice(0, 5)

  const goreVersusSummary = getGoreVersusSummary(segunda_vuelta.resultados) // TODO: Add default for case zero results

  useEffect(() => {
    ReactGA.event('if_visita', { medio: medio })
  }, [medio])

  const currentParticipation = segunda_vuelta.participacion

  return (
    <div className="w-[320px] sifr:w-[450px] h-[360px] flex flex-col justify-between overflow-hidden">
      <Helmet>
        <title>DecideChile 2024 Iframe</title>
      </Helmet>
      {shouldBypass || hasVotationStarted ? (
        <>
          <Wrapper>
            <div className="self-end absolute top-[-2px] right-[-2px]">
              <a
                className="relative"
                href="https://decidechile.cl/municipales2024"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => ReactGA.event('if_municipales2024_redireccion', { medio: medio })}
              >
                <LogoDch className="w-[100px] sifr:w-[138px] h-auto" />
              </a>
            </div>
            <Title>
              <div className="text-[10px] sifr:text-[15px] ">Segunda Vuelta</div>
              <span className="font-bold">Gobernadores Regionales</span>
            </Title>
            <StatusBar className="flex-shrink-0">
              <div>
                Actualizado: <b>{formattedUpdatedAt} hrs</b>
              </div>
              <div className="font-bold">
                Escrutado:{' '}
                <Badge>
                  {numberToPercent(currentParticipation?.porcentaje_mesas_escrutadas || 0, 2)}
                </Badge>
              </div>
              <div>
                Participación:{' '}
                <b>{numberToPercent(currentParticipation?.participacion_porcentual || 0, 2)}</b>
              </div>
            </StatusBar>

            <ElectionResultsSwitch>
              <Switch
                selected={!isPactosSelected}
                onClick={() => {
                  ReactGA.event('if_gores_versus_mostrar', { medio: medio })
                  setIsPactosSelected(false)
                }}
              >
                SEGUNDA VUELTA
              </Switch>
              <Switch
                selected={isPactosSelected}
                onClick={() => {
                  ReactGA.event('if_gores_electosporpacto_mostrar', { medio: medio })
                  setIsPactosSelected(true)
                }}
              >
                ELECTOS POR PACTO
              </Switch>
            </ElectionResultsSwitch>

            {!isPactosSelected && <GobernadoresGanadores summaryGore={goreVersusSummary} />}
            {isPactosSelected && (
              <GobernadoresPactos
                primeraVuelta={files?.gore || []}
                segundaVuelta={segunda_vuelta.resultados || []}
              />
            )}
          </Wrapper>
          <Footer>
            Más información y análisis en
            <a
              className="relative"
              href="https://decidechile.cl/municipales2024"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => ReactGA.event('if_municipales2024_redireccion', { medio: medio })}
            >
              <FooterBadge>decidechile.cl/municipales2024</FooterBadge>
              <Arrow className="absolute top-[2px] sifr:top-[4px] -right-[13px] sifr:-right-[16px] ifr:top-3 ifr:-right-5 transform scale-[0.6] sifr:scale-75 ifr:scale-100" />
            </a>
          </Footer>
        </>
      ) : (
        <Countdown
          votationStartDt={votationStartDt}
          setHasVotationStarted={setHasVotationStarted}
          medio={medio}
        ></Countdown>
      )}
    </div>
  )
}

const Wrapper = styled.div.attrs({
  className: 'h-[336px] sifr:h-[328px] gap-[8px] sifr:gap-[10px]',
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid ${colors['blueDch']};
  position: relative;
  padding-inline: 10px;
  padding-block: 10px;
`

const StatusBar = styled.div.attrs({
  className:
    'w-full h-[14px] sifr:h-[18px] ifr:h-[24px] text-[8px] sifr:text-[10px] ifr:text-[13px]',
})`
  background-color: ${colors['statusIfr']};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
  color: ${colors['blackDch']};
`

const Badge = styled.div.attrs({
  className: 'h-[12px] sifr:h-[14px] ifr:h-[18px] text-[8px] sifr:text-[10px] ifr:text-[13px]',
})`
  background-color: ${colors['blackDch']};
  color: white;
  border-radius: 2px;
  display: inline-block;
  padding-inline: 2px;
`

const ElectionResultsSwitch = styled.div.attrs({
  className:
    'w-full h-[22px] ifr:h-[28px] text-[13px] ifr:text-[17px] grid grid-cols-2 justify-center',
})`
  color: ${colors['blackDch']};
`

const Footer = styled.div.attrs({
  className: 'w-full h-[24px] sifr:h-[32px] text-[10px] sifr:text-[14px]',
})`
  background-color: ${colors['footerDch']};
  color: ${colors['whiteDch']};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`

const FooterBadge = styled.button.attrs({
  className: 'h-[16px] sifr:h-[24px] ifr:h-[30px] ifr:leading-[30px]',
})`
  background-color: ${colors['blueDch']};
  color: ${colors['whiteDch']};
  border: 1px solid ${colors['whiteDch']};
  border-radius: 7px;
  display: inline-block;
  padding-inline: 2px;
  font-weight: bold;
  padding: 0 8px;
`

const Title = styled.div.attrs({
  className: 'text-[17px] sifr:text-[25px] self-start leading-[1]',
})`
  color: ${colors['blackDch']};
`
