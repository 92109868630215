import { electionParams, Tema, TEMAS } from '@/constants'
import { ElectionType } from '@/types/Elections'
import { colors } from '@/constants'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import PByUnholsterBase from '@/assets/pByUnholsterWhite.svg'
import CNNLogoBase from '@/assets/logoCNNWhite.svg'
import BarChart from '@/assets/barChart.svg'
import '@leenguyen/react-flip-clock-countdown/dist/index.css'
import Vote from '@/assets/vote.svg'

const SECTIONS = [
  {
    title: 'población',
    icon: '/poblacion.svg',
    id: 'poblacion',
  },
  {
    title: 'extranjeros',
    id: 'extranjeros',
    icon: '/inmigracion.svg',
  },
  {
    title: 'vivienda',
    id: 'vivienda',
    icon: '/vivienda.svg',
  },
  {
    title: 'educación',
    id: 'educacion',
    icon: '/educacion.svg',
  },
  {
    title: 'política',
    id: 'politica',
    icon: '/politica.svg',
  },
  {
    title: 'salud',
    id: 'salud',
    icon: '/salud.svg',
  },
  {
    title: 'seguridad',
    id: 'seguridad',
    icon: '/seguridad.svg',
  },
  {
    title: 'niñez',
    id: 'ninez',
    icon: '/ninez.svg',
  },
  {
    title: 'análisis',
    id: 'analisis',
    icon: '/analisis.svg',
  },
]

export default function ChileEnDatos() {
  const navigate = useNavigate()
  const handleBtnClick = (tema: string) => {
    const primerSubtema = Object.keys(TEMAS[tema as Tema].subtemas)[0]
    const comunaDefault = electionParams[ElectionType.ALCALDES].defaultComuna
    navigate(`${tema}/${primerSubtema}/${comunaDefault}`)
  }

  return (
    <Wrapper>
      <Top>
        <CNNLogo />
        <div className="ml-10 block mgw:hidden">
          <ButtonElectiones onClick={() => navigate('/magic/elecciones')}>
            <Vote />
          </ButtonElectiones>
        </div>
      </Top>
      <div className="flex flex-col items-center ">
        <div className="flex items-center gap-6 mb-[30px]">
          <BarChart />
          <MonoLabel className="uppercase font-medium text-[40px] text-[#E0E0E0]">
            Datos Comunales
          </MonoLabel>
        </div>

        <div className="flex items-end justify-center pb-[30px]">
          <Buttons className="mgw:max-w-[1200px] mgw:translate-x-[74px] w-[100%]">
            {SECTIONS.map((section, index) => (
              <Button key={section.title} onClick={() => handleBtnClick(section.id)}>
                <img src={section.icon} alt={section.title} className="h-10 mb-[10px]" />
                <ButtonTitle>{section.title}</ButtonTitle>
              </Button>
            ))}
          </Buttons>
          <div className="hidden mgw:block translate-x-[200px]">
            <ButtonElectiones onClick={() => navigate('/magic/elecciones')}>
              <Vote />
            </ButtonElectiones>
          </div>
        </div>
      </div>
      {/* <Bottom>
        <div className="flex gap-x-[50px] items-start justify-center flex-wrap">
          <div className="text-center">
            <p className="text-gris3Dch mgw:text-[48px] font-bold text-[38px]">Cierre de Mesas</p>
            <p className="text-gris3Dch mgw:text-[32px] text-[22px]">
              Domingo 27 de Octubre, 18:00hrs
            </p>
          </div>
          <div className="hidden xl:block">
            <FlipClockCountdown
              to={new Date('2024-10-27T18:00:00').getTime()}
              labels={['Días', 'Horas', 'Min.', 'Seg.']}
              showSeparators={false}
              digitBlockStyle={{ backgroundColor: '#3b3b55', color: '#fff', borderRadius: '10px' }}
              dividerStyle={{ height: 0 }}
              renderMap={[true, true, true, true]}
              labelStyle={{ fontSize: '30px', fontWeight: 500, color: '#3b3b55' }}
            />
          </div>
        </div>
        <PByUnholster />
      </Bottom> */}
      <Bottom>
        <div className="flex items-end justify-center h-full w-full">
          <PByUnholster />
        </div>
      </Bottom>
    </Wrapper>
  )
}

const Wrapper = styled.div.attrs({
  className: 'mgw:p-[80px] mgw:gap-[80px] pb-[188px] p-[60px] gap-[60px] ',
})`
  display: grid;
  grid: 1fr 1fr 1fr/ 1fr;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: ${colors.frame};
`

const Top = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CNNLogo = styled(CNNLogoBase).attrs({
  className: 'mgw:w-[280px] w-[200px]',
})``

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  justify-content: center;
  gap: 15px;
  height: 100%;
`

const Button = styled.button.attrs({
  className: 'w-[200px] h-[149px] ',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  background-color: #364277;
  box-shadow: 3px 3px 0px 3px ${colors.frameBorderButton};
`

const ButtonTitle = styled.div.attrs({
  className: 'mgw:text-[20px] text-[18px]',
})`
  font-weight: 600;
  color: ${colors.whiteDch};
  line-height: 26px;
  text-transform: uppercase;
`

const Bottom = styled.div`
  display: grid;
  grid: 1fr / 1fr min-content;
  justify-content: center;
  align-items: end;
  height: 100%;
  width: 100%;
`
// STYLE FOR COUNTDOWN
// const Bottom = styled.div`
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   min-height: 188px;
//   width: 100%;
//   background-color: #e0e0e0;
// `

const ButtonElectiones = styled.button.attrs({
  className: 'mgw:w-[142px] mgw:h-[122px] w-[122px] h-[104px]',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #364277;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 3px 3px 0px 3px ${colors.frameBorderButton};
`

// STYLE FOR COUNTDOWN
// const PByUnholster = styled(PByUnholsterBase).attrs({
//   className:
//     'mgw:h-[44px] mgw:w-[217px] h-[34px] w-[170px] xl:absolute xl:bottom-[73px] xl:right-[46px] ml-[40px]',
// })``

const PByUnholster = styled(PByUnholsterBase).attrs({
  className: 'mgw:h-[44px] mgw:w-[217px] h-[34px] w-[170px]',
})``

const MonoLabel = styled.div`
  font-family: 'JetBrains Mono';
`
