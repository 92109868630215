import type {
  Alcaldes2021,
  Candidato,
  CandidatoCore,
  CandidatoGore,
  Concejales2021,
  Core2021,
} from '@/types/Files'
import { ElectionType } from '@/types/Elections'
import { PACTOS, PACTOS_CORE } from '@/constants/pactos'
import { regiones } from '@/stores/useTerritorios'
import { getRealRegionCode } from '@/app/MagicWall/GORE/ElectosRegion'
import { compareCORECircunscripcion, removeTildesExceptN } from '@/helpers/formatters'

type CandidatoKey = keyof Candidato

export const getCandidatoCountByGroupBy = (
  data: Candidato[],
  countByCategory: CandidatoKey,
  countByCriteria: string,
  groupBy: CandidatoKey
) => {
  return data.reduce((acc, candidato) => {
    if (countByCategory === 'cod_region') {
      const region = regiones.find((r) => r.codigo === Number(countByCriteria))
      const comunas = region?.comunas || []
      const comunaCount = comunas.reduce((comunaAcc, comuna) => {
        if (candidato.cod_comuna.toString() === comuna.toString()) {
          const groupKey = candidato[groupBy] as string
          if (comunaAcc[groupKey]) {
            comunaAcc[groupKey] += 1
          } else {
            comunaAcc[groupKey] = 1
          }
        }
        return comunaAcc
      }, {} as Record<string, number>)

      for (const [key, value] of Object.entries(comunaCount)) {
        if (acc[key]) {
          acc[key] += value
        } else {
          acc[key] = value
        }
      }
      return acc
    }
    if (candidato[countByCategory].toString() === countByCriteria) {
      const groupKey = candidato[groupBy] as string

      if (acc[groupKey]) {
        acc[groupKey] += 1
      } else {
        acc[groupKey] = 1
      }
    }
    return acc
  }, {} as Record<string, number>)
}

// Only for Alcalde
export function getRegionResultsByPactoAlcalde(
  data2024: Candidato[],
  data2021: Alcaldes2021,
  territorios: number[]
) {
  const electos = data2024.filter((candidato) => candidato.electo)
  const countElectosByPacto = electos.reduce((acc, electo) => {
    if (territorios.includes(Number(electo.cod_comuna))) {
      if (!acc[electo.cod_pacto]) acc[electo.cod_pacto] = 1
      else acc[electo.cod_pacto] += 1
    }
    return acc
  }, {} as Record<string, number>)

  const countElectosByPacto2021 = territorios.reduce((acc, territorio) => {
    const territorio2021 = data2021.electos_pacto[territorio] || { sigla_pacto: '', electos: 0 }
    if (!acc[territorio2021[0].sigla_pacto])
      acc[territorio2021[0].sigla_pacto] = territorio2021[0].electos
    else acc[territorio2021[0].sigla_pacto] += territorio2021[0].electos
    return acc
  }, {} as Record<string, number>)
  return {
    countElectosByPacto,
    countElectosByPacto2021,
  }
}

// Only for Concejales
export function getRegionResultsByPactoConcejales(
  data2024: Candidato[],
  data2021: Concejales2021,
  territorios: number[]
) {
  const electos = data2024.filter((candidato) => candidato.electo)
  const countElectosByPacto = electos.reduce((acc, electo) => {
    if (territorios.includes(Number(electo.cod_comuna))) {
      if (!acc[electo.cod_pacto]) acc[electo.cod_pacto] = 1
      else acc[electo.cod_pacto] += 1
    }
    return acc
  }, {} as Record<string, number>)

  const countElectosByPacto2021 = territorios.reduce((acc, territorio) => {
    const territorio2021 = data2021.electos_pacto[territorio] || [{ sigla_pacto: '', electos: 0 }]
    territorio2021.forEach(({ sigla_pacto, electos }) => {
      if (!acc[sigla_pacto]) acc[sigla_pacto] = 0
      acc[sigla_pacto] += electos
    })
    return acc
  }, {} as Record<string, number>)

  return {
    countElectosByPacto,
    countElectosByPacto2021,
  }
}

// Only for CORE
export function getRegionResultsByPactoCORE(
  data2024: CandidatoCore[],
  data2021: Core2021,
  circunscripcion: string,
  comunas: number[]
) {
  const electos = data2024.filter(
    (candidato) =>
      candidato.electo &&
      compareCORECircunscripcion(circunscripcion, candidato.cod_circunscripcion || '')
  )

  const countElectosByPacto = electos.reduce((acc, electo) => {
    if (!acc[electo.cod_pacto]) acc[electo.cod_pacto] = 1
    else acc[electo.cod_pacto] += 1
    return acc
  }, {} as Record<string, number>)

  const countElectosByPacto2021: Record<string, number> = {}
  // NEVER REPEAT THIS CODE, IT'S A MESS
  const electos2021Pacto =
    data2021.electos_pacto[circunscripcion.toUpperCase()] ||
    data2021.electos_pacto[circunscripcion] ||
    data2021.electos_pacto[removeTildesExceptN(circunscripcion).toUpperCase()] ||
    data2021.electos_pacto[removeTildesExceptN(circunscripcion)]
  electos2021Pacto?.forEach((pacto) => (countElectosByPacto2021[pacto.sigla_pacto] = pacto.electos))

  const comunasColor: Record<number, string> = {}
  comunas.forEach((comuna) => {
    const orderedByVotos = electos.sort(
      (a, b) => b?.resultados_comuna[comuna]?.votos - a.resultados_comuna[comuna]?.votos
    )
    comunasColor[comuna] = PACTOS_CORE[orderedByVotos[0]?.cod_pacto]?.color || ''
  })

  return {
    countElectosByPacto,
    countElectosByPacto2021,
    comunasColor,
  }
}

export const getTerritorioColorDictSimple = (
  data: Candidato[],
  eleccion: ElectionType
): Record<number, string> => {
  // Filter electos
  const electos = data.filter((candidato) => candidato.electo)

  return electos.reduce((acc, candidato) => {
    acc[Number(candidato.cod_comuna)] = PACTOS[eleccion][candidato.cod_pacto]?.color || ''
    return acc
  }, {} as Record<number, string>)
}

// Only for GORE
export const getTerritorioColorDictByMajority = (
  data: CandidatoGore[],
  region: number,
  eleccion: ElectionType
) => {
  // filter by region
  const candidatos = data.filter(
    (c) => c.cod_region.toString() === getRealRegionCode(region).toString()
  )

  if (candidatos.length === 0) return {}

  const pactos = PACTOS[eleccion]

  // Get all comunas
  const comunas = Array.from(Object.keys(candidatos[0]?.resultados_comuna))

  const result: Record<number, string> = {}
  // count electos by pacto by comuna
  comunas.forEach((comuna) => {
    // get candidate with most votes in comuna
    const orderCandidatos = candidatos.sort(
      (a, b) => b.resultados_comuna[comuna].votos - a.resultados_comuna[comuna].votos
    )
    const majorityPacto = orderCandidatos[0].cod_pacto
    result[Number(comuna)] = pactos[majorityPacto]?.color || ''
  })

  return result
}

export const jointCandidatosColor = (
  candidatos: Candidato[] | CandidatoCore[],
  eleccion: ElectionType
) => {
  return candidatos.map((c) => ({
    ...c,
    color: PACTOS[eleccion][c.cod_pacto]?.color || '#4f4f4f',
    darkColor: PACTOS[eleccion][c.cod_pacto]?.darkColor || '#4f4f4f',
  }))
}

export function getResultsByPacto(candidatos: Candidato[] | CandidatoCore[]) {
  return candidatos.reduce((acc, candidato) => {
    const { electo, cod_partido, cod_pacto, votos } = candidato
    // Set initial values for pacto
    if (!acc[cod_pacto]) {
      acc[cod_pacto] = {
        electos: 0,
        votos: 0,
      }
    }
    // Set initial values for partido
    if (!acc[cod_pacto][cod_partido]) acc[cod_pacto][cod_partido] = 0

    // Add values
    if (electo) acc[cod_pacto][cod_partido] += 1
    if (electo) acc[cod_pacto].electos += 1
    acc[cod_pacto].votos += votos

    return acc
  }, {} as Record<string, { [key: string]: number }>)
}

export const getPartidoDisplayName = (partidos: string) => {
  switch (partidos.toUpperCase()) {
    case 'FEDERACION REGIONALISTA VERDE SOCIAL':
      return 'F. REGIONALISTA VERDE SOCIAL'
    default:
      return partidos
  }
}
