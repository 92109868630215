import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import PByUnholsterBase from '@/assets/pByUnholsterWhite.svg'
import CNNLogoBase from '@/assets/logoCNNWhite.svg'
import House from '@/assets/house.svg'
import TriangleArrowSVG from '@/assets/triangleArrow.svg'
import { colors, electionParams } from '@/constants'
import { ElectionType } from '@/types/Elections'
import React from 'react'
import useElectionData from '@/stores/useElectionData'
import { numberWithDots } from '@/helpers/formatters'
import { ParticipationFile } from '@/types/Files'

const SECTIONS = {
  [ElectionType.GORE]: '1ra Vuelta Gores',
  [ElectionType.ALCALDES]: electionParams[ElectionType.ALCALDES].name,
  [ElectionType.CONCEJALES]: electionParams[ElectionType.CONCEJALES].name,
  [ElectionType.CORE]: electionParams[ElectionType.CORE].name,
  [ElectionType.GORE_2V]: '2da Vuelta Gores',
}

type Row = {
  title: string
  percentage_2021: string | number
  percentage_2024: string | number
  votes_2021: string | number
  votes_2024: string | number
  compare: 'up' | 'down' | 'equal'
}

const compare2021and2024 = (before: number, after: number) => {
  return before < after ? 'up' : before > after ? 'down' : 'equal'
}

const participacionGORE2021 = {
  total_inscritos: 14900190,
  votos_escrutados: 6472470,
  votos_validos: 0,
  abstenciones: 8427720,
  nulos: 139999,
  blancos: 257073,
  votos_escrutados_porcentaje: 43.4,
  votos_validos_porcentaje: 0,
  abstenciones_porcentaje: 56.6,
  nulos_porcentaje: 2.2,
  blancos_porcentaje: 4,
}

const totalInscritos2021 = {
  [ElectionType.CORE]: 14959956,
  [ElectionType.ALCALDES]: 14900190,
  [ElectionType.CONCEJALES]: 14900190,
}

export function create2VData2021(resultsGore: ParticipationFile) {
  const total2024 = 15450377
  const noVotan = total2024 - (resultsGore.votos || 0)
  return {
    total_inscritos: total2024,
    votos_escrutados: resultsGore.votos || 0,
    votos_escrutados_porcentaje: Number(resultsGore.participacion_porcentual.toFixed(1) || 0),
    nulos: resultsGore.votos_nulos || 0,
    nulos_porcentaje: Number(
      (((resultsGore.votos_nulos || 0) * 100) / (resultsGore.votos || 1)).toFixed(1)
    ),
    blancos: resultsGore.votos_blancos,
    blancos_porcentaje: Number(
      (((resultsGore.votos_blancos || 0) * 100) / (resultsGore.votos || 1)).toFixed(1)
    ),
    abstenciones: noVotan,
    abstenciones_porcentaje: Number(((noVotan * 100) / (total2024 || 1)).toFixed(1)),
  }
}

function calculatePercentage(amount: number | undefined, total: number | undefined) {
  if (!total || !amount) return '-'
  return ((amount * 100) / total).toFixed(1).replace('.', ',')
}

export default function MagicWall() {
  const { eleccion } = useParams()
  const {
    comparador,
    participation,
    segunda_vuelta: { participacion: participacion2V },
  } = useElectionData()
  const navigate = useNavigate()

  const data2021 =
    eleccion === ElectionType.GORE_2V
      ? create2VData2021(
          participation[ElectionType.GORE] || {
            porcentaje_mesas_escrutadas: 0,
            votos: 0,
            votos_blancos: 0,
            votos_nulos: 0,
            participacion_porcentual: 0,
            inscritos: 0,
            resultados_comuna: {},
          }
        )
      : eleccion === ElectionType.GORE
      ? participacionGORE2021
      : comparador[eleccion as keyof typeof comparador]?.participacion

  if (data2021?.total_inscritos) {
    data2021.total_inscritos =
      totalInscritos2021[eleccion as keyof typeof totalInscritos2021] || data2021.total_inscritos
  }
  const currentParticipation =
    eleccion !== ElectionType.GORE_2V
      ? participation[eleccion as keyof typeof participation]
      : participacion2V

  const total2024 =
    eleccion !== ElectionType.GORE_2V ? 15450377 : participacion2V?.inscritos || 14116702
  const noVotan = React.useMemo(
    () => total2024 - (currentParticipation?.votos || 0),
    [total2024, currentParticipation]
  )

  const data: Row[] = React.useMemo(
    () => [
      {
        title: 'Inscritos',
        percentage_2021: '-',
        percentage_2024: '-',
        votes_2021: data2021?.total_inscritos ? numberWithDots(data2021?.total_inscritos) : '-',
        votes_2024: numberWithDots(total2024),
        compare: compare2021and2024(data2021?.total_inscritos || 0, 15450377),
      },
      {
        title: 'Participación',
        percentage_2021: calculatePercentage(data2021?.votos_escrutados, data2021?.total_inscritos),
        percentage_2024: currentParticipation?.participacion_porcentual.toFixed(1) || '0,0',
        votes_2021: data2021?.votos_escrutados ? numberWithDots(data2021?.votos_escrutados) : '-',
        votes_2024: numberWithDots(currentParticipation?.votos || 0),
        compare: compare2021and2024(
          data2021?.votos_escrutados || 0,
          currentParticipation?.votos || 0
        ),
      },
      {
        title: 'Nulos',
        percentage_2021: data2021?.nulos_porcentaje
          ? numberWithDots(data2021?.nulos_porcentaje)
          : '-',
        percentage_2024: currentParticipation?.votos
          ? (
              ((currentParticipation?.votos_nulos || 0) * 100) /
              currentParticipation?.votos
            ).toFixed(1)
          : '0,0',
        votes_2021: data2021?.nulos ? numberWithDots(data2021?.nulos) : '-',
        votes_2024: numberWithDots(currentParticipation?.votos_nulos || 0),
        compare: compare2021and2024(data2021?.nulos || 0, currentParticipation?.votos_nulos || 0),
      },
      {
        title: 'Blancos',
        percentage_2021: data2021?.blancos_porcentaje
          ? numberWithDots(data2021?.blancos_porcentaje)
          : '-',
        percentage_2024: currentParticipation?.votos
          ? (
              ((currentParticipation?.votos_blancos || 0) * 100) /
              currentParticipation?.votos
            ).toFixed(1)
          : '0,0',
        votes_2021: data2021?.blancos ? numberWithDots(data2021?.blancos) : '-',
        votes_2024: numberWithDots(currentParticipation?.votos_blancos || 0),
        compare: compare2021and2024(
          data2021?.blancos || 0,
          currentParticipation?.votos_blancos || 0
        ),
      },
      {
        title: 'No votan',
        percentage_2021: calculatePercentage(
          (data2021?.total_inscritos || 0) - (data2021?.votos_escrutados || 0),
          data2021?.total_inscritos
        ),
        percentage_2024: currentParticipation?.inscritos
          ? ((noVotan * 100) / currentParticipation?.inscritos).toFixed(1)
          : '0,0',
        votes_2021: numberWithDots(
          (data2021?.total_inscritos || 0) - (data2021?.votos_escrutados || 0)
        ),
        votes_2024: numberWithDots(noVotan),
        compare: compare2021and2024(data2021?.abstenciones || 0, noVotan),
      },
    ],
    [
      data2021?.total_inscritos,
      data2021?.votos_escrutados,
      data2021?.nulos_porcentaje,
      data2021?.nulos,
      data2021?.blancos_porcentaje,
      data2021?.blancos,
      data2021?.abstenciones,
      total2024,
      currentParticipation?.participacion_porcentual,
      currentParticipation?.votos,
      currentParticipation?.votos_nulos,
      currentParticipation?.votos_blancos,
      currentParticipation?.inscritos,
      noVotan,
    ]
  )
  return (
    <Wrapper>
      <Top>
        <CNNLogo />
      </Top>
      <Middle>
        <Participacion>
          <div className="w-[500px]">
            <Title>
              <div>Participación</div>
              {SECTIONS[eleccion as keyof typeof SECTIONS]}
            </Title>
            <ContainerMesa>
              <Percentage>
                {(currentParticipation?.porcentaje_mesas_escrutadas || 0)
                  .toFixed(1)
                  .replace('.', ',')}
                %
              </Percentage>
              <MesasEscrutadas>MESAS ESCRUTADAS</MesasEscrutadas>
            </ContainerMesa>
          </div>
          <Stats>
            <div className="col-span-1 row-span-2"></div>
            <GridYearType className="col-span-2">
              <GridYearContent>{eleccion === ElectionType.GORE_2V ? '' : 2021}</GridYearContent>
              <GridTypeContent>
                {eleccion === ElectionType.GORE_2V ? '1ra vuelta gores' : 'voluntario'}
              </GridTypeContent>
            </GridYearType>
            <div /> {/* Empty cell */}
            <GridYearType className="col-span-2">
              <GridYearContent>{eleccion === ElectionType.GORE_2V ? '' : 2024}</GridYearContent>
              <GridTypeContent>
                {eleccion === ElectionType.GORE_2V ? '2da vuelta gores' : 'obligatorio'}
              </GridTypeContent>
            </GridYearType>
            <GridNumberSymbol>Nº</GridNumberSymbol>
            <GridNumberSymbol>%</GridNumberSymbol>
            <div /> {/* Empty cell */}
            <GridNumberSymbol>Nº</GridNumberSymbol>
            <GridNumberSymbol>%</GridNumberSymbol>
            {data.map(
              ({ title, percentage_2021, percentage_2024, votes_2021, votes_2024, compare }) => (
                <React.Fragment key={`participacion-${title}`}>
                  <GridCellTitle>
                    {(title === 'Nulos' || title === 'Blancos') && (
                      <div className="mgw:pl-6 pl-4">
                        <div className="bg-white h-2 aspect-square" />
                      </div>
                    )}
                    {title}
                  </GridCellTitle>
                  <GridBodyCell>{votes_2021}</GridBodyCell>
                  <GridBodyCell>
                    {percentage_2021}
                    {percentage_2021 !== '-' && <sup className="mt-[16px]">%</sup>}
                  </GridBodyCell>
                  <GridBodyCell>
                    {compare !== 'equal' && <TriangleArrow direction={compare} />}
                  </GridBodyCell>
                  <GridBodyCell>{votes_2024}</GridBodyCell>
                  <GridBodyCell>
                    {percentage_2024.toString().replace('.', ',')}
                    {percentage_2024 !== '-' && <sup className="mt-[16px]">%</sup>}
                  </GridBodyCell>
                </React.Fragment>
              )
            )}
          </Stats>
        </Participacion>
        <GoToHome onClick={() => navigate('/magic/elecciones')}>
          <House />
        </GoToHome>
      </Middle>
      <Bottom>
        <div className="flex items-end justify-center h-full w-full">
          <PByUnholster />
        </div>
      </Bottom>
    </Wrapper>
  )
}

const Wrapper = styled.div.attrs({
  className: 'mgw:p-[80px] mgw:gap-[100px] p-[60px] gap-[80px]',
})`
  display: grid;
  grid: min-content 1fr 1fr / 1fr;
  height: 100%;
  width: 100%;
  overflow: auto;
  justify-items: center;
  background-color: ${colors.frame};
`

const Top = styled.div`
  padding-top: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CNNLogo = styled(CNNLogoBase).attrs({
  className: 'mgw:w-[280px] w-[200px]',
})``

const Middle = styled.div`
  display: grid;
  grid: 1fr / 1fr min-content;
  max-width: 1740px;
  width: 100%;
  height: 100%;
`

const Participacion = styled.div.attrs({
  className: 'mgw:p-[30px] p-[20px] gap-16',
})`
  width: 100%;
  display: grid;
  grid: 1fr / min-content 1fr;
  justify-content: space-between;
  color: ${colors.whiteDch};
  background-color: ${colors.frameBgButton};
  border-radius: 10px;
  box-shadow: 3px 3px 0px 3px ${colors.frameBorderButton};
  z-index: 1;
`

const Title = styled.div.attrs({
  className: 'mgw:text-[60px] mgw:leading-[75px] text-[45px] leading-[55px]',
})`
  font-weight: 700;
`

const ContainerMesa = styled.div.attrs({
  className: 'mgw:py-2 py-1',
})`
  display: flex;
  gap: 8px;
  align-items: center;
  color: #c9ccd9;
`

const Percentage = styled.div.attrs({
  className: 'mgw:text-[36px] text-[30px]',
})`
  font-weight: 600;
`

const MesasEscrutadas = styled.div.attrs({
  className: 'mgw:text-[28px] text-[24px]',
})`
  margin-top: 5px;
  font-weight: 500;
  white-space: nowrap;
`

const Stats = styled.div.attrs({
  className: '',
})`
  display: grid;
  grid: repeat(6, min-content) / 1fr repeat(5, min-content);
  grid-auto-flow: row;
  gap: 0px;

  & > *:not(:nth-child(-n + 9)):not(:nth-last-child(-n + 6)) {
    border-bottom: 0.6px solid ${colors.gray1Dch};
  }

  & > :nth-child(n + 10):nth-child(-n + 15) {
    background-color: ${colors.frame};
    font-weight: 700;
  }
`

const GridHeaderCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0c184d80;
  border: 0px;
  font-weight: 700;
`

const GridNumberSymbol = styled(GridHeaderCell).attrs({
  className: 'mgw:text-[27px] mgw:leading-[35px] mgw:pb-[5px] text-[17px] leading-[25px] pb-[2px]',
})``

const GridYearType = styled(GridHeaderCell).attrs({
  className: 'mgw:gap-[10px] mgw:px-[30px] mgw:pt-[12px] gap-[5px] px-[20px] pt-[10px]',
})`
  align-items: end;
`

const GridYearContent = styled.div.attrs({
  className: 'mgw:text-[35px] mgw:leading-[45px] text-[28px] leading-[35px]',
})``

const GridCellTitle = styled.div.attrs({
  className:
    'mgw:text-[23px] mgw:leading-[30px] mgw:px-[30px] mgw:py-[15px] mgw:gap-6 gap-4 text-[17px] leading-[25px] px-[20px] py-[10px]',
})`
  display: flex;
  align-items: center;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
`

const GridTypeContent = styled.div.attrs({
  className: 'mgw:text-[25px] mgw:leading-[39px] text-[18px] leading-[25px]',
})`
  text-transform: uppercase;
  white-space: nowrap;
`

const GridBodyCell = styled.div.attrs({
  className:
    'mgw:text-[27px] mgw:leading-[35px] mgw:px-[30px] mgw:py-[10px] text-[20px] leading-[25px] px-[20px] py-[5px]',
})`
  display: flex;
  justify-content: center;
  align-items: center;
`

const GoToHome = styled.button.attrs({
  className: 'mgw:p-[15px] mgw:mt-[70px] mt-[60px] p-[10px]',
})`
  height: fit-content;
  background-color: ${colors.frameBgButton};
  border-radius: 10px;
  box-shadow: 3px 3px 0px 3px ${colors.frameBorderButton};
`

const Bottom = styled.div`
  display: grid;
  grid: 1fr / 1fr min-content;
  justify-content: center;
  align-items: end;
  height: 100%;
  width: 100%;
`

const PByUnholster = styled(PByUnholsterBase).attrs({
  className: 'mgw:h-[44px] mgw:w-[217px] h-[34px] w-[170px]',
})``

const TriangleArrow = styled(TriangleArrowSVG).attrs({
  className: 'mgw:w-[17px] mgw:h-[17px] w-[12px] h-[12px]',
})<{ direction: 'up' | 'down' }>`
  transform: ${({ direction }) => (direction === 'up' ? 'rotate(0deg)' : 'rotate(180deg)')};
  fill: ${({ direction }) => (direction === 'up' ? '#27AE60' : '#F2C94C')};
`
