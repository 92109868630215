import styled from 'styled-components'
import { Fragment, useState } from 'react'
import Card from '@/components/MagicWall/Card'
import ToggleSwitch from '@/components/MagicWall/ToggleSwitch'
import ContentPercentageBar, {
  DefaultBar,
  ContentLabel,
  SupPercentageLabel,
} from '../PercentageBar'
import { colors } from '@/constants'

export type Data = {
  pacto: string
  color: string
  electos_2021: number
  electos_2024: number
  porcentaje_2021: number
  porcentaje_2024: number
  orden: number
}

type Comparacion = {
  data: Data[]
  verticalSeparatorBefore?: string
  numberColSeparatorBefore?: number
}

function ComparisonBarItem({ isChecked, item }: { isChecked: boolean; item: Data }) {
  const formatDifference = (value: number, formatter: (num: number) => string) => {
    const sign = value > 0 ? '+' : ''
    const formattedValue = formatter(value)
    return `${sign}${formattedValue}`
  }

  const percentageDifference = (
    <SupPercentageLabel percentage={item.porcentaje_2024 - item.porcentaje_2021} includeSign />
  )
  const amountDifference = formatDifference(item.electos_2024 - item.electos_2021, (num) =>
    num.toString()
  )

  return (
    <BarItemContainer>
      <PercentageBarContainer>
        <ContentPercentageBar
          outerContent={
            <ContentLabel className="mgw:text-[24px] text-[20px] font-medium">
              {isChecked ? (
                <SupPercentageLabel
                  hideTrailingZero
                  especialSupForBar
                  percentage={item.porcentaje_2021}
                />
              ) : (
                item.electos_2021.toString()
              )}
            </ContentLabel>
          }
          percentage={item.porcentaje_2021}
          color={`${item.color}50`}
          default_={<DefaultBar className="mgw:text-[20px] text-[14px]" bordered />}
          maxHeight={80}
          bordered
        />
        <ContentPercentageBar
          outerContent={
            <ContentLabel className="mgw:text-[24px] text-[20px] font-medium">
              {isChecked ? (
                <SupPercentageLabel
                  hideTrailingZero
                  especialSupForBar
                  percentage={item.porcentaje_2024}
                />
              ) : (
                item.electos_2024.toString()
              )}
            </ContentLabel>
          }
          maxHeight={80}
          percentage={item.porcentaje_2024}
          color={item.color}
          bordered
        />
      </PercentageBarContainer>
      <CategorieLabel>{item.pacto}</CategorieLabel>
      <div className="flex justify-center mgw:h-[52px] h-[40px]">
        {item.porcentaje_2021 !== 0 && (
          <DifferenceTag>{isChecked ? percentageDifference : amountDifference}</DifferenceTag>
        )}
      </div>
    </BarItemContainer>
  )
}

export default function Comparacion({
  data,
  verticalSeparatorBefore = '',
  numberColSeparatorBefore = 2,
}: Comparacion) {
  const [isChecked, setIsChecked] = useState(false)

  const handleChange = () => {
    setIsChecked((prev) => !prev)
  }

  const sortData = [...data].sort((a, b) => a.orden - b.orden)

  return (
    <Card
      title={`Comparación Pactos 2021 y 2024`}
      separatorColor="#CCCCCC"
      statusLabel="PRELIMINAR"
      toggle={
        <ToggleSwitch
          checkedLabel="N°"
          uncheckedLabel="%"
          checked={isChecked}
          handleToggle={handleChange}
        />
      }
    >
      <div className="flex flex-col h-full p-[30px] min-h-[400px]">
        <Legend>
          <LegendItem>
            <ColorBox color={`${colors.gray3Dch}50`} />
            <span>2021</span>
          </LegendItem>
          <LegendItem>
            <ColorBox color={colors.blackDch} />
            <span>2024</span>
          </LegendItem>
        </Legend>
        <ComparisonGrid number={data.length} numberColSeparatorBefore={numberColSeparatorBefore}>
          {sortData.map((item, index) => (
            <Fragment key={`pactos-${item.pacto}`}>
              {item.pacto === verticalSeparatorBefore && <VSeparatorPactos />}
              <ComparisonBarItem key={index} item={item} isChecked={isChecked} />
            </Fragment>
          ))}
        </ComparisonGrid>
      </div>
    </Card>
  )
}

const ComparisonGrid = styled.div<{ number: number; numberColSeparatorBefore: number }>`
  display: grid;
  height: 100%;
  grid-template-columns:
    repeat(${({ number, numberColSeparatorBefore }) => number - numberColSeparatorBefore - 1}, 1fr)
    1.04fr min-content 1.04fr ${({ numberColSeparatorBefore }) =>
      numberColSeparatorBefore === 2 ? '1fr' : ''};
  --dataSize: ${({ number }) => number};
`

const Legend = styled.div`
  display: flex;
  padding-left: 20px;
  gap: 20px;
`

const LegendItem = styled.div.attrs({
  className: 'mgw:text-[24px] text-[20px]',
})`
  display: flex;
  align-items: center;
  gap: 10px;
`

const ColorBox = styled.div.attrs<{ color: string }>({
  className: 'mgw:w-[24px] w-[20px] mgw:h-[24px] h-[20px]',
})`
  background-color: ${({ color }) => color};
`

const PercentageBarContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  padding: 0 calc(100% / var(--dataSize));
  gap: calc(40% / var(--dataSize));
  border-bottom: 2.5px solid ${colors.gray2Dch};
`

const BarItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  color: ${colors.blackDch};
  &:nth-last-of-type(4) ${PercentageBarContainer} {
    padding-right: calc(150% / var(--dataSize));
  }
  &:nth-last-of-type(2) ${PercentageBarContainer} {
    padding-left: calc(150% / var(--dataSize));
  }
`

const CategorieLabel = styled.div.attrs({
  className: 'mgw:text-[22px] text-[20px] mgw:h-[90px] h-[70px] leading-6',
})`
  padding: 10px calc(70% / var(--dataSize));
  text-align: center;
  font-weight: 500;
`

const DifferenceTag = styled.div.attrs({
  className: 'mgw:text-[22px] text-[18px] mgw:min-w-[70px] min-w-[60]',
})`
  display: flex;
  align-items: center;
  justify-content: center;

  letter-spacing: 0.5px;
  font-weight: 500;

  width: max-content;
  border-radius: 15px;
  border: 2px solid ${colors.gray3Dch};
  padding: 0 8px;
`

const VSeparatorPactos = styled.div`
  height: 100%;
  border-right: 2.5px solid ${colors.gray2Dch};
`
