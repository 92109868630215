import HorizontalMap from '@/components/Web/HorizontalMap'
import MPactos, { type PactoResult } from '@/components/Web/MPactos'
import { colors, PACTOS } from '@/constants'
import { regiones } from '@/stores/useTerritorios'
import styled from 'styled-components'
import useElectionData from '@/stores/useElectionData'
import { useMemo, useState } from 'react'
import { ElectionType } from '@/types/Elections'
import { getResultsByPacto } from '@/lib/dataProcess'
import { buildPactosViewData } from '@/lib/buildDataViews'
import { Circle } from 'lucide-react'
import Scroll from '@/assets/scroll.svg'
import DecideChileMono from '@/components/DecideChileMono'
import { Button } from '@/components/ui/button'
import { Eye, EyeOff } from 'lucide-react'
import {
  ELECTOS_1V,
  ELECTOS_1V_PACTOS,
  ELECTOS_1V_REGIONES,
  ELECTOS_1V_REGION_PACTO,
} from '@/constants'

const VOTES_PACTOS_1V = {
  PCR: 1308882,
  TRR: 151105,
  PCM: 341633,
  CHV: 2675022,
  REP: 1493090,
  IND: 2068851,
  RVL: 492560,
}

export default function Pactos() {
  const {
    segunda_vuelta: { resultados: resultados2V },
  } = useElectionData()

  const [showPartidosMobile, setShowPartidosMobile] = useState(false)

  const data: PactoResult[] = useMemo(() => {
    const resultByPactos = getResultsByPacto(resultados2V || [])
    const pactosViewData = buildPactosViewData(resultByPactos, ElectionType.GORE_2V, false)
    return pactosViewData.map((pacto) => {
      if (!ELECTOS_1V_PACTOS.includes(pacto.abreviatura)) return pacto
      const selected1V = ELECTOS_1V[pacto.abreviatura] || {}
      return {
        ...pacto,
        electos: pacto.electos + selected1V?.electos,
        electos_partido: pacto.electos_partido
          .map((partido) => ({
            ...partido,
            electos: partido.electos + selected1V?.partidos[partido.cod_partido] || partido.electos,
          }))
          .sort((a, b) => b.electos - a.electos),
      }
    })
  }, [resultados2V])

  // Set color of each region but GS
  const regionesData = useMemo(() => {
    const orderedRegiones = regiones
      // Remove GS
      .filter((region) => region.codigo !== 17)
      // Sort by order of appearance in the map
      .sort((a, b) => {
        if (a.orden && b.orden) {
          return a.orden - b.orden
        }
        return 0
      })

    return orderedRegiones.map((region) => {
      // Check if region has an electo
      const electosRegion = (resultados2V || []).filter(
        (electo) => Number(electo.cod_region) === Number(region.codigo) && electo.electo
      )

      const regionElectos1V = ELECTOS_1V_REGIONES.includes(region.codigo.toString())

      let selectedColor = ''
      if (electosRegion.length === 1) {
        selectedColor = PACTOS[ElectionType.GORE_2V][electosRegion[0]?.cod_pacto]?.color || ''
      }
      if (regionElectos1V) {
        selectedColor =
          PACTOS[ElectionType.GORE_2V][ELECTOS_1V_REGION_PACTO[region.codigo]]?.color || ''
      }

      return {
        name: region.acronimo || region.nombre,
        selectedColor,
        code: region.codigo,
        reverseBorderBackground: regionElectos1V,
        realName: region.nombre,
      }
    })
  }, [resultados2V])

  return (
    <MPactos
      data={data}
      verticalSeparatorBefore="Independientes"
      numberOfPartidosbyColumn={5}
      colVerticalSeparatorBefore={1}
      minWidthPactos="110px"
      className="relative"
      showPartidosMobile={showPartidosMobile}
      includeVotes={true}
      secondRound={true}
      resultsPactos1V={VOTES_PACTOS_1V}
      title={'Electos por Pacto'}
      toggle={
        <div className="xl:hidden">
          <Scroll />
        </div>
      }
    >
      <HideScrollbar className="xl:px-[50px] pb-[5px] mb-4 xl:mb-0 xl:pb-[10px] max-w-full xl:w-[867px] xl:mt-3 overflow-y-auto px-3">
        <HorizontalMap
          selectionProperty="cod_comuna"
          position="center"
          regionResults={regionesData}
        />
        <div className="flex w-full h-full justify-center gap-2 items-center -mt-3">
          <Circle color="#4f4f4f" className="w-[12px] h-[12px]" />
          <Text>Electos en 1ra V.</Text>
        </div>
      </HideScrollbar>
      <div className="flex w-full justify-between xl:justify-end items-center mb-1 -mt-3">
        <DecideChileMono />
        <Button
          className="gap-2 flex xl:hidden uppercase"
          variant={'outline'}
          size={'ghostSmall'}
          onClick={() => setShowPartidosMobile(!showPartidosMobile)}
        >
          {showPartidosMobile ? <EyeOff size={13} /> : <Eye size={13} />}
          Partidos
        </Button>
      </div>
    </MPactos>
  )
}

const Text = styled.div`
  font-size: 12px;
  color: ${colors.blackDch};
  font-weight: 400;
`

const HideScrollbar = styled.div`
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20px; /* Adjust the width of the fade effect */
    pointer-events: none;
    height: 100px;
  }
  &::before {
    left: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  }
  &::after {
    right: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  }
  padding-left: 15px;
  padding-right: 20px;
`
