import styled from 'styled-components'
import FirstRoundSVG from '@/assets/1V.svg'
import CheckIconSVG from '@/assets/checkIcon.svg'
import ContentPercentageBar from '@/components/PercentageBar'
import Card from '@/components/Web/Card'
import ReelectoBadge from '@/components/ReelectoBadge'
import { Skeleton } from '@/components/ui/skeleton'
import { colors } from '@/constants'
import { numberWithDots, voteToMillionString } from '@/helpers/formatters'
import { cn } from '@/lib/utils'

type ICandidateBadge = {
  nombre?: string
  imagen?: string
  color?: string
  darkColor?: string
  electo?: boolean
  isEmpty?: boolean
  pacto?: string
  partido?: string
  primeraVuelta?: boolean
  reelecto?: boolean
  isAllGrey?: boolean
}

type Data = {
  nombre: string
  imagen: string
  color: string
  darkColor: string
  electo: boolean
  pacto: string
  partido: string
  votos: number
  porcentaje: number
  order: number
  reelecto?: boolean
  primera_vuelta?: boolean
}

type ElectosComuna = {
  data: Data[]
  title: string
  regionView: boolean
  selection: React.ReactNode
}

export default function Versus2V({ data, title, selection, regionView }: ElectosComuna) {
  const sortData = [...data].sort((a, b) => a.order - b.order)

  return (
    <Card title={title} selector={selection}>
      <div className="flex flex-col h-auto xl:max-h-[330px] xl:overflow-y-auto xl:pb-2 xl:px-1">
        <ComparativeCard className="flex-grow gap-[10px] text-[5px]">
          {sortData.length > 0 ? (
            <CandidateBadge
              partido={sortData[0].partido || ''}
              electo={sortData[0].electo || false}
              reelecto={sortData[0].reelecto || false}
              primeraVuelta={sortData[0]?.primera_vuelta || false}
              imagen={sortData[0].imagen}
              color={sortData[0].color}
              darkColor={sortData[0].darkColor}
              pacto={sortData[0].pacto}
              nombre={sortData[0].nombre}
              isAllGrey={sortData[1]?.primera_vuelta || false}
            />
          ) : (
            <CandidateBadge isEmpty />
          )}

          <div className="flex gap-5 border-b-2 border-[lightgray] w-full justify-center">
            <CustomPercentageBar
              color={sortData[0]?.color || ''}
              porcentaje={sortData[0]?.porcentaje || 0}
              votos={sortData[0]?.votos || 0}
              isAllGrey={sortData[1]?.primera_vuelta || false}
            />
            <CustomPercentageBar
              color={sortData[1]?.color || ''}
              porcentaje={sortData[1]?.porcentaje || 0}
              votos={sortData[1]?.votos || 0}
              isAllGrey={sortData[0]?.primera_vuelta || false}
            />
          </div>

          {sortData.length > 1 ? (
            <CandidateBadge
              partido={sortData[1].partido || ''}
              electo={sortData[1].electo || false}
              reelecto={sortData[1].reelecto || false}
              primeraVuelta={sortData[1]?.primera_vuelta || false}
              imagen={sortData[1].imagen}
              color={sortData[1].color}
              darkColor={sortData[1].darkColor}
              pacto={sortData[1].pacto}
              nombre={sortData[1].nombre}
              isAllGrey={sortData[0]?.primera_vuelta || false}
            />
          ) : (
            <CandidateBadge isEmpty />
          )}
        </ComparativeCard>
      </div>
    </Card>
  )
}

type CustomContentLabelProps = {
  color: string
  porcentaje: number
  votos?: number
  isAllGrey?: boolean
}

function SupPercentage({ percentage }: { percentage: number }) {
  return (
    <div className="flex items-center justify-center">
      <div className="flex justify-end items-center relative">
        {percentage.toFixed(1).replace('.', ',')}
        <sup className="absolute right-[-12px] top-[15px] text-[15px]">%</sup>
      </div>
    </div>
  )
}

function CustomPercentageBar({
  color,
  porcentaje,
  votos = 0,
  isAllGrey = false,
}: CustomContentLabelProps) {
  return (
    <ContentPercentageBar
      className={cn('max-w-[85px]', { 'grayscale opacity-50': isAllGrey })}
      outerContent={
        <CustomContentLabel $color={color}>
          <SupPercentage percentage={porcentaje} />
        </CustomContentLabel>
      }
      innerContent={
        <>
          <div className="font-light text-[11px] w-full text-center absolute bottom-[-18px] text-gris3Dch hidden xl:block">
            {numberWithDots(votos)}
          </div>
          <div className="font-light text-[10px] w-full text-center absolute bottom-[-18px] text-gris3Dch block xl:hidden text-nowrap -translate-x-1">
            {voteToMillionString(votos)}
          </div>
        </>
      }
      maxHeight={80}
      percentage={porcentaje}
      color={color}
    />
  )
}

function CandidateBadge({
  nombre = '',
  imagen = 'userProfile.png',
  color = '#3c3c3c',
  darkColor = '#3c3c3c',
  pacto = '',
  electo = false,
  reelecto = false,
  partido = '',
  isEmpty = false,
  primeraVuelta = false,
  isAllGrey = false,
}: ICandidateBadge) {
  const splitName = nombre.trim().split(' ')
  const firstLastName = splitName
    .slice(1, splitName.length - 1)
    .join(' ')
    .toLowerCase()
  const secondLastNameFirstCharacter = splitName[splitName.length - 1].charAt(0)

  return (
    <div
      className={cn('flex flex-col items-center gap-[3px]', {
        'grayscale opacity-60': isAllGrey,
      })}
    >
      <div className="relative flex justify-center items-center">
        <CandidateImage src={imagen} $borderColor={color} $isAllGrey={isAllGrey} />
        {/* {reelecto && (
          <ReelectoBadge
            id={`reeleccion-tag-${color}`}
            color={color}
            className="absolute -bottom-8 w-[72px] left-[0px]"
          />
        )} */}
        {electo && !primeraVuelta ? (
          <CheckIconSVG className="absolute bottom-[-10px] right-0 w-[24px]" />
        ) : null}
        {primeraVuelta && <FirstRoundSVG className="absolute bottom-1 right-0 w-[24px] h-[24px]" />}
      </div>
      {isEmpty ? (
        <div className="w-full flex flex-col items-center gap-1">
          <Skeleton className="w-[70%] h-[20px]" />
          <Skeleton className="w-[95%] h-[20px]" />
          <Skeleton className="w-[70%] h-[20px]" />
          <Skeleton className="w-full h-[10px]" />
        </div>
      ) : (
        <>
          <Wrapper>
            <div className="lowercase first-letter:uppercase text-center text-[12px]">
              {splitName[0]}
            </div>
            <div className="capitalize text-[14px] mt-[-5px] mb-[-1px] font-medium text-nowrap overflow-hidden">
              {firstLastName.slice(0, 10)}
              {firstLastName.length > 10 && '.'}{' '}
              <span className="capitalize">{secondLastNameFirstCharacter}.</span>
            </div>
          </Wrapper>
          <PartidoLabel className="text-[10px] mb-[4px] min-h-[25px] max-w-full text-wrap">
            {partido}
          </PartidoLabel>
          <PactoLabel $color={color} $darkColor={darkColor || '#3c3c3c'}>
            {pacto}
          </PactoLabel>
        </>
      )}
    </div>
  )
}

const Wrapper = styled.div`
  color: ${colors.blackDch};
`

const ComparativeCard = styled.div`
  display: grid;
  padding: 25px 0px;
  justify-items: center;
  align-content: center;
  grid-template-columns: 1fr 1.2fr 1fr;
`

const CustomContentLabel = styled.div.attrs<{ $color: string }>({
  className: 'text-[20px] bigPhone:text-[25px] font-semibold',
})`
  color: ${({ $color }) => $color || colors.blackDch};
`

const PartidoLabel = styled.div`
  font-family: 'JetBrains Mono';
  text-transform: uppercase;
  text-align: center;
  color: #525252;
  line-height: 12px;
`

const CandidateImage = styled.img<{ $borderColor: string; $isAllGrey: boolean }>`
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  max-width: 85px;
  max-height: 85px;
  object-fit: cover;
  border-color: ${({ $borderColor, $isAllGrey }) => (!$isAllGrey ? $borderColor : '#BDBDBD')};
  border-width: 2px;
`

const PactoLabel = styled.div.attrs<{ $color: string; $darkColor: string }>({
  className: 'tracking-wide min-w-[114px] w-full text-[10px]',
})`
  height: fit-content;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  background-color: ${({ $color }) => `${$color}50`};
  color: ${({ $darkColor }) => $darkColor};
`
