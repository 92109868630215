import IfrHorizontalMap from '@/components/Iframe2Vuelta/IfrHorizontalMap'
import PactoInfo from '@/components/Iframe/PactoInfo'
import { colors } from '@/constants/colors'
import { PACTOS_2V_GORE, PACTOS_GORE } from '@/constants/pactos'
import { Fragment } from 'react/jsx-runtime'
import styled from 'styled-components'
import { getPactosSummary, deepMergePactosSummary } from '@/helpers/iframe'
import { CandidatoGore } from '@/types/Files'
import { regiones } from '@/stores/useTerritorios'
import { useMemo, useState } from 'react'

export default function GobernadoresPactos({
  primeraVuelta,
  segundaVuelta,
}: {
  primeraVuelta: CandidatoGore[]
  segundaVuelta: CandidatoGore[]
}) {
  const pactosSummary = getPactosSummary(primeraVuelta)
  const pactosSummary2v = getPactosSummary(segundaVuelta)
  const pactosSummaryMerged = deepMergePactosSummary(pactosSummary, pactosSummary2v)
  const [highlightedColor, setHighlightedColor] = useState<string | null>(null)

  // Set color of each region but GS
  const regionesData = useMemo(() => {
    const orderedRegiones = regiones
      // Remove GS
      .filter((region) => region.codigo !== 17)
      // Sort by order of appearance in the map
      .sort((a, b) => {
        if (a.orden && b.orden) {
          return a.orden - b.orden
        }
        return 0
      })

    return orderedRegiones.map((region) => {
      // Check if region has an electo
      const electosRegion = (primeraVuelta || []).filter(
        (electo) => Number(electo.cod_region) === Number(region.codigo) && electo.electo
      )

      const selectedColor =
        electosRegion.length === 1
          ? // If there is only one electo, get the color of the pacto
            PACTOS_GORE[electosRegion[0].cod_pacto].color
          : // if no data, use gray, else empty string for 2 vuelta
            ''
      return {
        name: region.acronimo || region.nombre,
        longName: region.nombre,
        selectedColor,
        code: region.codigo,
        vuelta: 1,
      }
    })
  }, [primeraVuelta])

  segundaVuelta
    .filter((electo) => electo.electo)
    .forEach((electo) => {
      const region = regionesData.find((region) => region.code === Number(electo.cod_region))
      if (region) {
        region.vuelta = 2
        region.selectedColor = PACTOS_2V_GORE[electo.cod_pacto].color
      }
    })

  return (
    <Wrapper>
      <IfrHorizontalMap
        selectionProperty="cod_comuna"
        position="center"
        regionResults={regionesData}
        highlightedColor={highlightedColor}
      />
      <div className="flex relative gap-[8px] sifr:gap-[8px] gap-y-[16px] sifr:gap-y-[32px] flex-wrap justify-center">
        {Object.entries(pactosSummaryMerged)
          .sort((a, b) => {
            const orderA = PACTOS_2V_GORE[a[0]].order
            const orderB = PACTOS_2V_GORE[b[0]].order
            return orderA - orderB
          })
          .map(([codigoPacto, info], index) => (
            <Fragment key={`pactoGore-${codigoPacto}`}>
              <PactoInfo
                color={PACTOS_2V_GORE[codigoPacto].color}
                electos={info.electos}
                shortName={PACTOS_2V_GORE[codigoPacto].shortName}
                longName={PACTOS_2V_GORE[codigoPacto].name}
                initials={PACTOS_2V_GORE[codigoPacto].initials}
                votos={info.votos}
                setHighlightedColor={setHighlightedColor}
              />
              {index === 5 && <VerticalDivider />}
            </Fragment>
          ))}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div.attrs({
  className: 'gap-[40px] sifr:gap-[10px] flex flex-col flex-grow justify-center items-center',
})``

const VerticalDivider = styled.div.attrs({
  className: `absolute h-[40px] sifr:h-[54px]
    right-[46px] sifr:right-[72px]`,
})`
  width: 1px;
  background-color: ${colors.gray5Dch};
`
