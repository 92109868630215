export const ELECTOS_1V: {
  [key: string]: {
    electos: number
    partidos: { [key: string]: number }
    regiones: number[]
  }
} = {
  PCR: {
    electos: 3,
    partidos: {
      PS: 2,
      IND: 1,
    },
    regiones: [1, 16, 14],
  },
  TRR: {
    electos: 1,
    partidos: {
      IND: 1,
    },
    regiones: [12],
  },
  CHV: {
    electos: 1,
    partidos: {
      UDI: 1,
    },
    regiones: [11],
  },
}

export const ELECTOS_1V_PACTOS = Object.keys(ELECTOS_1V)

export const ELECTOS_1V_REGION_PACTO = ELECTOS_1V_PACTOS.reduce((acc, pacto) => {
  ELECTOS_1V[pacto].regiones.forEach((region) => {
    acc[region] = pacto
  })
  return acc
}, {} as Record<number, string>)

export const ELECTOS_1V_REGIONES = Object.keys(ELECTOS_1V_REGION_PACTO)
