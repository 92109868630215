import { regiones } from '@/stores/useTerritorios'
import { colors } from '@/constants'
import { ELECTOS_1V_REGIONES } from '@/constants'
import useElectionData from '@/stores/useElectionData'
import Card from '@/components/MagicWall/Card'
import styled from 'styled-components'
import ToggleSwitch from '@/components/MagicWall/ToggleSwitch'
import { useMemo, useState } from 'react'
import { CandidatoGore } from '@/types/Files'
import { formatNameFirstLastName, numberToPercent, numberWithDots } from '@/helpers/formatters'
import { PACTOS_2V_GORE } from '@/constants/pactos'
import { Skeleton } from '@/components/ui/skeleton'
import CheckIconSVG from '@/assets/checkIcon.svg'
import ReelectoBadge from '@/components/ReelectoBadge'
import FirstRoundSVG from '@/assets/1V.svg'

function generateElectoData(electo: CandidatoGore) {
  const firstRound = ELECTOS_1V_REGIONES.includes(electo.cod_region?.toString() || '')
  const pacto = electo.cod_pacto === 'OTROS' ? PACTOS_2V_GORE.RVL : PACTOS_2V_GORE[electo.cod_pacto]
  const region = regiones.find((r) => r.codigo.toString() === electo.cod_region.toString())
  return {
    imagen: electo.imagen || '/userProfile.png',
    nombre: formatNameFirstLastName(electo.nombre) || '',
    porcentaje: numberToPercent(electo.porcentaje || 0, 2) || '',
    votos: numberWithDots(electo.votos || 0) || '',
    pacto: pacto ? pacto?.shortName : '',
    color: pacto?.color || '',
    color_oscuro: pacto?.darkColor || '',
    region: region?.nombreCorto || region?.nombre || electo.region,
    cod_region: Number(region?.codigo || electo.cod_region),
    primera_vuelta: firstRound,
    reelecto: electo.reelecto || false,
  }
}

export interface IElectoData {
  imagen: string
  nombre: string
  porcentaje: number
  votos: number
  pacto: string
  color: string
  color_oscuro: string
  region: string
  cod_region: number
  primera_vuelta: boolean
  reelecto: boolean
}

export default function Electos() {
  const {
    files: { gore },
    segunda_vuelta: { resultados: resultados2V },
  } = useElectionData()

  const [isChecked, setIsChecked] = useState(false)
  const handleChange = () => setIsChecked((prev) => !prev)

  const data: { [key: number]: IElectoData } = useMemo(() => {
    if (!gore || !resultados2V) return []
    const electos1V = gore.filter((candidato) => candidato.electo)
    const electos2V = resultados2V.filter((candidato) => candidato.electo)
    return [...electos1V, ...electos2V].reduce(
      (acc, electo) => ({ ...acc, [electo.cod_region]: generateElectoData(electo) }),
      {}
    )
  }, [gore, resultados2V])

  const orderedData = useMemo(() => {
    const orderedRegiones = regiones
      .filter((region) => region.codigo !== 17)
      .sort((a, b) => (a.orden && b.orden ? a.orden - b.orden : a.codigo - b.codigo))

    return orderedRegiones.map((region) =>
      data[region.codigo]
        ? data[region.codigo]
        : {
            imagen: 'userProfile.png',
            nombre: '',
            porcentaje: 0,
            votos: 0,
            pacto: '',
            color: '',
            color_oscuro: '',
            region: region?.nombreCorto || region?.nombre || '',
            cod_region: 0,
            primera_vuelta: false,
            reelecto: false,
          }
    )
  }, [data])

  return (
    <Card
      title="Electos"
      separatorColor="#CCCCCC"
      statusLabel="PRELIMINAR"
      toggle={
        <ToggleSwitch
          checkedLabel="N°"
          uncheckedLabel="%"
          checked={isChecked}
          handleToggle={handleChange}
        />
      }
    >
      <Wrapper>
        {orderedData.map((electo, index) => (
          <ElectoCard key={`electo-2v-${index}`} electo={electo} votes={!isChecked} />
        ))}
      </Wrapper>
    </Card>
  )
}

function ElectoCard({ electo, votes }: { electo: IElectoData; votes: boolean }) {
  const {
    imagen,
    nombre,
    porcentaje,
    votos,
    pacto,
    color,
    color_oscuro,
    region,
    primera_vuelta,
    cod_region,
    reelecto,
  } = electo
  return (
    <div className="flex flex-col items-center w-full">
      <div className="relative mb-1">
        <ElectoImage src={imagen} $borderColor={color} />
        {reelecto && (
          <ReelectoBadge
            className="absolute top-[5px] left-[-12px] scale-[0.8]"
            id={`electo-reelecto-${cod_region}`}
            color={color}
          />
        )}
        {primera_vuelta ? (
          <FirstRoundSVG className="w-[40px] h-[40px] absolute bottom-0 right-0" />
        ) : (
          <CheckIconSVG className="w-[40px] h-[40px] absolute bottom-0 right-0" />
        )}
      </div>
      <div className="text-[22px] font-semibold w-full flex items-center justify-center">
        {nombre !== '' ? nombre : <Skeleton className="w-[70%] h-[25px] mt-2" />}
      </div>
      <ElectoPacto $color={color_oscuro}>
        {pacto !== '' ? pacto : <Skeleton className="w-[90%] h-[16.19px] mt-1" />}
      </ElectoPacto>
      <ElectoNumber $color={color_oscuro}>
        {votos !== 0 && porcentaje !== 0 ? (
          votes ? (
            votos
          ) : (
            porcentaje
          )
        ) : (
          <Skeleton className="w-[60%] h-[47px] my-1" />
        )}
      </ElectoNumber>
      <ElectoRegion $empty={region !== ''}>
        {region !== '' ? region : <Skeleton className="w-[100%] h-[31px] mt-2" />}
      </ElectoRegion>
    </div>
  )
}

const Wrapper = styled.div.attrs({
  className: 'mgw:py-5 mgw:px-2 py-5 px-2',
})`
  display: grid;
  grid: repeat(2, 1fr) / repeat(8, 1fr);
  column-gap: 10px;
  row-gap: 20px;
  height: 100%;
  width: 100%;
  color: ${colors.blackDch};
`

const ElectoImage = styled.img<{ $borderColor: string }>`
  width: 127px;
  height: 127px;
  border: 4px solid ${({ $borderColor }) => $borderColor};
  border-radius: 50%;
  object-fit: cover;
  border-color: ${({ $borderColor }) => $borderColor};
`

const ElectoPacto = styled.div<{ $color?: string }>`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: 'JetBrains Mono';
  font-size: 16px;
  text-transform: uppercase;
  color: ${({ $color }) => $color || colors.blackDch};
  font-weight: 600;
  line-height: 20.18px;
  letter-spacing: 0.5px;
`

const ElectoNumber = styled.div<{ $color?: string }>`
  width: 100%;
  display: flex;
  justify-content: center;
  color: ${({ $color }) => $color || colors.blackDch};
  font-size: 30px;
  font-weight: 700;
  line-height: 55.09px;
`

const ElectoRegion = styled.div<{ $empty: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  font-size: 18px;
  font-weight: 600;
  background-color: ${({ $empty }) => ($empty ? '#A1A1A14D' : 'none')};
  color: ${colors.gray3Dch};
  line-height: 19px;
  letter-spacing: 1px;
  text-transform: uppercase;
`
