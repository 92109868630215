import { IElectoData } from '@/app/MagicWall/2VGORE/2VElectos'
import { colors } from '@/constants/colors'
import useElectionData from '@/stores/useElectionData'
import { useMemo, useState } from 'react'
import styled from 'styled-components'
import CheckIconSVG from '@/assets/checkIcon.svg'
import { regiones } from '@/stores/useTerritorios'
import ReelectoBadge from '@/components/ReelectoBadge'
import FirstRoundSVG from '@/assets/1V.svg'
import { Skeleton } from '@/components/ui/skeleton'
import { Progress } from '@/components/ui/progress'
import { CandidateBadgeWithCheck } from '@/components/Web/CandidateBadge'
import { ELECTOS_1V_REGIONES } from '@/constants'
import { CandidatoGore } from '@/types/Files'
import { cn } from '@/lib/utils'
import { formatNameFirstLastName, numberWithDots } from '@/helpers/formatters'
import { PACTOS_2V_GORE } from '@/constants/pactos'
import Card from '@/components/Web/Card'
import ToggleSwitch from '@/components/Web/ToggleSwitch'

function generateElectoData(electo: CandidatoGore) {
  const firstRound = ELECTOS_1V_REGIONES.includes(electo.cod_region?.toString() || '')
  const pacto = electo.cod_pacto === 'OTROS' ? PACTOS_2V_GORE.RVL : PACTOS_2V_GORE[electo.cod_pacto]
  const region = regiones.find((r) => r.codigo.toString() === electo.cod_region.toString())
  return {
    imagen: electo.imagen || '/userProfile.png',
    nombre: electo.nombre || '',
    porcentaje: electo.porcentaje || 0,
    votos: numberWithDots(electo.votos || 0) || '',
    pacto: pacto ? pacto?.shortName : '',
    color: pacto?.color || '',
    color_oscuro: pacto?.darkColor || '',
    region: region?.nombreCorto || region?.nombre || electo.region,
    cod_region: Number(region?.codigo || electo.cod_region),
    primera_vuelta: firstRound,
    reelecto: electo.reelecto || false,
  }
}

export default function G2VElectos() {
  const {
    files: { gore },
    segunda_vuelta: { resultados: resultados2V },
  } = useElectionData()

  const [isChecked, setIsChecked] = useState(false)

  const handleChange = () => {
    setIsChecked((prev) => !prev)
  }

  const data: { [key: number]: IElectoData } = useMemo(() => {
    if (!gore || !resultados2V) return []
    const electos1V = gore.filter((candidato) => candidato.electo)
    const electos2V = resultados2V.filter((candidato) => candidato.electo)
    return [...electos1V, ...electos2V].reduce(
      (acc, electo) => ({ ...acc, [electo.cod_region]: generateElectoData(electo) }),
      {}
    )
  }, [gore, resultados2V])

  const orderedData = useMemo(() => {
    const orderedRegiones = regiones
      .filter((region) => region.codigo !== 17)
      .sort((a, b) => (a.orden && b.orden ? a.orden - b.orden : a.codigo - b.codigo))

    return orderedRegiones.map((region) =>
      data[region.codigo]
        ? data[region.codigo]
        : {
            imagen: 'userProfile.png',
            nombre: '',
            porcentaje: 0,
            votos: 0,
            pacto: '',
            color: '',
            color_oscuro: '',
            region: region?.nombreCorto || region?.nombre || '',
            cod_region: 0,
            primera_vuelta: false,
            reelecto: false,
          }
    )
  }, [data])

  return (
    <Card
      title="Electos"
      separatorColor="#CCCCCC"
      statusLabel={'PRELIMINAR'}
      toggle={
        <div className="hidden sm:block">
          <ToggleSwitch
            checkedLabel="N°"
            uncheckedLabel="%"
            checked={isChecked}
            handleToggle={handleChange}
          />
        </div>
      }
    >
      <Wrapper className="h-full xl:grid-cols-8 xl:grid-rows-2 md:grid-cols-6 md:grid-rows-3 sm:grid-cols-4 sm:grid-rows-4 sm:gap-x-[10px] sm:gap-y-[20px] gap-y-[10px]">
        {orderedData.map((electo, index) => (
          <div className="w-full h-full" key={`electo-2v-${index}`}>
            <ElectoCard className="hidden sm:flex" electo={electo} votes={!isChecked} />
            <ElectoCardMobile className="flex sm:hidden" electo={electo} />
          </div>
        ))}
      </Wrapper>
    </Card>
  )
}

function ElectoCard({
  electo,
  className,
  votes,
}: {
  electo: IElectoData
  className: string
  votes: boolean
}) {
  const {
    imagen,
    nombre,
    porcentaje,
    votos,
    pacto,
    color,
    color_oscuro,
    region,
    primera_vuelta,
    cod_region,
    reelecto,
  } = electo
  return (
    <div className={cn('flex flex-col items-center w-full', className)}>
      <div className="relative mb-1">
        <ElectoImage src={imagen} $borderColor={color} />
        {reelecto && (
          <ReelectoBadge
            className="absolute top-[-27px] left-[-36px] scale-[0.462]"
            id={`electo-reelecto-${cod_region}`}
            color={color}
          />
        )}
        {primera_vuelta ? (
          <FirstRoundSVG className="w-[18px] h-[18px] absolute bottom-0 right-[10px]" />
        ) : (
          <CheckIconSVG className="w-[18px] h-[18px] absolute bottom-0 right-[10px]" />
        )}
      </div>
      <div className="text-[13px] font-semibold w-full flex items-center justify-center">
        {nombre !== '' ? (
          formatNameFirstLastName(electo.nombre) || ''
        ) : (
          <Skeleton className="w-[70%] h-[14px] mt-0.5" />
        )}
      </div>
      <ElectoPacto $color={color_oscuro}>
        {pacto !== '' ? pacto : <Skeleton className="w-[90%] h-[7px] mt-0.5" />}
      </ElectoPacto>
      <ElectoNumber $color={color_oscuro}>
        {votos !== 0 && porcentaje !== 0 ? (
          votes ? (
            votos
          ) : (
            porcentaje.toString().replace('.', ',') + '%'
          )
        ) : (
          <Skeleton className="w-[60%] h-[16.2px] my-1" />
        )}
      </ElectoNumber>
      <ElectoRegion $empty={region !== ''}>
        {region !== '' ? region : <Skeleton className="w-[100%] h-[31px] mt-0.5" />}
      </ElectoRegion>
    </div>
  )
}

function ElectoCardMobile({ electo, className }: { electo: IElectoData; className: string }) {
  return (
    <div className={cn('flex flex-col gap-1', className)}>
      <div className="border-b text-[14px] font-semibold">
        {electo.region === 'Arica y P.' ? 'Arica y Parinacota' : electo.region}
      </div>
      <div className="flex items-center justify-between">
        <CandidateBadgeWithCheck
          winner
          firstRound={electo.primera_vuelta || false}
          imagen={electo.imagen}
          color={electo.color}
          upperCandidateLabel={<UpperContent>{electo.nombre?.toLocaleLowerCase()}</UpperContent>}
          lowerCandidateLabel={<LowerContent>{electo.pacto}</LowerContent>}
        />
        <div className="flex items-start justify-end">
          <div className="flex flex-col gap-0 items-start">
            <div className="font-semibold text-[12px] text-right w-full">
              {electo?.porcentaje?.toFixed(1)?.replace('.', ',') || '0'}%
            </div>
            <div className="text-[10px] font-light text-right w-full">
              {electo.votos || '0'} votos
            </div>
          </div>
          <Progress
            value={Number(electo.porcentaje)}
            className="w-[50px] xl:w-[30px] h-[11px] ml-3 bg-[#EEEEF0] rounded-tr-[3px] rounded-br-[3px] mt-1"
            indicatorColor={electo.color}
          />
        </div>
      </div>
    </div>
  )
}

const UpperContent = styled.div.attrs({
  className: 'text-[12px] capitalize',
})`
  width: 100%;
  font-weight: 500;
`

const LowerContent = styled.div.attrs({
  className: 'text-[9px]',
})`
  width: 100%;
  font-weight: 300;
  color: #4f4f4f;
`

const Wrapper = styled.div.attrs({
  className: 'py-5 px-2',
})`
  display: grid;
  height: 100%;
  width: 100%;
  color: ${colors.blackDch};
`

const ElectoNumber = styled.div<{ $color?: string }>`
  width: 100%;
  display: flex;
  justify-content: center;
  color: ${({ $color }) => $color || colors.blackDch};
  font-size: 17px;
  font-weight: 700;
  line-height: 1.2;
`

const ElectoRegion = styled.div<{ $empty: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 18px;
  font-size: 10px;
  font-weight: 600;
  background-color: ${({ $empty }) => ($empty ? '#A1A1A14D' : 'none')};
  color: ${colors.gray3Dch};
  line-height: 1.2;
  letter-spacing: 1px;
  text-transform: uppercase;
`

const ElectoPacto = styled.div<{ $color?: string }>`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: 'JetBrains Mono';
  font-size: 8px;
  text-transform: uppercase;
  color: ${({ $color }) => $color || colors.blackDch};
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.5px;
`

const ElectoImage = styled.img<{ $borderColor: string }>`
  width: 74px;
  height: 74px;
  border: 3px solid ${({ $borderColor }) => $borderColor};
  border-radius: 50%;
  object-fit: cover;
  border-color: ${({ $borderColor }) => $borderColor};
`
