import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import PByUnholsterBase from '@/assets/pByUnholsterWhite.svg'
import CNNLogoBase from '@/assets/logoCNNWhite.svg'
import ChileEnDatos from '@/assets/chileDatos.svg'
import { colors } from '@/constants'
import { ElectionType } from '@/types/Elections'
import ReactGA from 'react-ga4'
import useElectionData from '@/stores/useElectionData'
import ArrowLeftIcon from '@/assets/arrow_left.svg'

ReactGA.initialize(import.meta.env.VITE_GA_MEASUREMENT_ID)

const SECTIONS = [
  {
    title: 'Alcaldes',
    path: ElectionType.ALCALDES,
    gaName: 'magicwall2024_alcaldes_menu',
  },
  {
    title: 'Concejales',
    path: ElectionType.CONCEJALES,
    gaName: 'magicwall2024_concejales_menu',
  },
  {
    title: 'Cores',
    path: ElectionType.CORE,
    gaName: 'magicwall2024_cores_menu',
  },
  {
    title: '1ra Vuelta Gores',
    path: ElectionType.GORE,
    gaName: 'magicwall2024_gores_menu',
  },
  {
    title: '2da Vuelta Gores',
    path: ElectionType.GORE_2V,
    gaName: 'magicwall2024_gores2v_menu',
  },
]

export default function MagicWall() {
  const navigate = useNavigate()
  const {
    segunda_vuelta: { participacion },
  } = useElectionData()

  return (
    <Wrapper>
      <Top>
        <CNNLogo />
      </Top>
      <Buttons>
        {SECTIONS.map((section) => (
          <Button key={section.path} lowOpacity={section.path !== ElectionType.GORE_2V}>
            <ElectionTitle
              onClick={() => {
                ReactGA.event(section.gaName, { medio: 'CNN' })
                navigate(`/magic/${section.path}`)
              }}
            >
              {section.title}
            </ElectionTitle>
            <ContainerMesa onClick={() => navigate(`/magic/participacion/${section.path}`)}>
              {section.path === ElectionType.GORE_2V ? (
                <>
                  <Percentage>
                    {(participacion?.porcentaje_mesas_escrutadas || 0).toFixed(0).replace('.', ',')}
                    %
                  </Percentage>
                  <MesasEscrutadas>MESAS ESCRUTADAS</MesasEscrutadas>{' '}
                </>
              ) : (
                <ArrowLeftIcon />
              )}
            </ContainerMesa>
          </Button>
        ))}
        <div className="absolute right-0">
          <ButtonChileEnDatos onClick={() => navigate('/magic/chile-en-datos')}>
            <ChileEnDatos />
          </ButtonChileEnDatos>
        </div>
      </Buttons>
      <Bottom>
        <div className="flex items-end justify-center h-full w-full">
          <PByUnholster />
        </div>
      </Bottom>
    </Wrapper>
  )
}

const Wrapper = styled.div.attrs({
  className: 'mgw:p-[80px] mgw:gap-[100px] p-[60px] gap-[80px]',
})`
  display: grid;
  grid: min-content 1fr 1fr / 1fr;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: ${colors.frame};
`

const Top = styled.div`
  padding-top: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CNNLogo = styled(CNNLogoBase).attrs({
  className: 'mgw:w-[280px] w-[200px]',
})``

const Buttons = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
  row-gap: 30px;
  column-gap: 20px;
  width: 100%;
  height: 100%;
`

const Button = styled.button.attrs<{ lowOpacity?: boolean }>({
  className: 'mgw:w-[440px] mgw:h-[220px] h-[234px] w-[360px]',
})`
  display: grid;
  grid: 1fr min-content / 1fr;
  background-color: ${colors.frameBgButton};
  text-align: left;
  border-radius: 10px;
  box-shadow: 3px 3px 0px 3px ${colors.frameBorderButton};
  opacity: ${({ lowOpacity }) => (lowOpacity ? 0.5 : 1)};
`

const ElectionTitle = styled.div.attrs({
  className: 'mgw:text-[48px] text-[40px] mgw:px-[30px] px-[20px] py-[30px]',
})`
  width: 100%;
  height: 100%;
  font-weight: 600;
  color: ${colors.whiteDch};
  line-height: 1.2;
`

const ContainerMesa = styled.div.attrs({
  className: 'mgw:px-[30px] mgw:py-[18px] px-[20px] py-[10px]',
})`
  height: 70px;
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: ${colors.frameBgButton2};
  color: #c9ccd9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`

const Percentage = styled.div.attrs({
  className: 'mgw:text-[32px] text-[28px]',
})`
  font-weight: 600;
  line-height: 45.14px;
  letter-spacing: 1px;
  text-align: left;
`

const MesasEscrutadas = styled.div.attrs({
  className: 'mgw:text-[24px] text-[20px]',
})`
  margin-top: 6px;
  font-weight: 500;
  line-height: 30.1px;
  text-align: left;
  letter-spacing: 1px;
`

const Bottom = styled.div`
  display: grid;
  grid: 1fr / 1fr min-content;
  justify-content: center;
  align-items: end;
  height: 100%;
  width: 100%;
`

const ButtonChileEnDatos = styled.button.attrs({
  className: 'mgw:w-[142px] mgw:h-[120px] w-[122px] h-[104px]',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.frameBgButton};
  padding: 40px;
  border-radius: 10px;
  box-shadow: 3px 3px 0px 3px ${colors.frameBorderButton};
`

const PByUnholster = styled(PByUnholsterBase).attrs({
  className: 'mgw:h-[44px] mgw:w-[217px] h-[34px] w-[170px]',
})``
