import HorizontalMapSvg from '@/assets/horizontalMap.svg'
import { colors } from '@/constants'
import styled from 'styled-components'
import { MapProps } from './Map/ComunasChileMap'

type HorizontalMapProps = {
  regionResults: {
    name: string
    selectedColor: string
    code: number
    reverseBorderBackground?: boolean
  }[]
  children?: React.ReactNode
} & MapProps

export default function HorizontalMap({ regionResults }: HorizontalMapProps) {
  return (
    <Wrapper>
      <Circles>
        {regionResults.map((region) => (
          <Circle
            key={`${region.code}-${region.selectedColor}`}
            selectedColor={region.selectedColor}
            reverseBorderBackground={region.reverseBorderBackground || false}
          >
            {region.name}
          </Circle>
        ))}
      </Circles>
      <Map />
    </Wrapper>
  )
}

const getBackgroundColor = (selectedColor?: string, reverseBorderBackground?: boolean) => {
  if (reverseBorderBackground) return colors.whiteDch
  return selectedColor || colors.gray2Dch
}

const getBorder = (selectedColor?: string, reverseBorderBackground?: boolean) => {
  if (!selectedColor && !reverseBorderBackground) return `2px solid ${colors.gray2Dch}`
  if (reverseBorderBackground && selectedColor) return `2px solid ${selectedColor}`
  return 'none'
}

const getColor = (selectedColor?: string, reverseBorderBackground?: boolean) => {
  if (reverseBorderBackground && selectedColor) return selectedColor
  return colors.whiteDch
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  width: 100%;
`

const Map = styled(HorizontalMapSvg).attrs({
  className: 'px-6 mgw:px-8 -mt-6 mgw:-mt-8',
})`
  height: 100%;
  width: 100%;
`

const Circle = styled.div.attrs<{ selectedColor?: string; reverseBorderBackground?: boolean }>({
  className:
    'w-8 sm:w-10 xl:w-14 mgw:w-14 p-0.5 sm:p-1 mgw:p-2 aspect-square text-sm xl:text-xl mgw:text-2xl',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 600;
  background-color: ${({ selectedColor, reverseBorderBackground }) =>
    getBackgroundColor(selectedColor, reverseBorderBackground)};
  border: ${({ selectedColor, reverseBorderBackground }) =>
    getBorder(selectedColor, reverseBorderBackground)};
  color: ${({ selectedColor, reverseBorderBackground }) =>
    getColor(selectedColor, reverseBorderBackground)};
`

const Circles = styled.div.attrs({
  className: 'gap-1 justify-between',
})`
  font-family: 'JetBrains Mono', monospace;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 0;
`
