import useElectionData from '@/stores/useElectionData'
import { PACTOS_2V_GORE, PACTOS_GORE } from '@/constants/pactos'
import { regiones } from '@/stores/useTerritorios'
import styled from 'styled-components'
import { formatFullName } from '@/helpers/formatters'
import CheckIconSVG from '@/assets/checkIcon.svg'

type CirclesGORE = {
  blue: number
  red: number
  brown: number
}

const REGIONES_AZULES = [15, 4, 7, 8, 10, 11]
const REGIONES_ROJAS = [1, 2, 3, 5, 13, 6, 16, 9, 14, 12]

const regionesCircles = {
  15: { blue: 9, red: 5, brown: 0 },
  1: { blue: 8, red: 6, brown: 0 },
  2: { blue: 7, red: 9, brown: 0 },
  3: { blue: 5, red: 9, brown: 0 },
  4: { blue: 7, red: 9, brown: 0 },
  5: { blue: 17, red: 11, brown: 0 },
  13: { blue: 21, red: 13, brown: 0 },
  6: { blue: 13, red: 7, brown: 0 },
  7: { blue: 13, red: 7, brown: 0 },
  16: { blue: 9, red: 7, brown: 0 },
  8: { blue: 18, red: 10, brown: 0 },
  9: { blue: 12, red: 7, brown: 1 },
  14: { blue: 7, red: 7, brown: 0 },
  10: { blue: 12, red: 7, brown: 0 },
  11: { blue: 7, red: 7, brown: 0 },
  12: { blue: 6, red: 8, brown: 0 },
}

const AZUL = '#00A9D1'
const ROJO = '#F75B5C'
const CAFE = '#A37C5E'

export default function Resumen() {
  const {
    segunda_vuelta: { resultados },
    files: { gore },
  } = useElectionData()

  return (
    <Wrapper>
      {regiones
        .filter((region) => region.codigo !== 17)
        .map((region) => {
          const electo = [...(resultados || []), ...(gore || [])]?.find(
            (c) => c.cod_region === region.codigo && c.electo
          )

          console.log('🚀 ~ Resumen ~ electo:', electo)
          const color = REGIONES_AZULES.includes(region.codigo) ? AZUL : ROJO
          const splitName = (electo?.nombre || '').trim().split(' ')
          const firstLastName = splitName
            .slice(1, splitName.length - 1)
            .join(' ')
            .toLowerCase()
          const secondLastNameFirstCharacter = splitName[splitName.length - 1].charAt(0)

          const pacto =
            PACTOS_2V_GORE[electo?.cod_pacto || ''] || PACTOS_GORE[electo?.cod_pacto || '']

          return (
            <div key={region.codigo} className="flex flex-col items-center w-full">
              <div className="text-center font-bold">{region.nombreCorto || region.nombre}</div>
              <div className="w-full h-fit relative flex items-center justify-center">
                <Border color={color}>
                  <CandidateImage
                    src={electo?.imagen}
                    $borderColor={color}
                    className="grayscale opacity-60"
                  />
                </Border>
                <CheckIconSVG className="absolute bottom-[-10px] right-[14px] w-[25px] grayscale z-30" />
              </div>
              <div className="lowercase first-letter:uppercase  text-[16px leading-3]">{splitName[0]}</div>
              <div className="lowercase first-letter:uppercase text-[16px] font-semibold leading-3">
                {splitName[1]} <span className="capitalize">{secondLastNameFirstCharacter}.</span>
              </div>
              <div className="uppercase min-h-[32px] text-[12px] text-center leading-3 mt-[10px]">{pacto?.name}</div>
              <CircleGrid>
                {Array.from({ length: regionesCircles[region?.codigo].blue }, (_, i) => (
                  <Circle key={i} color={AZUL} />
                ))}
                {Array.from({ length: regionesCircles[region?.codigo].red }, (_, i) => (
                  <Circle key={i} color={ROJO} />
                ))}
                {Array.from({ length: regionesCircles[region?.codigo].brown }, (_, i) => (
                  <Circle key={i} color={CAFE} />
                ))}
              </CircleGrid>
            </div>
          )
        })}
    </Wrapper>
  )
}

const Circle = styled.div<{ color: string }>`
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`

const CircleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6px;
`

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(16, 100px);
  gap: 12px;
`

const Border = styled.div<{ color: string }>`
  border: 3px solid ${({ color }) => color};
  border-radius: 50%;
  width: 85px;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const CandidateImage = styled.img<{ $borderColor: string }>`
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  max-width: 85px;
  max-height: 85px;
  object-fit: cover;
  border-width: 2px;
`
