import DecideChileMono from '@/components/DecideChileMono'
import { Button } from '@/components/ui/button'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel'
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Separator } from '@/components/ui/separator'
import { numberWithDots } from '@/helpers/formatters'
import { cn } from '@/lib/utils'
import useElectionData from '@/stores/useElectionData'
import useRouting from '@/stores/useRouting'
import { ElectionType } from '@/types/Elections'
import { ChevronLeft, ChevronRight, Plus } from 'lucide-react'
import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { create2VData2021 } from '../MagicWall/Participacion'

type singleParticipationData = {
  label: string
  amount: number | string
  porcentage?: number | string
}

interface ParticipationData {
  escrutado: number
  title: string
  voteType: string
  detail: singleParticipationData[]
}

const displayName = {
  [ElectionType.GORE]: 'Gores',
  [ElectionType.ALCALDES]: 'Alcaldes',
  [ElectionType.CORE]: 'Cores',
  [ElectionType.CONCEJALES]: 'Concejales',
}

const totalInscritos2021 = {
  [ElectionType.CORE]: 14959956,
  [ElectionType.ALCALDES]: 14900190,
  [ElectionType.CONCEJALES]: 14900190,
}

function calculatePercentage(amount: number | undefined, total: number | undefined) {
  if (!total || !amount) return '-'
  return ((amount * 100) / total).toFixed(1).replace('.', ',')
}

export default function ParticipationBanner() {
  const [show2021, setShow2021] = useState(false)
  const {
    comparador,
    updated_at,
    participation,
    segunda_vuelta: { updated_at: updated_at2V, participacion: participation2V },
  } = useElectionData()
  const { currentElection } = useRouting()

  const isFirstRound = currentElection !== ElectionType.GORE_2V
  const data2021 = isFirstRound
    ? comparador[currentElection as keyof typeof comparador]?.participacion
    : create2VData2021(
        participation[ElectionType.GORE] || {
          porcentaje_mesas_escrutadas: 0,
          votos: 0,
          votos_blancos: 0,
          votos_nulos: 0,
          participacion_porcentual: 0,
          inscritos: 0,
          resultados_comuna: {},
        }
      )

  if (data2021?.total_inscritos) {
    data2021.total_inscritos =
      totalInscritos2021[currentElection as keyof typeof totalInscritos2021] ||
      data2021.total_inscritos
  }

  const participationData = isFirstRound
    ? participation[currentElection as keyof typeof participation]
    : participation2V

  const totalInscritos = isFirstRound ? 15450377 : participation2V?.inscritos || 14116702
  const participacionAmount = participationData?.votos || 0

  const today = new Date()
  const realUpdatedAt = (currentElection !== ElectionType.GORE_2V ? updated_at : updated_at2V) || ''

  const currentData = useMemo(
    () => ({
      title: isFirstRound ? '2024 Municipales' : '2da Vuelta 2024',
      voteType: 'obligatorio',
      escrutado: 0,
      detail: [
        { amount: totalInscritos, label: 'Inscritos' },
        {
          amount: participacionAmount || 0,
          porcentage: participationData?.participacion_porcentual.toFixed(1) || 0,
          label: 'Participación',
        },
        {
          amount: participationData?.votos_blancos.toFixed(0) || 0,
          porcentage: (
            ((participationData?.votos_blancos || 0) * 100) /
            (participationData?.votos || 1)
          )
            .toFixed(1)
            .replace('.', ','),
          label: '· Blancos',
        },
        {
          amount: participationData?.votos_nulos || 0,
          porcentage: (
            ((participationData?.votos_nulos || 0) * 100) /
            (participationData?.votos || 1)
          )
            .toFixed(1)
            .replace('.', ','),
          label: '· Nulos',
        },
        {
          amount: (totalInscritos - participacionAmount).toFixed(0) || 0,
          porcentage: (((totalInscritos - participacionAmount) * 100) / totalInscritos)
            .toFixed(1)
            .replace('.', ','),
          label: 'No votan',
        },
      ],
    }),
    [
      isFirstRound,
      participacionAmount,
      participationData?.participacion_porcentual,
      participationData?.votos,
      participationData?.votos_blancos,
      participationData?.votos_nulos,
      totalInscritos,
    ]
  )

  const data: ParticipationData[] = [
    {
      title: isFirstRound ? '2021 Municipales' : '1ra Vuelta 2024',
      voteType: isFirstRound ? 'voluntario' : 'obligatorio',
      escrutado: 99.99,
      detail: [
        { amount: data2021?.total_inscritos || 0, label: 'Inscritos' },
        {
          amount: data2021?.votos_escrutados || 0,
          porcentage: calculatePercentage(data2021?.votos_escrutados, data2021?.total_inscritos),
          label: 'Participación',
        },
        {
          amount: data2021?.blancos || 0,
          porcentage: data2021?.blancos_porcentaje || 0,
          label: '· Blancos',
        },
        {
          amount: data2021?.nulos || 0,
          porcentage: data2021?.nulos_porcentaje || 0,
          label: '· Nulos',
        },
        {
          amount: (data2021?.total_inscritos || 0) - (data2021?.votos_escrutados || 0),
          porcentage: calculatePercentage(
            (data2021?.total_inscritos || 0) - (data2021?.votos_escrutados || 0),
            data2021?.total_inscritos
          ),
          label: 'No votan',
        },
      ],
    },
    {
      title: '2023 Plebiscito',
      voteType: 'obligatorio',
      escrutado: 99.86,
      detail: [
        { amount: 15406352, label: 'Inscritos' },
        { amount: 13008594, porcentage: 84.4, label: 'Participación' },
        { amount: 170412, porcentage: 1.3, label: '· Blancos' },
        { amount: 480017, porcentage: 3.6, label: '· Nulos' },
        { amount: 2397758, porcentage: 15.6, label: 'No votan' },
      ],
    },
  ]

  return (
    <div className="w-full xl:min-w-[867px] text-[#3C3C3C]">
      <div className="flex justify-center xl:justify-between items-center">
        <div className="flex items-center space-x-2">
          <div className="flex items-center space-x-1 text-[12px]">
            <span className="font-bold">
              {participationData?.porcentaje_mesas_escrutadas.toFixed(2).replace('.', ',') || 0}%
            </span>
            <span>Escrutado </span>
            <span>{displayName[currentElection as keyof typeof displayName] || ''}</span>
          </div>
          <Separator orientation="vertical" className="h-3" />
          <div className="flex items-center space-x-1 text-[12px]">
            <span className="font-bold">
              {participationData?.participacion_porcentual.toFixed(2).replace('.', ',') || 0}%
            </span>
            <span>Participación</span>
          </div>
          <Popover>
            <PopoverTrigger asChild>
              <Button variant="outline" size={'iconSm'} className="hidden xl:flex">
                <Plus className="h-3 w-3" />
                <span className="sr-only">More info</span>
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[600px] h-[220px] pt-0 relative" align="start">
              <div className="pt-0">
                <div className="flex justify-between space-x-2">
                  <ParticipationInfoCard
                    title={currentData?.title || ''}
                    votoType={currentData.voteType || ''}
                    data={currentData}
                  />
                  <div className="h-full flex">
                    <Separator orientation="vertical" className="h-[240px]" />
                    <Button
                      variant="outline"
                      size={'iconSm'}
                      className="ml-2 mt-4 border-[#333333]"
                      onClick={() => setShow2021(!show2021)}
                    >
                      {!show2021 ? (
                        <ChevronLeft className="h-3 w-3" />
                      ) : (
                        <ChevronRight className="h-3 w-3" />
                      )}
                      <span className="sr-only">{show2021 ? 'mostrar 2023' : 'mostrar 2021'}</span>
                    </Button>
                  </div>
                  {!show2021 ? (
                    <ParticipationInfoCard
                      title={data[0]?.title || ''}
                      votoType={data[0]?.voteType || ''}
                      pastData
                      data={data[0]}
                    />
                  ) : (
                    <ParticipationInfoCard
                      title={data[1]?.title || ''}
                      votoType={data[1]?.voteType || ''}
                      pastData
                      data={data[1]}
                    />
                  )}
                </div>
              </div>
              <DecideChileMono className="absolute bottom-2 right-8" />
            </PopoverContent>
          </Popover>
          <Dialog>
            <DialogTrigger asChild>
              <Button variant="outline" size={'iconSm'} className="flex xl:hidden">
                <Plus className="h-3 w-3" />
                <span className="sr-only">More info</span>
              </Button>
            </DialogTrigger>
            <DialogContent className="px-9 pt-3 pb-5 w-[310px] rounded-lg">
              <Carousel className="w-full">
                <CarouselContent>
                  <CarouselItem>
                    <ParticipationInfoCard
                      title={currentData.title || ''}
                      votoType="obligatorio"
                      data={currentData}
                    />
                  </CarouselItem>
                  {data.map((data, index) => (
                    <CarouselItem key={index}>
                      <ParticipationInfoCard
                        title={data.title}
                        votoType={data.voteType}
                        data={data}
                      />
                    </CarouselItem>
                  ))}
                </CarouselContent>
                <CarouselPrevious className="-left-7" />
                <CarouselNext className="-right-4" />
              </Carousel>
              <DecideChileMono className="absolute bottom-2 right-3" />
            </DialogContent>
          </Dialog>
        </div>
        <span className="text-[12px] text-[#3c3c3c] hidden xl:inline-block">
          Actualizado:{' '}
          <b>
            {isFirstRound
              ? '24 Nov.'
              : participationData?.porcentaje_mesas_escrutadas === 0
              ? parseDate(today)
              : parseDate(realUpdatedAt)}
          </b>
        </span>
      </div>
    </div>
  )
}

function parseDate(date: string | Date) {
  const hour = date
    ? new Date(date).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })
    : '--:--'
  const dayMonth = date
    ? new Date(date)
        .toLocaleDateString('es-CL', {
          day: '2-digit',
          month: 'short',
        })
        .replace(/-|\s/, ' ')
        .replace(/ (\w+)/, (_, month) => ` ${month.charAt(0).toUpperCase()}${month.slice(1)}`)
    : '24 Nov'
  return `${hour} hrs, ${dayMonth}.`
}

export const MonoLabel = styled.div`
  font-family: 'JetBrains Mono';
`

interface ParticipationInfoCardProps {
  title: string
  votoType: string
  pastData?: boolean
  data?: ParticipationData
}

function ParticipationInfoCard({ title, votoType, data, pastData }: ParticipationInfoCardProps) {
  return (
    <div
      className={cn('flex-1 py-3', {
        'opacity-55': pastData,
      })}
    >
      <h3 className="font-semibold text-negroDch text-sm">{title}</h3>
      <MonoLabel className="text-[10px] text-gris2Dch uppercase">Voto {votoType}</MonoLabel>
      <div className="space-y-2 mt-3">
        {data?.detail.map((item, index) => (
          <div className="grid grid-cols-4 gap-2" key={`item-${index}`}>
            <div
              className={cn('col-span-2 text-[11px] font-semibold', {
                'pl-2': item.label.includes('Blancos') || item.label.includes('Nulos'),
              })}
            >
              {item.label}
            </div>
            <div className="text-[11px] text-right">
              {item.amount ? numberWithDots(Number(item.amount)) : 0}
            </div>
            <div className="text-[11px] pr-4 text-end">
              {item.porcentage
                ? item.porcentage?.toString().replace('.', ',') + '%'
                : item.label === 'Inscritos'
                ? '-'
                : '0,0%'}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
