import MCambioPacto, { type Data } from '@/components/MagicWall/GORE/CambioMantienePacto'
import { useMemo } from 'react'
import { buildCambioMantienePactoGORE } from '@/lib/buildDataViews'
import useElectionData from '@/stores/useElectionData'
import { regiones } from '@/stores/useTerritorios'
import { ElectionType } from '@/types/Elections'
import gore2021 from '@/assets/files/gore2021.json'

export default function GMantienePacto() {
  const {
    files: { gore },
  } = useElectionData()

  const data: Data[] = useMemo(() => {
    if (!gore) return []
    return buildCambioMantienePactoGORE(gore, gore2021, 'mantiene', 15, ElectionType.GORE, false)
  }, [gore])

  const regionesData = regiones
    .filter((region) => region.codigo !== 17)
    .sort((a, b) => {
      if (a.orden && b.orden) {
        return a.orden - b.orden
      }
      return 0
    })
    .map((region) => ({
      code: region.codigo,
      name: region.acronimo || region.nombre,
      selectedColor:
        data.find((item) => item.region_codigo === region.codigo)?.electo_2024.color ?? '',
    }))

  return <MCambioPacto title="Mantiene Pacto" pageSize={4} data={data} regiones={regionesData} />
}
