import styled from 'styled-components'
import React from 'react'
import { colors } from '@/constants'
import { CandidateBigBadge, FullCandidateBadge } from '@/components/MagicWall/CandidateBadget'
import Card from '@/components/MagicWall/Card'
import ContentPercentageBar, { ContentLabel, SupPercentageLabel } from '../PercentageBar'
import { numberToPercent } from '@/helpers/formatters'
import { PARTIDOS } from '@/constants/pactos'
import { Skeleton } from '../ui/skeleton'
import { ButtonToFirstRound } from './GORE/Versus2V'

export type Data = {
  pacto: string
  cod_pacto: string
  color: string
  nombre: string
  electo: boolean
  partido: string
  cod_partido: string
  imagen: string
  votos: number
  porcentaje: number
  order: number
  darkColor: string
  reelecto?: boolean
  segunda_vuelta?: boolean
}

type ElectosComuna = {
  data: Data[]
  title: string
  cod_region?: number
  totalCount?: number
  linkToSecondRound?: string
}

function CustomPercentageBar({ item }: { item: Data }) {
  return (
    <ContentPercentageBar
      className="mgw:max-w-[100px] w-[85px]"
      outerContent={
        <CustomContentLabel color={item.color}>
          <SupPercentageLabel percentage={item.porcentaje} especialSupForBar size="big" />
        </CustomContentLabel>
      }
      maxHeight={80}
      percentage={item.porcentaje}
      color={item.color}
    />
  )
}

export default function ElectosComuna({
  data,
  title,
  totalCount = 4,
  linkToSecondRound,
}: ElectosComuna) {
  const sortData = [...data].sort((a, b) => a.order - b.order)
  const fakeItem: Data = {
    pacto: '',
    cod_pacto: '',
    color: '#3c3c3c',
    darkColor: '#3c3c3c',
    nombre: '',
    electo: false,
    partido: '',
    cod_partido: '',
    imagen: 'userProfile.png',
    votos: 0,
    porcentaje: 0,
    order: 0,
  }

  return (
    <Card
      title={title}
      statusLabel="PRELIMINAR"
      toggle={
        linkToSecondRound ? (
          <ButtonToFirstRound link={linkToSecondRound} firstRound={false} />
        ) : null
      }
    >
      <div className="flex flex-col h-full">
        <ComparativeCard className="flex-grow gap-3 max-h-[410px]">
          {sortData.length > 0 ? (
            <CandidateBigBadge
              labeled
              electo={sortData[0]}
              monoLabel={PARTIDOS[sortData[0].cod_partido]?.name || ''}
              winner={sortData[0].electo}
              className="w-[235px]"
              reelecto={sortData[0].reelecto}
              segundaVuelta={sortData[0]?.segunda_vuelta || false}
            />
          ) : (
            <CandidateBigBadge
              labeled
              electo={fakeItem}
              monoLabel={''}
              winner={false}
              isBlank
              showSkeleton
            />
          )}

          <div className="flex mgw:gap-8 gap-5 border-b-2 border-[lightgray] w-full justify-center">
            <CustomPercentageBar item={sortData.length > 0 ? sortData[0] : fakeItem} />
            {totalCount > 1 && (
              <CustomPercentageBar item={sortData.length > 1 ? sortData[1] : fakeItem} />
            )}
          </div>
          {sortData.length > 1 ? (
            <CandidateBigBadge
              labeled
              electo={sortData[1]}
              monoLabel={PARTIDOS[sortData[1].cod_partido]?.name || ''}
              winner={sortData[1].electo}
              className="w-[235px]"
              segundaVuelta={sortData[0]?.segunda_vuelta || false}
            />
          ) : (
            totalCount > 1 && (
              <CandidateBigBadge
                labeled
                electo={fakeItem}
                monoLabel={''}
                winner={false}
                isBlank
                showSkeleton
              />
            )
          )}
        </ComparativeCard>
        <GridTable className="">
          {sortData.slice(2, 4).map((item, index) => (
            <React.Fragment key={index}>
              <GridCell>
                <FullCandidateBadge electo={item} winner={item.electo} />
              </GridCell>
              <GridCell className="justify-end mgw:text-[40px] text-[35px]">
                {numberToPercent(item.porcentaje, 2, false)}
                <sup className="text-[0.6em] mt-3">%</sup>
              </GridCell>
            </React.Fragment>
          ))}

          {Array.from({ length: sortData.length < 3 ? Math.min((totalCount || 0) - 2, 2) : 0 }).map(
            (_, index) => (
              <React.Fragment key={index}>
                <GridCell>
                  <FullCandidateBadge electo={fakeItem} winner={false} isBlank />
                </GridCell>
                <GridCell className="justify-end mgw:text-[40px] text-[35px]">
                  <Skeleton className="w-[60%] h-6" />
                </GridCell>
              </React.Fragment>
            )
          )}
        </GridTable>
      </div>
    </Card>
  )
}

const ComparativeCard = styled.div`
  display: grid;
  padding: 25px 20px;
  justify-items: center;
  align-content: center;
  grid-template-columns: 1fr 1.2fr 1fr;
`

const GridTable = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  background-color: ${colors.gray1Dch};
  border-radius: 0 0 8px 8px;
`

const GridCell = styled.div.attrs({
  className: 'mgw:text-[1.5em] text-[1.25em] mgw:py-[15px] py-[12px]',
})`
  display: flex;
  align-items: center;

  font-weight: 500;

  color: ${colors.blackDch};
  border-top: 2px solid ${colors.gray3Dch};

  padding-left: 10px;
  padding-right: 10px;
  &:nth-of-type(2n + 1) {
    padding-left: 60px;
  }
  &:nth-of-type(2n + 2) {
    padding-right: 60px;
  }
`

const CustomContentLabel = styled(ContentLabel).attrs({
  className: 'mgw:text-[50px] text-[45px] font-semibold',
})``
