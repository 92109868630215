import { colors } from '@/constants'
import { numberToPercent, numberWithDots } from '@/helpers/formatters'
import styled from 'styled-components'
import useElectionData from '@/stores/useElectionData'
import useRouting from '@/stores/useRouting'
import { ElectionType } from '@/types/Elections'

type Participacion = {
  porcentaje: number
  votos: number
  escrutado: number
  hora: string
}

export default function Participacion() {
  const {
    updated_at,
    participation,
    segunda_vuelta: { participacion: participacion2V, updated_at: updated_at2V },
  } = useElectionData()
  const { currentElection } = useRouting()

  const data = (currentElection !== ElectionType.GORE_2V
    ? participation[currentElection as keyof typeof participation]
    : participacion2V) || {
    porcentaje_mesas_escrutadas: 0,
    votos: 0,
    votos_blancos: 0,
    votos_nulos: 0,
    participacion_porcentual: 0,
    inscritos: 0,
    resultados_comuna: {},
  }

  const today = new Date()
  const realUpdatedAt = (currentElection !== ElectionType.GORE_2V ? updated_at : updated_at2V) || ''

  return (
    <Wrapper>
      <WrapperText>
        Participación <BoldText>{numberToPercent(data?.participacion_porcentual || 0, 2)}</BoldText>
      </WrapperText>
      <WrapperText>
        Votos <BoldText>{numberWithDots(data?.votos || 0)}</BoldText>
      </WrapperText>
      <WrapperText>
        Escrutado el{' '}
        <BoldText>{numberToPercent(data?.porcentaje_mesas_escrutadas || 0, 2)}</BoldText>
        {currentElection !== ElectionType.GORE_2V ? ', ' : ' a las '}
        <BoldText>
          {currentElection !== ElectionType.GORE_2V
            ? '27 de Oct.'
            : data?.porcentaje_mesas_escrutadas === 0
            ? parseDate(today)
            : parseDate(realUpdatedAt)}
        </BoldText>
      </WrapperText>
    </Wrapper>
  )
}

function parseDate(date: string | Date) {
  const hour = date
    ? new Date(date).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })
    : '--:--'
  const dayMonth = date
    ? new Date(date)
        .toLocaleDateString('es-CL', {
          day: '2-digit',
          month: 'short',
        })
        .replace(/-|\s/, ' ')
        .replace(/ (\w+)/, (_, month) => ` ${month.charAt(0).toUpperCase()}${month.slice(1)}`)
    : '24 Nov'
  return `${hour} hrs, ${dayMonth}.`
}

const Wrapper = styled.div.attrs({
  className:
    'mgw:text-[28px] mgw:mt-[-0.5rem] mgw:gap-[35px] gap-x-[30px] mt-[-0.5rem] text-[20px]',
})`
  display: flex;
  flex-wrap: wrap;
  line-height: 33.88px;
  letter-spacing: 0.03rem;
  color: ${colors.blackDch};
`

const WrapperText = styled.div.attrs({
  className: 'mgw:gap-3 gap-2',
})``

const BoldText = styled.span`
  font-weight: 700;
`
