import React from 'react'
import { Outlet } from 'react-router-dom'
import Alianza from './Alianza'
import { ErrorBoundary } from 'react-error-boundary'

const MainMagicLayout: React.FC = () => {
  document.addEventListener('contextmenu', (event) => event.preventDefault())
  return (
    <ErrorBoundary fallback={<Alianza isError={true} />}>
      <div className="w-full h-full">
        <Outlet />
      </div>
    </ErrorBoundary>
  )
}

export default MainMagicLayout
