import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { cn } from '@/lib/utils'
import { comunas } from '@/stores/useTerritorios'
import { type SelectProps } from '@radix-ui/react-select'
import { ChevronDown } from 'lucide-react'
import { GSComunas } from '@/stores/useTerritorios'
import { useNavigate } from 'react-router-dom'

type ComunaSelectorProps = {
  onClick: (value: string) => void
  region: number | undefined
  className?: string
  showIcon?: boolean
  eleccion: string
} & SelectProps

export default function ComunaSelector({
  onClick,
  region,
  value,
  className,
  showIcon = true,
  eleccion,
}: ComunaSelectorProps) {
  const navigate = useNavigate()
  const orderedComunas = comunas.sort((a, b) => {
    if (a.nombre > b.nombre) {
      return 1
    }
    if (a.nombre < b.nombre) {
      return -1
    }
    return 0
  })

  return (
    <Select
      onValueChange={(value) => {
        if (value === 'todas') {
          if (eleccion === 'concejales') return navigate(`/${eleccion}/territorio/${region}/pactos`)
          return navigate(`/${eleccion}/territorio/${region}`)
        }
        return onClick(value)
      }}
      value={value}
    >
      <SelectTrigger
        className={cn(`w-[180px] ${className}`, {
          'justify-start gap-1': !showIcon,
        })}
        showIcon={showIcon}
      >
        <SelectValue placeholder="Comunas" className="underline underline-offset-1" />
        {!showIcon && <ChevronDown className="h-4 w-4 opacity-50" />}
      </SelectTrigger>

      <SelectContent onClick={(e) => e.stopPropagation()}>
        <SelectGroup>
          {eleccion !== 'datos-comunales' && (
            <SelectItem
              key="todas"
              value="todas"
              className=" font-semibold  border-b border-gray-200"
            >
              Ver todas las comunas
            </SelectItem>
          )}
          {
            // Add Gran Santiago
            region === 17
              ? orderedComunas
                  .filter((c) => GSComunas.includes(c.codigo))
                  .map((option) => (
                    <SelectItem key={option.codigo} value={String(option.codigo)}>
                      {option.nombre}
                    </SelectItem>
                  ))
              : orderedComunas
                  .filter((option) => option.region === region)
                  .map((option) => (
                    <SelectItem key={option.codigo} value={String(option.codigo)}>
                      {option.nombre}
                    </SelectItem>
                  ))
          }
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}
