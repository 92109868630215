import GCambioMantienePacto, { type Data } from '@/components/Web/GORE/CambioMantienePacto'
import { colors } from '@/constants'
import { buildCambioMantienePactoGORE } from '@/lib/buildDataViews'
import useElectionData from '@/stores/useElectionData'
import { regiones } from '@/stores/useTerritorios'
import { useMemo } from 'react'
import gore2021 from '@/assets/files/gore2021.json'
import { ElectionType } from '@/types/Elections'

export default function GoreCambioPacto() {
  const {
    files: { gore },
  } = useElectionData()

  const data: Data[] = useMemo(() => {
    if (!gore) return []
    return buildCambioMantienePactoGORE(gore, gore2021, 'cambia', 18, ElectionType.GORE, false)
  }, [gore])

  const regionesData = useMemo(
    () =>
      regiones
        .filter((region) => region.codigo !== 17)
        .sort((a, b) => {
          if (a.orden && b.orden) {
            return a.orden - b.orden
          }
          return 0
        })
        .map((region) => {
          const defaultColor = data.length > 0 ? colors.gray2Dch : colors.gray1Dch
          return {
            realName: region.nombre || '',
            code: region.codigo,
            name: region.acronimo || region.nombre,
            selectedColor:
              data.find((item) => item.region_codigo === region.codigo)?.electo_2024.color ||
              defaultColor,
          }
        }),
    [data]
  )

  return (
    <GCambioMantienePacto
      title="Regiones que cambian sector político"
      data={data}
      regiones={regionesData}
      isMantiene={false}
    />
  )
}
