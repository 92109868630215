import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import VoteSVG from '@/assets/vote.svg'
import FirstRoundSVG from '@/assets/1V.svg'
import CheckIconSVG from '@/assets/checkIcon.svg'
import ContentPercentageBar from '@/components/PercentageBar'
import Card from '@/components/MagicWall/Card'
import ReelectoBadge from '@/components/ReelectoBadge'
import { Skeleton } from '@/components/ui/skeleton'
import { colors } from '@/constants'
import { numberWithDots } from '@/helpers/formatters'
import { cn } from '@/lib/utils'

type ICandidateBadge = {
  nombre?: string
  imagen?: string
  color?: string
  darkColor?: string
  electo?: boolean
  isEmpty?: boolean
  pacto?: string
  partido?: string
  primeraVuelta?: boolean
  reelecto?: boolean
  isAllGrey?: boolean
}

type Data = {
  nombre: string
  imagen: string
  color: string
  darkColor: string
  electo: boolean
  pacto: string
  partido: string
  votos: number
  porcentaje: number
  order: number
  reelecto?: boolean
  primera_vuelta?: boolean
}

type ElectosComuna = {
  data: Data[]
  title: string
  regionView: boolean
  linkToFirstRound?: string
  participation?: {
    percentage: string
    votes: string
    blankVotes: string
    nullVotes: string
    totalVoters: string
  }
}

export default function Versus2V({
  data,
  title,
  regionView,
  linkToFirstRound = '',
  participation = {
    percentage: '0,0%',
    votes: '000.000',
    blankVotes: '000.000',
    nullVotes: '000.000',
    totalVoters: '000.000',
  },
}: ElectosComuna) {
  const sortData = [...data].sort((a, b) => a.order - b.order)

  return (
    <Card
      title={title}
      statusLabel="PRELIMINAR"
      toggle={<ButtonToFirstRound link={linkToFirstRound} firstRound />}
      participation={regionView ? participation : undefined}
    >
      <div className="flex flex-col h-full pb-[30px]">
        <ComparativeCard className="flex-grow gap-3">
          {sortData.length > 0 ? (
            <CandidateBadge
              partido={sortData[0].partido || ''}
              electo={sortData[0].electo || false}
              reelecto={sortData[0].reelecto || false}
              primeraVuelta={sortData[0]?.primera_vuelta || false}
              imagen={sortData[0].imagen}
              color={sortData[0].color}
              darkColor={sortData[0].darkColor}
              pacto={sortData[0].pacto}
              nombre={sortData[0].nombre}
            />
          ) : (
            <CandidateBadge isEmpty />
          )}

          <div className="flex mgw:gap-8 gap-5 border-b-2 border-[lightgray] w-full justify-center">
            <CustomPercentageBar
              color={sortData[0]?.color || ''}
              porcentaje={sortData[0]?.porcentaje || 0}
              votos={numberWithDots(sortData[0]?.votos || 0)}
            />
            <CustomPercentageBar
              color={sortData[1]?.color || ''}
              porcentaje={sortData[1]?.porcentaje || 0}
              votos={numberWithDots(sortData[1]?.votos || 0)}
              isAllGrey={sortData[0]?.primera_vuelta || false}
            />
          </div>

          {sortData.length > 1 ? (
            <CandidateBadge
              partido={sortData[1].partido || ''}
              electo={sortData[1].electo || false}
              reelecto={sortData[1].reelecto || false}
              primeraVuelta={false}
              imagen={sortData[1].imagen}
              color={sortData[1].color}
              darkColor={sortData[1].darkColor}
              pacto={sortData[1].pacto}
              nombre={sortData[1].nombre}
              isAllGrey={sortData[0]?.primera_vuelta || false}
            />
          ) : (
            <CandidateBadge isEmpty />
          )}
        </ComparativeCard>
      </div>
    </Card>
  )
}

function SupPercentage({ percentage }: { percentage: number }) {
  return (
    <div className="flex items-center justify-center">
      <div className="flex justify-end items-center relative">
        {percentage.toFixed(1).replace('.', ',')}
        <sup className="absolute top-[32px] right-[-29px] text-[0.8em]">%</sup>
      </div>
    </div>
  )
}

type CustomContentLabelProps = {
  color: string
  porcentaje: number
  votos?: string
  isAllGrey?: boolean
}

function CustomPercentageBar({
  color,
  porcentaje,
  votos = '0',
  isAllGrey = false,
}: CustomContentLabelProps) {
  return (
    <ContentPercentageBar
      className={cn('mgw:max-w-[100px] w-[85px]', { 'grayscale opacity-50': isAllGrey })}
      outerContent={
        <CustomContentLabel $color={color}>
          <SupPercentage percentage={porcentaje} />
        </CustomContentLabel>
      }
      maxHeight={90}
      percentage={porcentaje}
      color={color}
      votes={votos || '0'}
    />
  )
}

export function ButtonToFirstRound({ link, firstRound }: { link: string; firstRound: boolean }) {
  const navigate = useNavigate()
  return (
    <ContainerButton onClick={() => navigate(link)}>
      <VoteSVG className="w-[23px] h-[25px]" />
      <div className="font-bold text-[20px]">{firstRound ? '1V' : '2V'}</div>
    </ContainerButton>
  )
}

function CandidateBadge({
  nombre = '',
  imagen = 'userProfile.png',
  color = '#3c3c3c',
  darkColor = '#3c3c3c',
  pacto = '',
  electo = false,
  reelecto = false,
  partido = '',
  isEmpty = false,
  primeraVuelta = false,
  isAllGrey = false,
}: ICandidateBadge) {
  const splitName = nombre.trim().split(' ')
  const firstLastName = splitName
    .slice(1, splitName.length - 1)
    .join(' ')
    .toLowerCase()
  const secondLastNameFirstCharacter = splitName[splitName.length - 1].charAt(0)

  return (
    <div
      className={cn('flex flex-col items-center gap-2 w-[235px]', {
        'grayscale opacity-60': isAllGrey,
      })}
    >
      <div className="relative w-full h-full flex justify-center">
        <CandidateImage src={imagen} $borderColor={color} $isAllGrey={isAllGrey} />
        {reelecto && (
          <ReelectoBadge
            id={`reeleccion-tag-${color}`}
            color={color}
            className="absolute top-[38px] left-[40px] scale-[1.2]"
          />
        )}
        {electo && !primeraVuelta ? (
          <CheckIconSVG className="absolute bottom-0 right-[30px]" />
        ) : null}
        {primeraVuelta && (
          <FirstRoundSVG className="absolute bottom-0 right-[30px] w-[52px] h-[52px]" />
        )}
      </div>
      {isEmpty ? (
        <div className="w-full flex flex-col items-center gap-1">
          <Skeleton className="w-[70%] h-[52px]" />
          <Skeleton className="w-[95%] h-[60px]" />
          <Skeleton className="w-[70%] h-[42px]" />
          <Skeleton className="w-full h-[30px]" />
        </div>
      ) : (
        <>
          <Wrapper>
            {splitName[0] === 'MARÍA' ? (
              <div className="text-center text-[35px] font-medium">M. José</div>
            ) : (
              <div className="text-center text-[35px] font-medium lowercase first-letter:uppercase">
                {splitName[0]}
              </div>
            )}

            <div className="capitalize text-nowrap text-[40px] mt-[-15px] font-medium">
              {firstLastName.slice(0, 10)}
              {firstLastName.length > 10 && '.'}{' '}
              <span className="capitalize">{secondLastNameFirstCharacter}.</span>
            </div>
          </Wrapper>
          <PartidoLabel className="text-[20px] my-[5px] min-h-[42px] max-w-full text-wrap">
            {partido}
          </PartidoLabel>
          <PactoLabel $color={color} $darkColor={darkColor || '#3c3c3c'}>
            {pacto}
          </PactoLabel>
        </>
      )}
    </div>
  )
}

const Wrapper = styled.div`
  color: ${colors.blackDch};
`

const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  background-color: ${colors.gray3Dch};
  border-radius: 8px;
  color: ${colors.whiteDch};
  cursor: pointer;
`

const ComparativeCard = styled.div`
  display: grid;
  padding: 25px 20px;
  justify-items: center;
  align-content: center;
  grid-template-columns: 1fr 1.2fr 1fr;
`

const CustomContentLabel = styled.div.attrs<{ $color: string }>({
  className: 'mgw:text-[50px] text-[45px] font-semibold',
})`
  color: ${({ $color }) => $color || colors.blackDch};
`

const PartidoLabel = styled.div`
  font-family: 'JetBrains Mono';
  text-transform: uppercase;
  text-align: center;
  color: #525252;
  line-height: 21.9px;
`

const CandidateImage = styled.img<{ $borderColor: string; $isAllGrey: boolean }>`
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  width: 80%;
  object-fit: cover;
  border-color: ${({ $borderColor, $isAllGrey }) => (!$isAllGrey ? $borderColor : '#BDBDBD')};
  border-width: 6px;
`

const PactoLabel = styled.div.attrs<{ $color: string; $darkColor: string }>({
  className: 'mgw:text-[20px] text-[17px] tracking-wide mgw:w-[230px] w-[200px]',
})`
  height: fit-content;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  background-color: ${({ $color }) => `${$color}50`};
  color: ${({ $darkColor }) => $darkColor};
`
