import styled from 'styled-components'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { formatFullName, numberToPercent, numberWithDots } from '@/helpers/formatters'
import { PACTOS_2V_GORE } from '@/constants/pactos'
import CheckIcon from '@/assets/checkIcon.svg'
import { RegionData, RegionesData } from '@/types/Iframe'
import { colors } from '@/constants/colors'
import VersusBar from '@/components/Iframe2Vuelta/VersusBar'

export default function ElectionResultsTable({ summaryGore }: { summaryGore: RegionesData }) {
  summaryGore[13].orden = -1 // Metropolitana first for this view
  return (
    <div className="flex flex-col w-full gap-[10px] sifr:gap-[12px] overflow-auto pr-1">
      {summaryGore &&
        Object.entries(summaryGore)
          .sort(([, a], [, b]) => (a as RegionData).orden - (b as RegionData).orden)
          .map(([, region]) => {
            const typedRegion = region as RegionData
            if (typedRegion.currentSegundos.length === 0) return null
            typedRegion.currentSegundos.sort((a, b) => b.porcentaje - a.porcentaje)

            return (
              <div className="flex flex-col gap-2" key={typedRegion.nombre}>
                <RegionHeader>{typedRegion.nombre}</RegionHeader>
                <div className="flex gap-[6px] ifr:gap-[10px] justify-between px-1">
                  {typedRegion.currentSegundos.map((candidate, idx) => (
                    <Candidate
                      key={`candidate-${candidate.nombre}`}
                      className={`flex relative ${idx === 1 ? 'flex-row-reverse' : 'flex-row'}`}
                    >
                      <StyledAvatar
                        color={PACTOS_2V_GORE[candidate.cod_pacto]?.color || '#1E9724'}
                        className="h-[46px] sifr:h-[64px] w-[46px] sifr:w-[64px] "
                      >
                        <AvatarImage
                          src={candidate.imagen || '/userProfile.png'}
                          alt={candidate.nombre}
                        />
                        <AvatarFallback>{candidate.nombre.charAt(0)}</AvatarFallback>
                      </StyledAvatar>
                      {idx === 0 && candidate.votos > 0 && (
                        <CheckIcon
                          className="absolute w-[12px] h-[12px] sifr:w-[18px] sifr:h-[18px] 
                          bottom-[0px] left-[31px] sifr:bottom-[0px] sifr:left-[44px] "
                        />
                      )}
                      <CandidateInfo
                        className={`flex flex-col gap-[0px] ${idx === 1 ? 'text-end' : ''}`}
                      >
                        <div className="text-balance leading-[1]">
                          <div className="font-medium text-[10px] sifr:text-[12px] pt-[1px] sifr:pt-[2px]">
                            {formatFullName(candidate.nombre, 9, true)}
                          </div>
                        </div>
                        <div className="text-[16px] sifr:text-[20px] font-semibold leading-[1] sifr:leading-5 pt-[2px] sifr:pt-[3px]">
                          {numberToPercent(candidate.porcentaje, 1)}{' '}
                        </div>
                        <VoteCount>{numberWithDots(candidate.votos)}</VoteCount>
                        <StyledBadge
                          color={PACTOS_2V_GORE[candidate.cod_pacto]?.color || '#1E9724'}
                          $darkColor={PACTOS_2V_GORE[candidate.cod_pacto]?.darkColor || '#1E9724'}
                        >
                          {(PACTOS_2V_GORE[candidate.cod_pacto]?.name || 'OTROS').toUpperCase()}
                        </StyledBadge>
                      </CandidateInfo>
                    </Candidate>
                  ))}
                </div>
                <div className="px-1">
                  <VersusBar
                    percentageA={typedRegion.currentSegundos[0].porcentaje}
                    percentageB={typedRegion.currentSegundos[1].porcentaje}
                    colorA={
                      PACTOS_2V_GORE[typedRegion.currentSegundos[0].cod_pacto]?.color ||
                      colors.gray2Dch
                    }
                    colorB={
                      PACTOS_2V_GORE[typedRegion.currentSegundos[1].cod_pacto]?.color ||
                      colors.gray2Dch
                    }
                  ></VersusBar>
                </div>
              </div>
            )
          })}
    </div>
  )
}

const Candidate = styled.div`
  gap: 6px;
`

const RegionHeader = styled.div.attrs({
  className: 'text-[11px] px-1',
})`
  font-weight: 700;
  color: ${colors['blackDch']};
  background-color: ${colors['gray6Dch']};
  border-top: 1px solid ${colors['grayVersusDch']};
`

const CandidateInfo = styled.div.attrs({
  className: `text-[9px] sifr:text-[10px]`,
})`
  color: ${colors['blackDch']};
`

const VoteCount = styled.div.attrs({
  className: 'text-[8px] sifr:text-[12px] pt-[2px] sifr:pt-[3px]',
})`
  font-weight: 300;
  line-height: 1;
`

const StyledAvatar = styled(Avatar).attrs({
  className: 'h-10 w-10 border-[1px] sifr:border-2',
})<{ color: string }>`
  border-color: ${(props) => props.color};
`

const StyledBadge = styled.div.attrs({
  className: 'text-[6px] sifr:text-[8px] pt-[1px] sifr:pt-[0px] leading-[1] mt-auto',
})<{ color: string; $darkColor: string | undefined }>`
  color: ${(props) => props.$darkColor};
  font-weight: 700;
`
