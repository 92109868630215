import { colors } from '@/constants/colors'
import { ReactNode } from 'react'
import styled from 'styled-components'

interface SwitchProps {
  selected?: boolean
  children: ReactNode
  onClick: () => void
}

export default function Switch({ selected = false, children, onClick }: SwitchProps) {
  return (
    <StyledBtn selected={selected} onClick={onClick}>
      {children}
    </StyledBtn>
  )
}

const StyledBtn = styled.button.attrs<{ selected: boolean }>({
  className: 'h-[16px] sifr:h-[22px] ifr:h-[30px] text-[10px] sifr:text-[12px] ifr:text-[17px]',
})`
  border-bottom: ${(props) =>
    props.selected ? `3px solid ${colors['blueDch']}` : `1px solid ${colors['gray2Dch']}`};
  color: ${(props) => (props.selected ? colors['blueDch'] : colors['blackDch'])};
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
  text-align: center;
`
