import styled from 'styled-components'
import { Fragment, ReactNode } from 'react'
import { colors } from '@/constants'
import Card from './Card'
import PactoInformation from './PactoInformation'
import { voteToMillionString } from '@/helpers/formatters'
import { cn } from '@/lib/utils'
import { numberWithDots } from '@/helpers/formatters'

type PartidoResult = {
  nombre_partido: string
  cod_partido: string
  electos: number
}

export type PactoResult = {
  nombre: string
  abreviatura: string
  color: string
  electos: number
  porcentaje: number
  votos: number
  orden: number
  cod_pacto: string
  electos_partido: PartidoResult[]
}

type Pactos = {
  election?: string
  data: PactoResult[]
  verticalSeparatorBefore?: string
  colVerticalSeparatorBefore?: number
  children: ReactNode
  minWidthPactos: string
  minWidthPartidos?: string
  numberOfPartidosbyColumn?: number
  numberofPartidosbyColumnsMobile?: number
  className?: string
  title?: string
  showPartidosMobile?: boolean
  toggle?: ReactNode
  participation?: ReactNode
  includeVotes?: boolean
  secondRound?: boolean
  resultsPactos1V?: {
    [key: string]: number
  }
}

export function generateCircles(
  data: PactoResult[],
  totalCircles: number,
  highlightedPacto = '',
  showHighlight = false
) {
  const circles: {
    color: string
    key: string
    data: {
      cod_pacto: string
    }
  }[] = []
  data.forEach(({ color, electos, abreviatura, cod_pacto }) => {
    for (let i = 0; i < electos; i++) {
      circles.push({
        color: highlightedPacto === cod_pacto ? color : showHighlight ? `${color}40` : color,
        key: `${abreviatura}-${i.toString()}`,
        data: {
          cod_pacto,
        },
      })
    }
  })
  if (circles.length < totalCircles) {
    for (let i = circles.length; i < totalCircles; i++) {
      circles.push({
        color: colors.gray2Dch,
        key: `empty-${i.toString()}`,
        data: {
          cod_pacto: '',
        },
      })
    }
  }
  return circles
}

export default function MPactos({
  children,
  data,
  verticalSeparatorBefore = '',
  colVerticalSeparatorBefore = 2,
  numberOfPartidosbyColumn = 6,
  numberofPartidosbyColumnsMobile = 4,
  className = '',
  title,
  minWidthPactos,
  showPartidosMobile = false,
  toggle,
  participation,
  includeVotes = true,
  secondRound = false,
  resultsPactos1V = {},
}: Pactos) {
  const orderedData = data.sort((a, b) => a.orden - b.orden)
  const titleShow = title ? title : 'Electos'

  const numberOfPactos = orderedData.length
  return (
    <Card
      title={titleShow}
      separatorColor="#CCCCCC"
      statusLabel={'PRELIMINAR'}
      toggle={toggle}
      participationInfo={participation}
    >
      <Wrapper className={className}>
        <div className="max-w-[870px]">
          {children}
          <GridPactos
            $cols={numberOfPactos}
            $minWidthChild={minWidthPactos}
            $colMinContent={colVerticalSeparatorBefore}
          >
            {orderedData.map((pacto) => (
              <>
                {pacto.nombre === verticalSeparatorBefore && <VSeparatorPactos />}
                <GridChild
                  key={`partidos-${pacto.abreviatura}`}
                  $minWidthChild={minWidthPactos}
                  $minWidthChildMobile="80px"
                >
                  <PactoInformation
                    isLong={pacto.nombre.length === 3}
                    color={pacto.color}
                    electos={pacto.electos}
                    name={pacto.nombre}
                    abreviatura={pacto.abreviatura}
                    showTooltip={pacto.nombre === verticalSeparatorBefore}
                  >
                    <PactoVotes
                      className={cn({
                        'min-h-[30px]': includeVotes,
                        'min-h-[10px]': !includeVotes,
                      })}
                    >
                      {includeVotes && !secondRound ? (
                        `${voteToMillionString(pacto.votos)} votos`
                      ) : (
                        <div>
                          <div>
                            1V <span className="ml-1">{numberWithDots(pacto.votos)}</span>
                          </div>
                          <div>
                            2V{' '}
                            <span className="ml-1">
                              {numberWithDots(resultsPactos1V?.[pacto.abreviatura] || 0)}
                            </span>
                          </div>
                        </div>
                      )}
                    </PactoVotes>

                    <PartidosResult
                      cols={Math.ceil(pacto.electos_partido.length / numberOfPartidosbyColumn)}
                      rows={numberOfPartidosbyColumn}
                    >
                      {pacto.electos_partido.map((partido) => (
                        <Fragment key={partido.cod_partido}>
                          <div className="pr-1 text-[11px] font-bold text-right text-gris3Dch hidden xl:block">
                            {partido.electos}
                          </div>
                          <div className="pr-1 text-[11px] text-gris3Dch hidden xl:block">
                            {partido.cod_partido}
                          </div>
                        </Fragment>
                      ))}
                    </PartidosResult>

                    {showPartidosMobile && (
                      <PartidosResultMobile
                        cols={Math.ceil(
                          pacto.electos_partido.length / numberofPartidosbyColumnsMobile
                        )}
                        rows={Math.ceil(pacto.electos_partido.length)}
                        className="xl:hidden -mt-2"
                      >
                        {pacto.electos_partido.map((partido) => (
                          <Fragment key={partido.cod_partido}>
                            <div className="text-[11px] font-bold text-gris3Dch text-right">
                              {partido.electos}
                            </div>
                            <div className="pl-1 text-[11px] text-gris3Dch ">
                              {partido.cod_partido}
                            </div>
                          </Fragment>
                        ))}
                      </PartidosResultMobile>
                    )}
                  </PactoInformation>
                </GridChild>
              </>
            ))}
          </GridPactos>
        </div>
      </Wrapper>
    </Card>
  )
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`
const GridPactos = styled.div.attrs<{
  $cols: number
  $minWidthChild: string
  $colMinContent: number
}>({
  className: 'xl:gap-[8px] flex flex-wrap xl:grid justify-between gap-y-4',
})`
  grid-template-columns:
    repeat(
      ${({ $cols, $colMinContent }) => $cols - $colMinContent},
      minmax(${({ $minWidthChild }) => $minWidthChild}, 1fr)
    )
    min-content repeat(
      ${({ $colMinContent }) => $colMinContent},
      minmax(${({ $minWidthChild }) => $minWidthChild}, 1fr)
    );
  grid-template-rows: 1fr;
  grid-auto-flow: column;
  width: 100%;
  height: fit-content;
  max-width: 870px;
`

const GridChild = styled.div<{ $minWidthChild: string; $minWidthChildMobile: string }>`
  width: ${({ $minWidthChild }) => $minWidthChild};
  position: relative;
  @media (max-width: 768px) {
    width: ${({ $minWidthChildMobile }) => $minWidthChildMobile};
  }
`

const PartidosResult = styled.div.attrs<{ cols: number; rows: number }>({
  className: 'xl:py-[10px] xl:grid hidden',
})`
  grid-template-rows: repeat(${({ rows }) => rows}, 1fr);
  grid-template-columns: repeat(${({ cols }) => cols * 2}, min-content);
  height: 100%;
  width: 100%;
  color: ${colors.blackDch};
  column-gap: 2px;
  align-items: center;
`

const PartidosResultMobile = styled.div.attrs<{ cols: number; rows: number }>({
  className: 'py-[8px] mt-[1px] grid',
})`
  grid-template-rows: repeat(${({ rows }) => rows}, min-content);
  grid-template-columns: repeat(2, min-content);
  height: 100%;
  width: 100%;
  color: ${colors.blackDch};
  column-gap: 2px;
  align-items: center;
`

const VSeparatorPactos = styled.div`
  height: 100%;
  border-right: 0.6px solid ${colors.gray1Dch};
`

const PactoVotes = styled.div.attrs({
  className: 'text-[10px] xl:text-[12px] pt-1 font-normal xl:pt-2 -mb-1 max-h-[30px]',
})`
  color: ${colors.grayVersusDch};
  line-height: 12px;
`
