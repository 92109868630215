import { colors } from '@/constants/colors'
import styled from 'styled-components'

export default function VersusBar({
  percentageA,
  percentageB,
  colorA,
  colorB,
}: {
  percentageA: number
  percentageB: number
  colorA: string
  colorB: string
  mobileHeight?: number
  height?: number
}) {
  const validPercentageA = Math.max(0, Math.min(100, percentageA))
  const validPercentageB = Math.max(0, Math.min(100, percentageB))

  const total = validPercentageA + validPercentageB
  const proportionA = total > 0 ? (validPercentageA / total) * 100 : 0
  const proportionB = total > 0 ? (validPercentageB / total) * 100 : 0

  if (total === 0) {
    // Render a grey bar when both percentages are zero
    return (
      <div className={`relative h-[10px] sifr:h-[14px] w-full`}>
        <BarSegment width={100} $backgroundColor={colors.gray2Dch} />
        <CenteredDashedLine />
      </div>
    )
  }

  const isAGreater = validPercentageA >= validPercentageB
  const leftColor = isAGreater ? colorA : colorB
  const rightColor = isAGreater ? colorB : colorA
  const leftWidth = isAGreater ? proportionA : proportionB
  const rightWidth = isAGreater ? proportionB : proportionA

  return (
    <div className={`relative h-[10px] sifr:h-[14px] w-full`}>
      <div className="absolute inset-0 flex w-full">
        <BarSegment width={leftWidth} $backgroundColor={leftColor} />
        <BarSegment width={rightWidth} $backgroundColor={rightColor} />
      </div>
      <CenteredDashedLine />
    </div>
  )
}

const BarSegment = styled.div<{ width: number; $backgroundColor: string }>`
  width: ${({ width }) => `${width}%`};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  height: 100%;
`

const CenteredDashedLine = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  height: 100%;
  width: 1px;
  transform: translateX(-50%);
  background-image: linear-gradient(to bottom, white 3px, transparent 3px);
  background-size: 1px 4px;
  background-repeat: repeat-y;
`
