import Electos from '@/components/MagicWall/Electos'
import useRouting from '@/stores/useRouting'
import useTerritorios, { comunas } from '@/stores/useTerritorios'
import { useEffect, useMemo } from 'react'
import useElectionData from '@/stores/useElectionData'
import { CandidatoGore } from '@/types/Files'
import { jointCandidatosColor } from '@/lib/dataProcess'
import { ElectionType } from '@/types/Elections'
import { PACTOS } from '@/constants'

export function getGOREResultadosComuna(candidatos: CandidatoGore[], comuna: number) {
  return candidatos.map((candidato) => ({
    ...candidato,
    votos: candidato.resultados_comuna[comuna.toString()]?.votos || 0,
    porcentaje: candidato.resultados_comuna[comuna.toString()]?.porcentaje || 0,
  }))
}

export default function GElectosComuna() {
  const { currentComuna, currentRegion } = useRouting()
  const comuna = comunas.find((comuna) => comuna.codigo === currentComuna)
  const {
    files: { gore },
  } = useElectionData()
  const { setComunaColor } = useTerritorios()

  const linkToSecondRound = `/magic/segunda_vuelta/territorio/${currentRegion}/${currentComuna}`

  const regionGores = useMemo(
    () =>
      gore ? gore.filter((c) => c.cod_region.toString() === (currentRegion ?? '').toString()) : [],
    [gore, currentRegion]
  )

  const processedData = useMemo(() => {
    if (regionGores.length === 0 || !currentComuna) {
      return []
    }
    const orderedComunaGores = getGOREResultadosComuna(regionGores, currentComuna as number).sort(
      (a, b) => b.porcentaje - a.porcentaje
    )

    return jointCandidatosColor(orderedComunaGores, ElectionType.GORE).map((electo, i) => ({
      ...electo,
      pacto: PACTOS[ElectionType.GORE][electo.cod_pacto]?.shortName || electo.cod_pacto,
      order: i + 1,
      imagen: electo.imagen || '/userProfile.png',
    }))
  }, [regionGores, currentComuna])

  // process color for each comuna
  useEffect(() => {
    if (processedData.length > 0) {
      const goreElecto = processedData[0]
      setComunaColor({
        [currentComuna as number]: goreElecto.color || '',
      })
    }
  }, [setComunaColor, processedData, currentComuna])

  return (
    <Electos
      title={comuna?.nombre || ''}
      data={processedData}
      linkToSecondRound={linkToSecondRound}
    />
  )
}
