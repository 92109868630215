import Back from '@/assets/menu/back.svg'
import { electionParams, Tema, TEMAS } from '@/constants'
import useMagicFrame from '@/stores/useMagicFrame'
import useRouting from '@/stores/useRouting'
import { To, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Accordion from './Accordion'
import MagicButton from './MagicButton'

type MenuItem = {
  content: React.ReactNode
  navigateTo?: To
  onClick?: () => void
  icon?: string
  selected?: boolean
  expand?: boolean
}

const DEFAULT_MENU: MenuItem[] = [
  { content: <Back />, navigateTo: -1 as To },
  { content: 'Inicio', navigateTo: '/magic/elecciones', icon: '/menu/inicio.svg' },
]

const DeFAULT_MENU_CHILEDATO: MenuItem[] = [
  { content: <Back />, navigateTo: -1 as To },
  { content: 'Temas', navigateTo: '/magic/chile-en-datos', icon: '/menu/barChart.svg' },
]

export default function Menu() {
  const { currentElection, isMagicChileEnDatos, currentTema } = useRouting()
  const { setSubmenuOpen } = useMagicFrame()
  const navigate = useNavigate()

  let buttonsMap = DEFAULT_MENU
  if (currentElection) {
    buttonsMap = [
      ...DEFAULT_MENU,
      {
        content: electionParams[currentElection].menuName,
        onClick: () => setSubmenuOpen(true),
        icon: electionParams[currentElection].menuIcon,
        selected: true,
      },
    ]
  }
  if (isMagicChileEnDatos && currentTema) {
    buttonsMap = [
      ...DeFAULT_MENU_CHILEDATO,
      {
        content: TEMAS[currentTema as Tema].name,
        onClick: () => setSubmenuOpen(true),
        icon: TEMAS[currentTema as Tema].icon,
        selected: true,
        expand: false,
      },
    ]
  }

  return (
    <Wrapper>
      {buttonsMap.map(({ content, navigateTo, onClick, icon, selected }, index) => (
        <MagicButton
          key={index}
          selected={selected}
          onClick={() => {
            if (navigateTo) navigate(navigateTo)
            if (onClick) onClick()
          }}
        >
          {icon && <img src={icon} alt={`icon-${content}`} className="w-[25px] h-[25px]" />}

          {content}
        </MagicButton>
      ))}
      <Accordion />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;
  padding-top: 100px;
`
