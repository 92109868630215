import {
  PactosElectionType,
  PactosType,
  PartidosType,
  ElectionType,
  PactosListType,
} from '@/types/Elections'

export const PARTIDOS: PartidosType = {
  PS: {
    initials: 'PS',
    name: 'Partido Socialista de Chile',
    shortName: 'P. Socialista',
  },
  PPD: {
    initials: 'PPD',
    name: 'Partido por la Democracia',
    shortName: 'P. Democracia',
  },
  FA: {
    initials: 'FA',
    name: 'Frente Amplio',
    shortName: 'Frente Amplio',
  },
  PC: {
    initials: 'PC',
    name: 'Partido Comunista',
    shortName: 'P. Comunista',
  },
  AH: {
    initials: 'AH',
    name: 'Acción Humanista',
    shortName: 'Acción Humanista',
  },
  IND: {
    initials: 'IND',
    name: 'Independientes',
    shortName: 'Independientes',
  },
  PR: {
    initials: 'PR',
    name: 'Partido Radical',
    shortName: 'P. Radical',
  },
  DC: {
    initials: 'DC',
    name: 'Democracia Cristiana',
    shortName: 'Dem. Cristiana',
  },
  DEM: {
    initials: 'DEM',
    name: 'Demócratas',
    shortName: 'Demócratas',
  },
  APC: {
    initials: 'APC',
    name: 'Amarillos por Chile',
    shortName: 'Amarillos',
  },
  PDG: {
    initials: 'PDG',
    name: 'Partido de la Gente',
    shortName: 'P. de la Gente',
  },
  EVO: {
    initials: 'EVO',
    name: 'Evópoli',
    shortName: 'Evópoli',
  },
  RN: {
    initials: 'RN',
    name: 'Renovación Nacional',
    shortName: 'R. Nacional',
  },
  UDI: {
    initials: 'UDI',
    name: 'UDI',
    shortName: 'UDI',
  },
  PSC: {
    initials: 'PSC',
    name: 'Partido Social Cristiano',
    shortName: 'P. Social Crist.',
  },
  REP: {
    initials: 'REP',
    name: 'Partido Republicano',
    shortName: 'P. Republicano',
  },
  REPUBLICAN: {
    initials: 'REPUBLICAN',
    name: 'Partido Republicano',
    shortName: 'P. Republicano',
  },
  PI: {
    initials: 'PI',
    name: 'Partido Igualdad',
    shortName: 'P. Igualdad',
  },
  PH: {
    initials: 'PH',
    name: 'Partido Humanista',
    shortName: 'P. Humanista',
  },
  PP: {
    initials: 'PP',
    name: 'Partido Popular',
    shortName: 'P. Popular',
  },
  FRVS: {
    initials: 'FRVS',
    name: 'F. Regionalista Verde Social',
    shortName: 'F. Regionalista',
  },
  PL: {
    initials: 'PL',
    name: 'Partido Liberal',
    shortName: 'P. Liberal',
  },
  PAVP: {
    initials: 'PAVP',
    name: 'Alianza Verde Popular',
    shortName: 'A. Verde Popular',
  },
  PTR: {
    initials: 'PTR',
    name: 'Trabajadores Revolucionarios',
    shortName: 'Trabajadores',
  },
}

export const PACTOS_GORE: PactosElectionType = {
  PCR: {
    initials: 'PCR',
    name: 'Por Chile y sus Regiones',
    shortName: 'Por Ch. y sus Reg.',
    color: '#ba0286',
    darkColor: '#83015E',
    order: 1,
    partidos: [PARTIDOS.PS, PARTIDOS.PPD, PARTIDOS.FA, PARTIDOS.PC, PARTIDOS.AH, PARTIDOS.IND],
  },
  TRR: {
    initials: 'TRR',
    name: 'Tu Región Radical',
    shortName: 'Tu Reg. Radical',
    color: '#09d1e0',
    darkColor: '#079BA6',
    order: 2,
    partidos: [PARTIDOS.PR, PARTIDOS.IND],
  },
  PCM: {
    initials: 'PCM',
    name: 'Por un Chile Mejor',
    shortName: 'Por un Ch. Mejor',
    color: '#ff9a02',
    darkColor: '#C17400',
    order: 3,
    partidos: [PARTIDOS.DC, PARTIDOS.IND],
  },
  DEM: {
    initials: 'DEM',
    name: 'Demócratas',
    shortName: 'Dem.',
    color: '#0575c9',
    darkColor: '#045491',
    order: 4,
    partidos: [PARTIDOS.DEM, PARTIDOS.IND],
  },
  APC: {
    initials: 'APC',
    name: 'Amarillos por Chile',
    shortName: 'Amarillos',
    color: '#EDC000',
    darkColor: '#B19000',
    order: 5,
    partidos: [PARTIDOS.APC, PARTIDOS.IND],
  },
  PDG: {
    initials: 'PDG',
    name: 'Partido de la Gente',
    shortName: 'P. de la Gente',
    color: '#fd02b9',
    darkColor: '#C0008C',
    order: 6,
    partidos: [PARTIDOS.PDG, PARTIDOS.IND],
  },
  CHV: {
    initials: 'CHV',
    name: 'Chile Vamos',
    shortName: 'Chile Vamos',
    color: '#e81d0e',
    darkColor: '#B71509',
    order: 7,
    partidos: [PARTIDOS.EVO, PARTIDOS.RN, PARTIDOS.UDI, PARTIDOS.IND],
  },
  PSC: {
    initials: 'PSC',
    name: 'Partido Social Cristiano',
    shortName: 'P. Social Cristiano',
    color: '#8044fc',
    darkColor: '#6132C3',
    order: 8,
    partidos: [PARTIDOS.PSC, PARTIDOS.IND],
  },
  REP: {
    initials: 'REP',
    name: 'Republicanos',
    shortName: 'Rep.',
    color: '#0191fb',
    darkColor: '#006DBD',
    order: 9,
    partidos: [PARTIDOS.REP, PARTIDOS.IND],
  },
  OTROS: {
    initials: 'OTROS',
    name: 'Otros',
    shortName: 'Otros',
    color: '#1E9724',
    darkColor: '#1C7921',
    order: 10,
    partidos: [
      PARTIDOS.PI,
      PARTIDOS.PH,
      PARTIDOS.PP,
      PARTIDOS.FRVS,
      PARTIDOS.PL,
      PARTIDOS.PAVP,
      PARTIDOS.IND,
    ],
    subpactos: [
      {
        name: 'Izquierda Ecologista Popular',
        initialPartidos: ['PI', 'PH', 'PP', 'IND'],
      },
      {
        name: 'Regiones Verdes Liberales',
        initialPartidos: ['FRVS', 'PL', 'IND'],
      },
      {
        name: 'Ecologistas, Animalistas e Independientes',
        initialPartidos: ['PAVP', 'IND'],
      },
    ],
  },
  IND: {
    initials: 'IND',
    name: 'Independientes',
    shortName: 'Ind.',
    color: '#A37C5E',
    darkColor: '#6E543F',
    order: 11,
    partidos: [PARTIDOS.IND],
  },
}

export const PACTOS_ALCALDES: PactosElectionType = {
  CCM: {
    initials: 'CCM',
    name: 'Contigo Chile Mejor',
    shortName: 'Contigo Ch. Mejor',
    darkColor: '#C17400',
    color: '#ff9a02',
    order: 1,
    partidos: [
      PARTIDOS.FRVS,
      PARTIDOS.FA,
      PARTIDOS.AH,
      PARTIDOS.PC,
      PARTIDOS.DC,
      PARTIDOS.PL,
      PARTIDOS.PPD,
      PARTIDOS.PR,
      PARTIDOS.PS,
      PARTIDOS.IND,
    ],
  },
  CDE: {
    initials: 'CDE',
    name: 'Centro Democrático',
    shortName: 'Centro Dem.',
    darkColor: '#045491',
    color: '#0575c9',
    order: 2,
    partidos: [PARTIDOS.APC, PARTIDOS.DEM, PARTIDOS.IND],
  },
  PDG: {
    initials: 'PDG',
    name: 'Partido de la Gente',
    shortName: 'P. de la Gente',
    color: '#fd02b9',
    darkColor: '#C0008C',
    order: 3,
    partidos: [PARTIDOS.PDG, PARTIDOS.IND],
  },
  CHV: {
    initials: 'CHV',
    name: 'Chile Vamos',
    shortName: 'Ch. Vamos',
    darkColor: '#B71509',
    color: '#e81d0e',
    order: 4,
    partidos: [PARTIDOS.EVO, PARTIDOS.RN, PARTIDOS.UDI, PARTIDOS.IND],
  },
  PSC: {
    initials: 'PSC',
    name: 'Partido Social Cristiano',
    shortName: 'P. Social Cristiano',
    color: '#8044fc',
    darkColor: '#6132C3',
    order: 5,
    partidos: [PARTIDOS.PSC, PARTIDOS.IND],
  },
  REP: {
    initials: 'REP',
    name: 'Republicanos',
    shortName: 'Rep.',
    color: '#0191fb',
    darkColor: '#006DBD',
    order: 6,
    partidos: [PARTIDOS.REP, PARTIDOS.IND],
  },
  OTROS: {
    initials: 'OTROS',
    name: 'Otros',
    shortName: 'Otros',
    color: '#1E9724',
    darkColor: '#1C7921',
    order: 7,
    partidos: [PARTIDOS.PTR, PARTIDOS.PAVP, PARTIDOS.PI, PARTIDOS.PH, PARTIDOS.PP, PARTIDOS.IND],
    subpactos: [
      {
        name: 'Izquierda Ecologista Popular',
        initialPartidos: ['PI', 'PH', 'PP', 'IND'],
      },
      {
        name: 'Izquierda de Trabajadores e Independientes',
        initialPartidos: ['PTR', 'IND'],
      },
      {
        name: 'Ecologistas, Animalistas e Independientes',
        initialPartidos: ['PAVP', 'IND'],
      },
    ],
  },
  IND: {
    initials: 'IND',
    name: 'Independientes',
    shortName: 'Ind.',
    color: '#A37C5E',
    darkColor: '#6E543F',
    order: 8,
    partidos: [PARTIDOS.IND],
  },
}

export const PACTOS_CONCEJALES: PactosElectionType = {
  PCS: {
    initials: 'PCS',
    name: 'Por Chile, Seguimos',
    shortName: 'Por Ch. Seguimos',
    darkColor: '#B19000',
    color: '#edc000',
    order: 1,
    partidos: [PARTIDOS.FA, PARTIDOS.AH, PARTIDOS.PC, PARTIDOS.IND],
  },
  CMM: {
    initials: 'CMM',
    name: 'Chile Mucho Mejor',
    shortName: 'Ch. Mucho Mejor',
    darkColor: '#C17400',
    color: '#ff9a02',
    order: 2,
    partidos: [PARTIDOS.DC, PARTIDOS.PPD, PARTIDOS.PS, PARTIDOS.IND],
  },
  TCR: {
    initials: 'TCR',
    name: 'Tu Comuna Radical',
    shortName: 'Tu Com. Radical',
    darkColor: '#079BA6',
    color: '#09d1e0',
    order: 3,
    partidos: [PARTIDOS.PR, PARTIDOS.IND],
  },
  CDE: {
    initials: 'CDE',
    name: 'Centro Democrático',
    shortName: 'Centro Dem.',
    darkColor: '#045491',
    color: '#0575c9',
    order: 4,
    partidos: [PARTIDOS.DEM, PARTIDOS.APC, PARTIDOS.IND],
  },
  PDG: {
    initials: 'PDG',
    name: 'Partido de la Gente',
    shortName: 'P. de la Gente',
    darkColor: '#C0008C',
    color: '#fd02b9',
    order: 5,
    partidos: [PARTIDOS.PDG, PARTIDOS.IND],
  },
  CVUE: {
    initials: 'CVUE',
    name: 'Chile Vamos UDI - EVÓPOLI',
    shortName: 'Ch.Vamos UDI-EVO',
    color: '#e81d0e',
    darkColor: '#B71509',
    order: 6,
    partidos: [PARTIDOS.EVO, PARTIDOS.UDI, PARTIDOS.IND],
  },
  CVR: {
    initials: 'CVR',
    name: 'Chile Vamos RN',
    shortName: 'Ch.Vamos RN',
    darkColor: '#B71509',
    color: '#e81d0e',
    order: 7,
    partidos: [PARTIDOS.RN, PARTIDOS.IND],
  },
  PSC: {
    initials: 'PSC',
    name: 'Partido Social Cristiano',
    shortName: 'P. Social Cristiano',
    color: '#8044fc',
    darkColor: '#6132C3',
    order: 8,
    partidos: [PARTIDOS.PSC, PARTIDOS.IND],
  },
  REP: {
    initials: 'REP',
    name: 'Republicanos',
    shortName: 'Rep.',
    color: '#0191fb',
    darkColor: '#006DBD',
    order: 9,
    partidos: [PARTIDOS.REP, PARTIDOS.IND],
  },
  OTROS: {
    initials: 'OTROS',
    name: 'Otros',
    shortName: 'Otros',
    color: '#1E9724',
    darkColor: '#1C7921',
    order: 10,
    partidos: [
      PARTIDOS.PI,
      PARTIDOS.PH,
      PARTIDOS.PP,
      PARTIDOS.FRVS,
      PARTIDOS.PL,
      PARTIDOS.PAVP,
      PARTIDOS.IND,
      PARTIDOS.PTR,
    ],
    subpactos: [
      {
        name: 'Izquierda Ecologista Popular',
        initialPartidos: ['PI', 'PH', 'PP', 'IND'],
      },
      {
        name: 'Verdes Liberales por una Comuna Segura',
        initialPartidos: ['FRVS', 'PL', 'IND'],
      },
      {
        name: 'Ecologistas, Animalistas e Independientes',
        initialPartidos: ['PAVP', 'IND'],
      },
      {
        name: 'Izquierda de Trabajadores e Independientes',
        initialPartidos: ['PTR', 'IND'],
      },
    ],
  },
  IND: {
    initials: 'IND',
    name: 'Independientes',
    shortName: 'Ind.',
    color: '#A37C5E',
    darkColor: '#6E543F',
    order: 11,
    partidos: [PARTIDOS.IND],
  },
}

export const PACTOS_CORE: PactosElectionType = {
  TTC: {
    initials: 'TTC',
    name: 'Todas y Todos por Chile',
    shortName: 'Todos por Ch.',
    darkColor: '#C17400',
    color: '#ff9a02',
    order: 1,
    partidos: [PARTIDOS.PC, PARTIDOS.AH, PARTIDOS.IND],
  },
  FAM: {
    initials: 'FAM',
    name: 'Frente Amplio',
    shortName: 'Frente Amplio',
    color: '#EDC000',
    darkColor: '#B19000',
    order: 2,
    partidos: [PARTIDOS.FA, PARTIDOS.IND],
  },
  LMC: {
    initials: 'LMC',
    name: 'Lo Mejor para Chile',
    shortName: 'Lo Mejor para Ch.',
    darkColor: '#C17400',
    color: '#ff9a02',
    order: 3,
    partidos: [PARTIDOS.PS, PARTIDOS.PPD, PARTIDOS.IND],
  },
  TRR: {
    initials: 'TRR',
    name: 'Tu Región Radical',
    shortName: 'Tu Reg. Radical',
    color: '#09d1e0',
    darkColor: '#079BA6',
    order: 4,
    partidos: [PARTIDOS.PR, PARTIDOS.IND],
  },
  PCM: {
    initials: 'PCM',
    name: 'Por un Chile Mejor',
    shortName: 'Por un Ch.Mejor',
    darkColor: '#C17400',
    color: '#ff9a02',
    order: 5,
    partidos: [PARTIDOS.DC, PARTIDOS.IND],
  },
  CDE: {
    initials: 'CDE',
    name: 'Centro Democrático',
    shortName: 'Centro Dem.',
    darkColor: '#045491',
    color: '#0575c9',
    order: 6,
    partidos: [PARTIDOS.IND, PARTIDOS.DEM, PARTIDOS.APC],
  },
  PDG: {
    initials: 'PDG',
    name: 'Partido de la Gente',
    shortName: 'P. de la Gente',
    darkColor: '#C0008C',
    color: '#fd02b9',
    order: 7,
    partidos: [PARTIDOS.PDG, PARTIDOS.IND],
  },
  CVR: {
    initials: 'CVR',
    name: 'Chile Vamos RN',
    shortName: 'Ch.Vamos RN',
    darkColor: '#B71509',
    color: '#e81d0e',
    order: 8,
    partidos: [PARTIDOS.RN, PARTIDOS.IND],
  },
  CVE: {
    initials: 'CVE',
    name: 'Chile Vamos EVÓPOLI',
    shortName: 'Ch.Vamos EVO',
    darkColor: '#B71509',
    color: '#e81d0e',
    order: 9,
    partidos: [PARTIDOS.EVO, PARTIDOS.IND],
  },
  CVU: {
    initials: 'CVU',
    name: 'Chile Vamos UDI',
    shortName: 'Ch.Vamos UDI',
    darkColor: '#B71509',
    color: '#e81d0e',
    order: 10,
    partidos: [PARTIDOS.UDI, PARTIDOS.IND],
  },
  PSC: {
    initials: 'PSC',
    name: 'Partido Social Cristiano',
    shortName: 'P. Social Cristiano',
    color: '#8044fc',
    darkColor: '#6132C3',
    order: 11,
    partidos: [PARTIDOS.PSC, PARTIDOS.IND],
  },
  REP: {
    initials: 'REP',
    name: 'Republicanos',
    shortName: 'Rep.',
    color: '#0191fb',
    darkColor: '#006DBD',
    order: 12,
    partidos: [PARTIDOS.REP, PARTIDOS.IND],
  },
  OTROS: {
    initials: 'OTROS',
    name: 'Otros',
    shortName: 'Otros',
    color: '#1E9724',
    darkColor: '#1C7921',
    order: 13,
    partidos: [
      PARTIDOS.PI,
      PARTIDOS.PH,
      PARTIDOS.PP,
      PARTIDOS.FRVS,
      PARTIDOS.PL,
      PARTIDOS.PAVP,
      PARTIDOS.IND,
    ],
    subpactos: [
      {
        name: 'Izquierda Ecologista Popular',
        initialPartidos: ['PI', 'PH', 'PP', 'IND'],
      },
      {
        name: 'Regiones Verdes Liberales',
        initialPartidos: ['FRVS', 'PL', 'IND'],
      },
      {
        name: 'Ecologistas, Animalistas e Independientes',
        initialPartidos: ['PAVP', 'IND'],
      },
    ],
  },
  IND: {
    initials: 'IND',
    name: 'Independientes',
    shortName: 'Ind.',
    color: '#A37C5E',
    darkColor: '#6E543F',
    order: 14,
    partidos: [PARTIDOS.IND],
  },
}

export const PACTOS_2V_GORE: PactosElectionType = {
  RVL: {
    initials: 'RVL',
    name: 'Regiones Verdes Liberales',
    shortName: 'Reg. Verdes Lib.',
    color: '#1E9724',
    darkColor: '#1C7921',
    order: 1,
    partidos: [PARTIDOS.FRVS, PARTIDOS.PL, PARTIDOS.IND],
  },
  PCR: {
    initials: 'PCR',
    name: 'Por Chile y sus Regiones',
    shortName: 'Por Ch. y sus Reg.',
    color: '#ba0286',
    darkColor: '#83015E',
    order: 2,
    partidos: [PARTIDOS.PS, PARTIDOS.PPD, PARTIDOS.FA, PARTIDOS.PC, PARTIDOS.AH, PARTIDOS.IND],
  },
  TRR: {
    initials: 'TRR',
    name: 'Tu Región Radical',
    shortName: 'Tu Reg. Radical',
    color: '#09d1e0',
    darkColor: '#079BA6',
    order: 3,
    partidos: [PARTIDOS.PR, PARTIDOS.IND],
  },
  PCM: {
    initials: 'PCM',
    name: 'Por un Chile Mejor',
    shortName: 'Por un Ch. Mejor',
    color: '#ff9a02',
    darkColor: '#C17400',
    order: 4,
    partidos: [PARTIDOS.DC, PARTIDOS.IND],
  },
  CHV: {
    initials: 'CHV',
    name: 'Chile Vamos',
    shortName: 'Chile Vamos',
    color: '#e81d0e',
    darkColor: '#B71509',
    order: 5,
    partidos: [PARTIDOS.EVO, PARTIDOS.RN, PARTIDOS.UDI, PARTIDOS.IND],
  },
  REP: {
    initials: 'REP',
    name: 'Republicanos',
    shortName: 'Rep.',
    color: '#0191fb',
    darkColor: '#006DBD',
    order: 6,
    partidos: [PARTIDOS.REP, PARTIDOS.IND],
  },
  IND: {
    initials: 'IND',
    name: 'Independientes',
    shortName: 'Ind.',
    color: '#A37C5E',
    darkColor: '#6E543F',
    order: 7,
    partidos: [PARTIDOS.IND],
  },
}

export const PACTOS: PactosType = {
  [ElectionType.GORE]: PACTOS_GORE,
  [ElectionType.ALCALDES]: PACTOS_ALCALDES,
  [ElectionType.CORE]: PACTOS_CORE,
  [ElectionType.CONCEJALES]: PACTOS_CONCEJALES,
  [ElectionType.GORE_2V]: PACTOS_2V_GORE,
}

export const LIST_PACTOS: PactosListType = {
  [ElectionType.GORE]: Object.keys(PACTOS_GORE),
  [ElectionType.ALCALDES]: Object.keys(PACTOS_ALCALDES),
  [ElectionType.CORE]: Object.keys(PACTOS_CORE),
  [ElectionType.CONCEJALES]: Object.keys(PACTOS_CONCEJALES),
  [ElectionType.GORE_2V]: Object.keys(PACTOS_2V_GORE),
}
