import styled from 'styled-components'
import { ReactNode } from 'react'
import StatusTag from '@/components/MagicWall/StatusTag'
import { colors } from '@/constants'
import { Separator } from '@/components/ui/separator'
import useRouting from '@/stores/useRouting'

type ICard = {
  children: ReactNode
  participation?: {
    percentage: string
    votes: string
    blankVotes: string
    nullVotes: string
    totalVoters: string
  }
  statusLabel?: string
  separatorColor?: string
  title: string
  toggle?: ReactNode
}

export default function Card({
  children,
  participation,
  statusLabel,
  separatorColor = colors.gray3Dch,
  title,
  toggle,
}: ICard) {
  const { currentElection } = useRouting()

  return (
    <Wrapper>
      <div>
        <Top>
          <div className="flex items-center h-full gap-7">
            <Title>{title}</Title>
          </div>
          {toggle}
        </Top>
        {participation && (
          <Participation>
            <div className="text-[20px] font-bold uppercase tracking-[0.5px]">
              {participation.percentage} participación
            </div>
            <div className="uppercase text-[18px]">
              <span>{participation.votes} votos</span>
              <span> / {participation.totalVoters} inscritos</span>
              <span> · {participation.blankVotes} blancos</span>
              <span> / {participation.nullVotes} nulos</span>
            </div>
          </Participation>
        )}
      </div>
      <CustomSeparator color={separatorColor} />
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid: min-content min-content 1fr / 1fr;
  color: ${colors.blackDch};
  background-color: ${colors.whiteDch2};
  box-shadow: 4px 4px 0px 4px ${colors.frameBoxShadowCard};
  border: 1px solid #b7b7b7;
  border-radius: 8px;
  width: 100%;
  height: 100%;
`

const Top = styled.div.attrs({
  className: 'mgw:py-9 mgw:px-10 py-8 px-10',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Participation = styled.div.attrs({
  className: 'mgw:px-10 px-10 mt-[-32px] pb-[20px]',
})`
  line-height: 28px;
`

const Title = styled.div.attrs({
  className: 'mgw:text-4xl text-3xl',
})`
  color: ${colors.blackDch};
  font-weight: 600;
`

const CustomSeparator = styled(Separator).attrs<{ color: string }>({
  className: 'mgw:h-[2px] h-[1px]',
})`
  color: ${colors.blackDch};
  background-color: ${({ color }) => color};
`
