import styled from 'styled-components'
import { colors } from '@/constants'
import { ReactNode } from 'react'
import InfoToolTip from '../InfoToolTip'
import { cn } from '@/lib/utils'

type PactoInformation = {
  color: string
  electos: number
  abreviatura?: string
  name: string
  children?: ReactNode
  showTooltip?: boolean
  isLong?: boolean
}

export default function PactoInformation({
  color,
  electos,
  name,
  children,
  abreviatura,
  showTooltip = false,
  isLong = false,
}: PactoInformation) {
  return (
    <WrapperPacto className="relative">
      <ElectosPacto color={color}>{electos}</ElectosPacto>
      <PactoName
        className={cn('hidden xl:flex gap-1', {
          'min-h-[26px]': !isLong,
        })}
      >
        {name}{' '}
        {showTooltip && (
          <div className="mt-0.5">
            <InfoToolTip content="Conjunto de candidatos que no pertenecen a las listas anteriores." />
          </div>
        )}
      </PactoName>
      <PactoName className="block xl:hidden">{abreviatura}</PactoName>

      <HSeparatorPactos className="-mt-1" />
      {children}
    </WrapperPacto>
  )
}

const WrapperPacto = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const ElectosPacto = styled.div.attrs<{ color: string }>({
  className: 'text-[20px] xl:text-[27px]',
})`
  font-weight: 700;
  color: ${({ color }) => color};
`

const PactoName = styled.div.attrs({
  className: 'text-[12px]',
})`
  line-height: 14px;
  margin-bottom: 12px;
  font-weight: 500;
  color: ${colors.blackDch};
`

const HSeparatorPactos = styled.div`
  height: 4px;
  border-bottom: 0.6px solid ${colors.gray1Dch};
`
