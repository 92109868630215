import HorizontalMap from '@/components/HorizontalMap'
import { colors } from '@/constants'
import PaginatedCard from '../PaginatedCard'
import React, { useState } from 'react'
import styled from 'styled-components'
import { NameCandidateBadge } from '../CandidateBadget'

type Electo = {
  nombre: string
  imagen: string
  color: string
  partido: string
}

export type Data = {
  region_code: number
  region: string
  region_pacto?: string
  electo_2024: Electo
  porcentaje_2024: string
  porcentaje_2021: string
  primera_vuelta?: boolean
}

type ReelectosProps = {
  data: Data[]
  pageSize: number
  segundaVuelta?: boolean
  regiones: {
    name: string
    selectedColor: string
    code: number
  }[]
}

export default function Reelectos({
  data,
  pageSize,
  regiones,
  segundaVuelta = false,
}: ReelectosProps) {
  const [currentPage, setCurrentPage] = useState(0)

  const totalPages = Math.ceil(data.length / pageSize)
  const paginatedData = data.slice(currentPage * pageSize, (currentPage + 1) * pageSize)

  return (
    <PaginatedCard
      title="Reelectos"
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      totalPages={totalPages}
      separatorColor="#CCCCCC"
    >
      <Wrapper>
        <MapWrapper>
          <HorizontalMap
            selectionProperty="cod_comuna"
            position="center"
            regionResults={regiones}
          />
          {segundaVuelta && (
            <div className="flex w-full h-full justify-center gap-2 items-center">
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="10.5"
                  cy="10.5"
                  r="9.18979"
                  fill="#FAFAFA"
                  stroke="#4F4F4F"
                  stroke-width="2.62042"
                />
              </svg>
              <TextMap>Electos en 1ra v.</TextMap>
            </div>
          )}
        </MapWrapper>
        <Table>
          <HeaderCell>REGIÓN</HeaderCell>
          <HeaderCell>
            <YearLabel year="2024" label="OBLIGATORIO" />
          </HeaderCell>
          <HeaderCell>
            <YearLabel year="2021" label="VOLUNTARIO" />
          </HeaderCell>
          <HeaderCell>REGIÓN</HeaderCell>
          <HeaderCell>
            <YearLabel year="2024" label="OBLIGATORIO" />
          </HeaderCell>
          <HeaderCell>
            <YearLabel year="2021" label="VOLUNTARIO" />
          </HeaderCell>
          {paginatedData.map((item, index) => (
            <React.Fragment key={`gore-reelecto-${index}`}>
              <BodyCellRegion>
                <div className="mgw:leading-[30px] leading-[22px]">
                  {item.region}
                  <div className="font-normal">{item.region_pacto}</div>
                </div>
                <NameCandidateBadge
                  electo={item.electo_2024}
                  firstRound={item.primera_vuelta || false}
                />
              </BodyCellRegion>
              <BodyCell className="mgw:text-[32px] text-[26px] font-semibold">
                {item.porcentaje_2024}
              </BodyCell>
              <BodyCell className="mgw:text-[24px] text-[20px] font-medium">
                {item.porcentaje_2021}
              </BodyCell>
            </React.Fragment>
          ))}
        </Table>
      </Wrapper>
    </PaginatedCard>
  )
}

function YearLabel({ year, label }: { year: string; label: string }) {
  return (
    <div className="flex flex-col items-center justify-center gap-0 h-full w-full mgw:leading-[25px] leading-[20px]">
      <div>{year}</div>
      <div className="mgw:text-[18px] text-[14px] font-medium">{label}</div>
    </div>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid: 1fr min-content / 1fr;
  height: 100%;
  width: 100%;
`

const MapWrapper = styled.div.attrs({
  className: 'mgw:py-[20px] mgw:px-[100px] px-[60px] py-[10px]',
})`
  height: 100%;
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
`

const Table = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(300px, 1fr) minmax(150px, 0.3fr) minmax(150px, 0.3fr));
  grid-template-rows: minmax(0, min-content) repeat(2, minmax(110px, min-content));

  & > :nth-of-type(3n) {
    background-color: ${colors.gray1Dch};
  }

  & > :nth-of-type(3n + 1) {
    padding-left: 20px;
  }

  & > :nth-of-type(3n):not(:nth-of-type(6n)) {
    border-right: 2px solid ${colors.gray3Dch};
  }

  & > :not(:nth-child(n + 13)) {
    border-bottom: 2px solid ${colors.gray3Dch};
  }

  & > :nth-of-type(3n + 1) {
    padding-left: 20px;
  }
`

const HeaderCell = styled.div.attrs({
  className: 'mgw:text-[24px] text-[18px] mgw:py-[12px] py-[8px]',
})`
  width: 100%;
  display: flex;
  align-items: center;
  font-family: 'JetBrains Mono';
  font-weight: 600;
  line-height: 32px;
  color: ${colors.blackDch};
  min-height: 76px;
`

const BodyCellRegion = styled.div.attrs({
  className: 'mgw:text-[24px] text-[18px] mgw:py-[20px] py-[15px]',
})`
  display: grid;
  grid-template-columns: 0.45fr 0.55fr;
  gap: 20px;
  align-items: center;
  font-weight: 700;
  line-height: 32px;
  color: ${colors.blackDch};
  min-height: 112px;
`

const BodyCell = styled.div.attrs({
  className: 'mgw:py-[20px] py-[15px]',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.blackDch};
  min-height: 112px;
`

const TextMap = styled.div`
  font-size: 18px;
  color: ${colors.blackDch};
  font-weight: 500;
  text-transform: uppercase;
`
