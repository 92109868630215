import {
  Accordion as AccordionBase,
  AccordionContent as AccordionContentBase,
  AccordionItem as AccordionItemBase,
  AccordionTrigger as AccordionTriggerBase,
} from '@/components/ui/accordion'
import { colors, electionParams, TEMAS, Territorios } from '@/constants'
import useRouting from '@/stores/useRouting'
import { regiones } from '@/stores/useTerritorios'
import { ElectionType } from '@/types/Elections'
import { matchPath, NavigateFunction, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

type AccordionOption = {
  label: string
  value: string
  toNavigate?: string
  selected?: boolean
  onClick?: () => void
  territorio?: Territorios | 'region'
  hideIn?: ElectionType[]
}

export type AccordionMenuButton = {
  label: string
  tema: string
  options: AccordionOption[]
  expand?: boolean
}

const RESULTS_OPTIONS: AccordionMenuButton[] = [
  {
    label: 'RESUMEN',
    tema: 'resumen',
    options: [
      {
        label: 'Electos',
        value: 'electos',
        toNavigate: '/magic/:eleccion/',
        hideIn: [
          ElectionType.CORE,
          ElectionType.CONCEJALES,
          ElectionType.ALCALDES,
          ElectionType.GORE,
        ],
      },
      {
        label: 'Ranking',
        value: 'ranking',
        toNavigate: '/magic/:eleccion/',
        hideIn: [ElectionType.GORE_2V],
      },
      { label: 'Pactos', value: 'pactos', toNavigate: '/magic/:eleccion/pactos' },
      {
        label: 'Comparación',
        value: 'comparacion',
        toNavigate: '/magic/:eleccion/comparacion',
      },
      {
        label: 'Ganadores',
        value: 'ganadores',
        toNavigate: '/magic/:eleccion/ganadores',
        hideIn: [
          ElectionType.CORE,
          ElectionType.CONCEJALES,
          ElectionType.ALCALDES,
          ElectionType.GORE_2V,
        ],
      },
    ],
  },
  {
    label: 'TERRITORIO',
    tema: 'territorio',
    options: [
      {
        label: 'General',
        value: 'general',
        toNavigate: '/magic/:eleccion/territorio/:territorio',
        hideIn: [ElectionType.GORE_2V],
      },
      {
        label: 'Electos',
        value: 'electos',
        toNavigate: '/magic/:eleccion/territorio/:territorio/:comuna',
        hideIn: [ElectionType.CORE, ElectionType.GORE_2V],
      },
      {
        label: 'Electos',
        value: 'electos-core',
        toNavigate: '/magic/:eleccion/territorio/:territorio/electos',
        hideIn: [
          ElectionType.CONCEJALES,
          ElectionType.ALCALDES,
          ElectionType.GORE,
          ElectionType.GORE_2V,
        ],
      },
      {
        label: 'Región',
        value: 'region-gore-2v',
        toNavigate: '/magic/:eleccion/territorio/:territorio',
        hideIn: [
          ElectionType.CORE,
          ElectionType.CONCEJALES,
          ElectionType.ALCALDES,
          ElectionType.GORE,
        ],
      },
      {
        label: 'Comuna',
        value: 'comuna-gore-2v',
        toNavigate: '/magic/:eleccion/territorio/:territorio/:comuna',
        hideIn: [
          ElectionType.CORE,
          ElectionType.CONCEJALES,
          ElectionType.ALCALDES,
          ElectionType.GORE,
        ],
      },
      {
        label: 'Cambia Sector',
        value: 'cambio-pacto',
        toNavigate: '/magic/:eleccion/territorio/:territorio/cambio-pacto',
        territorio: 'region',
        hideIn: [
          ElectionType.CONCEJALES,
          ElectionType.CORE,
          ElectionType.GORE,
          ElectionType.GORE_2V,
        ],
      },
      {
        label: 'Mantiene Sector',
        value: 'mantiene-pacto',
        toNavigate: '/magic/:eleccion/territorio/:territorio/mantiene-pacto',
        territorio: 'region',
        hideIn: [
          ElectionType.CONCEJALES,
          ElectionType.CORE,
          ElectionType.GORE,
          ElectionType.GORE_2V,
        ],
      },
      {
        label: 'Reelectos',
        value: 'reelectos',
        toNavigate: '/magic/:eleccion/territorio/:territorio/reelectos',
        territorio: 'region',
        hideIn: [
          ElectionType.CONCEJALES,
          ElectionType.CORE,
          ElectionType.GORE,
          ElectionType.GORE_2V,
        ],
      },
      {
        label: 'Cambia Sector',
        value: 'cambio-pacto-gore',
        toNavigate: '/magic/:eleccion/cambio-pacto',
        territorio: 'region',
        hideIn: [ElectionType.CONCEJALES, ElectionType.CORE, ElectionType.ALCALDES],
      },
      {
        label: 'Mantiene Sector',
        value: 'mantiene-pacto-gore',
        toNavigate: '/magic/:eleccion/mantiene-pacto',
        territorio: 'region',
        hideIn: [
          ElectionType.CONCEJALES,
          ElectionType.CORE,
          ElectionType.ALCALDES,
          ElectionType.GORE_2V,
        ],
      },
      {
        label: 'Reelectos',
        value: 'reelectos-gore',
        toNavigate: '/magic/:eleccion/reelectos',
        territorio: 'region',
        hideIn: [ElectionType.CONCEJALES, ElectionType.CORE, ElectionType.ALCALDES],
      },
    ],
  },
]

export const CHILE_EN_DATOS_OPTIONS: AccordionMenuButton[] = Object.entries(TEMAS).map(
  ([tema, { subtemas, name }]) => ({
    tema: tema,
    label: name,
    expand: false,
    options: Object.entries(subtemas).map(([subtema, { name }]) => ({
      label: name,
      toNavigate: `/magic/chile-en-datos/${tema}/${subtema}/:comuna/`,
      value: subtema,
    })),
  })
)

export const ONLY_REGION_OPTIONS = Object.values(RESULTS_OPTIONS)
  .flatMap(({ options }) => options)
  .filter(({ territorio }) => territorio === 'region')

function adaptOptions({ path }: { path: string }) {
  const {
    currentRegion,
    currentElection,
    currentComuna,
    currentCircunscripcion,
    isMagicChileEnDatos,
    currentTema,
    currentSubtema,
  } = useRouting.getState()
  const options = isMagicChileEnDatos ? CHILE_EN_DATOS_OPTIONS : RESULTS_OPTIONS
  if (currentElection && !isMagicChileEnDatos)
    return options.map(({ label, options, expand }) => ({
      label,
      expand,
      options: options
        .filter(({ hideIn }) => {
          return !hideIn?.includes(currentElection)
        })
        .map(({ label, toNavigate }) => {
          const comunaRegion = regiones.find(
            ({ codigo }) => codigo === currentRegion
          )?.comunaDefecto
          const adaptedToNavigate = toNavigate
            ?.replace(
              ':territorio',
              String(
                currentRegion ||
                  currentCircunscripcion ||
                  electionParams[currentElection].defaultTerritorio
              )
            )
            .replace(
              ':comuna',
              String(currentComuna || comunaRegion || electionParams[currentElection].defaultComuna)
            )
            .replace(':eleccion', currentElection)
          return {
            label,
            toNavigate: adaptedToNavigate,
            selected: adaptedToNavigate ? !!matchPath(adaptedToNavigate, decodeURI(path)) : false,
          }
        }),
    })) as AccordionMenuButton[]

  if (isMagicChileEnDatos) {
    return options
      .filter((option) => option.tema === currentTema)
      .map(({ label, options, expand }) => ({
        label,
        expand,
        options: options
          .filter(({ toNavigate }) => currentTema && toNavigate?.includes(currentTema))
          .map(({ label, toNavigate }) => {
            const comuna = currentComuna || electionParams[ElectionType.ALCALDES].defaultComuna
            const adaptedToNavigate = toNavigate?.replace(':comuna', String(comuna))
            return {
              label,
              toNavigate: adaptedToNavigate,
              selected: currentSubtema && adaptedToNavigate?.includes(currentSubtema),
            }
          }),
      })) as AccordionMenuButton[]
  }
  return []
}

export default function Accordion() {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { currentElection } = useRouting.getState()

  const finalOptions = adaptOptions({
    path: pathname,
  })

  const selectedButton = finalOptions.find(({ options }) =>
    options.some(({ selected }) => selected)
  )

  if (!selectedButton) return

  return (
    <AccordionWrapper
      type="single"
      collapsible
      className="w-full"
      defaultValue={selectedButton?.label}
    >
      {finalOptions.map(({ label: accordionLabel, options, expand = true }) =>
        expand ? (
          <AccordionItem value={accordionLabel} key={accordionLabel}>
            <AccordionTrigger selected={selectedButton.label === accordionLabel}>
              {accordionLabel}
            </AccordionTrigger>
            <AccordionContent>
              <Options options={options} currentElection={currentElection} navigate={navigate} />
            </AccordionContent>
          </AccordionItem>
        ) : (
          <NoExpand key={accordionLabel}>
            <Options options={options} currentElection={currentElection} navigate={navigate} />
          </NoExpand>
        )
      )}
    </AccordionWrapper>
  )
}

function Options({
  options,
  currentElection,
  navigate,
}: {
  options: AccordionOption[]
  currentElection?: ElectionType
  navigate: NavigateFunction
}) {
  return options.map(({ label, selected, toNavigate, onClick, hideIn }) => {
    const isHidden = currentElection && hideIn?.includes(currentElection)
    if (isHidden) return null
    return (
      <AccordionButton
        key={label}
        selected={selected}
        onClick={() => {
          if (onClick) onClick()
          if (toNavigate) navigate(toNavigate)
        }}
      >
        {label}
      </AccordionButton>
    )
  })
}

const AccordionButton = styled.button<{ selected?: boolean }>`
  color: ${colors.frameFontLight};
  width: 100%;
  text-align: left;
  font-weight: 500;
  font-size: 20px;
  color: ${({ selected }) => (selected ? colors.menuSelected : colors.frameFontLight)};
  text-decoration: ${({ selected }) => (selected ? 'underline' : 'none')};
  text-underline-position: under;
`

const AccordionWrapper = styled(AccordionBase)`
  padding: 6px 18px;
`
const AccordionItem = styled(AccordionItemBase)`
  border: none;
`

const AccordionTrigger = styled(AccordionTriggerBase)<{ selected?: boolean }>`
  font-weight: 700;
  font-size: 20px;
  color: ${({ selected }) => (selected ? colors.menuSelected : colors.frameFontLight)};
  text-decoration: ${({ selected }) => (selected ? 'underline' : 'none')};
  text-underline-position: under;
  justify-content: start;
  gap: 6px;
  svg {
    stroke: ${({ selected }) => (selected ? colors.menuSelected : colors.frameFontLight)};
  }
`

const AccordionContent = styled(AccordionContentBase)`
  display: flex;
  flex-direction: column;
  padding: 6px 12px;
  gap: 12px;
`

const NoExpand = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 6px 12px;
`
