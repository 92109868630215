import styled from 'styled-components'
import { colors } from '@/constants'
import { ReactNode } from 'react'
import { numberWithDots } from '@/helpers/formatters'
import { Tooltip } from 'react-tooltip'

type PactoInfoProps = {
  color: string
  electos: number
  shortName?: string
  longName?: string
  initials?: string
  children?: ReactNode
  votos: number
  isGobernadorView?: boolean
  setHighlightedColor: (color: string | null) => void
}

export default function PactoInfo({
  color,
  electos,
  shortName,
  longName,
  initials,
  votos,
  isGobernadorView = true,
  setHighlightedColor,
}: PactoInfoProps) {
  return (
    <WrapperGobernadorPacto>
      <ElectosPacto color={color}>{electos}</ElectosPacto>
      <PactoName className={`pacto-anchor-${initials}`}>{initials}</PactoName>
      <StyledTooltip
        anchorSelect={`.pacto-anchor-${initials}`}
        place="bottom-start"
        offset={0}
        afterShow={() => setTimeout(() => setHighlightedColor(color), 170)}
        afterHide={() => setHighlightedColor(null)}
      >
        {longName}
      </StyledTooltip>
    </WrapperGobernadorPacto>
  )
}

const WrapperGobernadorPacto = styled.div.attrs({
  className: 'w-[35px] sifr:w-[54px]',
})`
  display: flex;
  flex-direction: column;
`

const ElectosPacto = styled.div.attrs<{ color: string }>({
  className: 'text-[16px] sifr:text-[22px]',
})`
  font-weight: 700;
  color: ${({ color }) => color};
`

const PactoName = styled.div.attrs({
  className: `text-[10px] sifr:text-[12px] 
            leading-[1] w-fit
            tracking-[0.48px] 
            pb-[4px] sifr:pb-[6px]
  `,
})`
  height: 100%;
  font-weight: 500;
  color: ${colors.blackDch};
`

const StyledTooltip = styled(Tooltip).attrs({
  className: '!text-[8px] !px-[6px] !py-[4px] !z-[9999]',
})``
