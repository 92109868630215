import styled from 'styled-components'
import { numberToPercent } from '@/helpers/formatters'
import { colors } from '@/constants'
import { cn } from '@/lib/utils'

type IPercentageBar = {
  className?: string
  bordered?: boolean
  percentage: number
  innerContent?: React.ReactNode
  outerContent?: React.ReactNode
  color: string
  maxPercentage?: number
  default_?: React.ReactNode
  maxHeight?: number
  minHeight?: number
  votes?: string
  small?: boolean
}

type ISupPercentageLabel = {
  percentage: number
  fixed?: number
  includeSign?: boolean
  hideTrailingZero?: boolean
  especialSupForBar?: boolean
  isWeb?: boolean
  size?: string
}

export function DefaultBar({
  className,
  bordered = false,
}: {
  className?: string
  bordered?: boolean
}) {
  return (
    <>
      <ContentLabel className={className} color={colors.gray3Dch}>
        n/a
      </ContentLabel>
      <Bar $color={colors.gray2Dch} $percentage={1} $bordered={bordered} />
    </>
  )
}

export function SupPercentageLabel({
  percentage,
  fixed = 1,
  includeSign = false,
  hideTrailingZero = false,
  especialSupForBar = false, // For comparison bars
  size = 'normal',
  isWeb = false,
}: ISupPercentageLabel) {
  return (
    <div className="flex items-center justify-center">
      <div
        className={cn('flex justify-end items-center', {
          relative: especialSupForBar,
        })}
      >
        {(includeSign && percentage > 0 ? '+' : '') +
          numberToPercent(percentage, fixed, false, hideTrailingZero)}
        <sup
          className={cn('text-[0.6em]', {
            'absolute top-[8px] right-[-10px] text-[0.8em]': especialSupForBar === true,
            'mt-[5px]': !especialSupForBar && size === 'normal' && isWeb,
            'mt-[8px]': !especialSupForBar && size === 'normal' && !isWeb, // Comparacion tag
            'absolute top-[14px] right-[-15px]': especialSupForBar && !isWeb, // comparacion
            'absolute top-[32px] right-[-29px]': especialSupForBar && size === 'big' && !isWeb, // Versus in MGW
            'mt-3': !especialSupForBar && size === 'big' && !isWeb,
            'mt-2': !especialSupForBar && size === 'big' && isWeb,
            'absolute top-[8px] right-[-7px]': isWeb && especialSupForBar,
          })}
        >
          %
        </sup>
      </div>
    </div>
  )
}

export default function ContentPercentageBar({
  className,
  percentage,
  color,
  innerContent,
  outerContent,
  default_,
  maxPercentage = 100,
  maxHeight = 100,
  minHeight = 0,
  bordered = false,
  votes,
  small = false,
}: IPercentageBar) {
  const topPercentage = minHeight + (percentage / maxPercentage) * maxHeight
  return (
    <BarContainer className={className}>
      {default_ && percentage === 0 ? (
        default_
      ) : (
        <>
          <OuterContentContainer percentage={topPercentage}>{outerContent}</OuterContentContainer>
          <Bar $percentage={topPercentage} $color={color} $bordered={bordered}>
            {innerContent}
          </Bar>
          {votes && (
            <VotesContainer
              className={cn({
                'text-[20px] bottom-[-32px]': !small,
                'text-[10px]': small,
              })}
            >
              {votes}
            </VotesContainer>
          )}
        </>
      )}
    </BarContainer>
  )
}

const VotesContainer = styled.div`
  position: absolute;
  color: ${colors.blackDch};
`

const BarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
`

const OuterContentContainer = styled.div<{ percentage: number }>`
  position: absolute;
  bottom: ${({ percentage }) => `${percentage}%`};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
`

const Bar = styled.div<{ $color: string; $percentage: number; $bordered?: boolean }>`
  width: 100%;
  background-color: ${({ $color }) => $color};
  height: ${({ $percentage }) => `${$percentage}%`};
  border-radius: ${({ $bordered }) => ($bordered ? '6px 6px 0 0' : '5px 5px 0 0')};
  transition: height 0.3s ease;
`

export const ContentLabel = styled.div<{ color?: string }>`
  text-align: center;
  color: ${({ color }) => color || colors.blackDch};
  width: 100%;
`

export const BetweenContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`
