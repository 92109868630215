import HorizontalMapSvg from '@/assets/horizontalMap.svg'
import { colors } from '@/constants'
import styled from 'styled-components'
import { MapProps } from '@/components/Map/ComunasChileMap'
import { Tooltip } from 'react-tooltip'
import { Fragment } from 'react/jsx-runtime'

type HorizontalMapProps = {
  regionResults: {
    name: string
    longName: string
    selectedColor: string
    code: number
    vuelta: number
  }[]
  children?: React.ReactNode
  highlightedColor?: string | null
} & MapProps

export default function IfrHorizontalMap({ regionResults, highlightedColor }: HorizontalMapProps) {
  return (
    <Wrapper>
      <Circles>
        {Object.entries(regionResults).map(([key, region]) => (
          <Fragment key={`${key}-${region.name}`}>
            <Circle
              className={`region-anchor-${key}-${region.name}`}
              key={`${key}-${region.name}`}
              $selectedcolor={region.selectedColor}
              $vuelta={region.vuelta}
              $isHighlighted={
                highlightedColor === null || highlightedColor === region.selectedColor
              }
            >
              <span className="leading-none">{region.name}</span>
            </Circle>
            <StyledTooltip
              key={`${key}-tooltip-${region.name}`}
              anchorSelect={`.region-anchor-${key}-${region.name}`}
              place="top-start"
              offset={6}
            >
              {region.longName}
            </StyledTooltip>
          </Fragment>
        ))}
      </Circles>
      <Map />
      <div className="flex text-[8px] sifr:text-[10px] items-center gap-1">
        <Circle $selectedcolor={null} className="!h-[8px] ifr:!h-[10px] !w-[8px] ifr:!w-[10px]" />
        Electos en 1era V.
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  width: 100%;
  padding-top: 6px;
`

const Map = styled(HorizontalMapSvg).attrs({
  className: 'px-[6px] sifr:px-[10px] ifr:px-[12px] -mt-[2px] ifr:-mt-[4px]',
})`
  height: 100%;
  width: 100%;
`

const Circle = styled.div.attrs<{
  $selectedcolor?: string | null | undefined
  $vuelta?: number
  $isHighlighted?: boolean
}>({
  className: `
    w-[14px] sifr:w-[20px] 
    h-[14px] sifr:h-[20px] 
    text-[6px] sifr:text-[8px]
    aspect-square
`,
})`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ $selectedcolor: selectedcolor, $vuelta: vuelta }) =>
    selectedcolor === ''
      ? colors.gray2Dch
      : selectedcolor && vuelta === 1
      ? colors.whiteDch
      : selectedcolor && vuelta === 2
      ? selectedcolor
      : colors.whiteDch};
  border: ${({ $selectedcolor: selectedcolor, $vuelta: vuelta }) =>
    selectedcolor === null
      ? `1px solid ${colors.gray3Dch}`
      : selectedcolor && vuelta === 1
      ? `1px solid ${selectedcolor}`
      : 'none'};
  color: ${({ $selectedcolor, $vuelta }) =>
    $selectedcolor && $vuelta === 1 ? $selectedcolor : colors.whiteDch};
  font-weight: 600;
  opacity: ${({ $isHighlighted }) => ($isHighlighted ? 1 : 0.5)};
  transition: opacity 0.2s ease-in-out;
`

const Circles = styled.div.attrs({
  className: 'gap-[2px] sifr:gap-1 justify-between',
})`
  font-family: 'JetBrains Mono', monospace;
  display: flex;
  width: 100%;
  z-index: 0;
`
const StyledTooltip = styled(Tooltip).attrs({
  className: '!text-[8px] !px-[5px] !py-[3px] !z-[9999]',
})``
