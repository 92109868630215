import { LIST_PACTOS, PACTOS, PACTOS_ALCALDES, TOTAL_ELECTOS } from '@/constants'
import { PACTOS_GORE, PARTIDOS } from '@/constants/pactos'
import { formatFullName, formatNameLastName, numberToPercent } from '@/helpers/formatters'
import { comunas, RegionData, regiones } from '@/stores/useTerritorios'
import { ElectionType } from '@/types/Elections'
import { Alcaldes2021, Candidato, CandidatoGore, LocalGore2021 } from '@/types/Files'
import { type Data as DataCambioMantieneGORE } from '@/components/MagicWall/GORE/CambioMantienePacto'
import { type Data as DataCambioMantieneAlcalde } from '@/components/MagicWall/MantienePacto'
import { type Data as DataElectosPactoRegion } from '@/components/MagicWall/ElectosPacto'
import { type Data as DataReelectoAlcalde } from '@/components/MagicWall/Reelecto'

export function buildPactosViewData(
  resultsByPactos: Record<string, { [key: string]: number }>,
  eleccion: ElectionType,
  shortName = true
) {
  return LIST_PACTOS[eleccion].map((sigla) => {
    const pacto = PACTOS[eleccion][sigla] || null
    if (!pacto) {
      return {
        nombre: '',
        abreviatura: '',
        color: '',
        electos: 0,
        porcentaje: 0,
        votos: 0,
        orden: 0,
        electos_partido: [],
        cod_pacto: '',
      }
    }

    return {
      nombre: shortName ? pacto.shortName || pacto.name : pacto.name || '',
      abreviatura: pacto.initials,
      color: pacto.color,
      electos: resultsByPactos[sigla]?.electos || 0,
      porcentaje: 0,
      votos: resultsByPactos[sigla]?.votos || 0,
      orden: pacto.order,
      cod_pacto: sigla,
      electos_partido: pacto.partidos
        .map((partido) => ({
          nombre_partido: partido.name,
          cod_partido: partido.initials,
          electos: resultsByPactos[pacto.initials]?.[partido.initials] || 0,
        }))
        .sort((a, b) => b.electos - a.electos),
    }
  })
}

export function buildComparacionData(
  resultsByPactos2024: Record<string, { [key: string]: number }>,
  resultsByPactos2021: Record<string, number>,
  eleccion: ElectionType,
  shortName = true
) {
  return LIST_PACTOS[eleccion].map((initials) => {
    const pacto = PACTOS[eleccion][initials]

    if (!pacto)
      return {
        pacto: '',
        color: '',
        electos_2021: 0,
        electos_2024: 0,
        porcentaje_2021: 0,
        porcentaje_2024: 0,
        orden: 0,
        comparacion: 'equal',
      }

    const electos_2021 = resultsByPactos2021[initials] || 0
    const porcentaje_2021 = (electos_2021 / TOTAL_ELECTOS[eleccion]) * 100

    const electos_2024 = resultsByPactos2024[initials]?.electos || 0
    const porcentaje_2024 = (electos_2024 / TOTAL_ELECTOS[eleccion]) * 100

    const pactoName = shortName ? pacto.shortName : pacto.name

    return {
      pacto: pactoName || initials,
      color: pacto.color,
      electos_2021,
      electos_2024,
      orden: pacto.order,
      porcentaje_2021,
      porcentaje_2024,
      comparacion:
        electos_2021 > electos_2024 ? 'down' : electos_2021 < electos_2024 ? 'up' : 'equal',
    }
  })
}

// Only for Alcaldes
export function buildCambioMantienePactoAlcalde(
  region: RegionData,
  alcaldes: Candidato[],
  alcaldes2021: Alcaldes2021,
  option: 'mantiene' | 'cambia',
  maxLength = 17
) {
  const comunasColor: { [key: number]: string } = {}
  const actualElectos = alcaldes.filter((c) => c.electo && !c.reelecto)

  const data =
    region?.comunas.reduce((acc: DataCambioMantieneAlcalde[], comuna) => {
      const electo = actualElectos.find((c) => c.cod_comuna.toString() === comuna.toString())
      const electo2021 = alcaldes2021.electos_persona[comuna]

      const optionPacto =
        option === 'mantiene'
          ? electo2021?.sigla_pacto === electo?.cod_pacto
          : electo2021?.sigla_pacto !== electo?.cod_pacto

      if (electo2021 && electo && optionPacto) {
        const pacto = PACTOS_ALCALDES[electo.cod_pacto]
        const pacto2021 = PACTOS_ALCALDES[electo2021.sigla_pacto]

        comunasColor[comuna] = pacto?.color || ''

        const partido = PARTIDOS[electo.cod_partido] || {}
        const partido2021 = PARTIDOS[electo2021.sigla_partido] || {}

        const partidoName =
          partido.name?.length > maxLength ? partido?.shortName || '' : partido?.name || ''
        const partidoName2021 =
          (partido2021.name?.length > maxLength
            ? partido2021?.shortName || electo2021.sigla_partido
            : partido2021?.name) || electo2021.sigla_partido

        const pactoName =
          (pacto?.name || '').length > maxLength ? pacto?.shortName || '' : pacto?.name || ''
        const pactoName2021 =
          (pacto2021?.name || '').length > maxLength
            ? pacto2021?.shortName || ''
            : pacto2021?.name || ''
        const comunaName = comunas.find((c) => c.codigo === comuna)?.nombre
        acc.push({
          comuna: comunaName || '',
          electo_2024: {
            nombre: formatNameLastName(electo.nombre),
            partido: option === 'mantiene' ? partidoName : pactoName,
            imagen: electo?.imagen || '/userProfile.png',
            color: pacto?.color || '',
          },
          electo_2021: {
            nombre: formatNameLastName(electo2021.nombre),
            partido: option === 'mantiene' ? partidoName2021 : pactoName2021,
            imagen: '/userProfile.png',
            color: pacto2021?.color || '',
          },
          pacto: pacto?.shortName || '',
        })
      }

      return acc
    }, []) || []

  return { data, comunasColor }
}

// Only for Alcaldes
export function buildReelectosAlcalde(
  region: number,
  alcaldes: Candidato[],
  alcaldes2021: Alcaldes2021,
  maxLength = 17
) {
  const comunasColor: { [key: number]: string } = {}
  const actualElectos = alcaldes.filter(
    (c) => c.electo && c.reelecto && Number(c.cod_region) === Number(region)
  )

  const data: DataReelectoAlcalde[] = actualElectos.map((reelecto) => {
    const pacto = PACTOS_ALCALDES[reelecto.cod_pacto] || {}
    const pactoName =
      (pacto?.name || '').length > maxLength ? pacto?.shortName || '' : pacto?.name || ''

    comunasColor[Number(reelecto.cod_comuna)] = pacto?.color || ''
    return {
      comuna: comunas.find((c) => Number(c.codigo) === Number(reelecto.cod_comuna))?.nombre || '',
      nombre: formatFullName(reelecto.nombre) || '',
      partido: PARTIDOS[reelecto.cod_partido]?.name || '',
      imagen: reelecto.imagen || '/userProfile.png',
      cod_partido: reelecto.cod_partido || '',
      color: pacto?.color || '',
      votos: reelecto.votos || 0,
      porcentaje_2021: numberToPercent(
        alcaldes2021.electos_persona[reelecto.cod_comuna]?.porcentaje || 0,
        1
      ),
      porcentaje_2024: numberToPercent(reelecto.porcentaje || 0, 1),
      pacto: pactoName,
    }
  })

  return { data, comunasColor }
}

function labelMaxLength(
  longLabel: string,
  shortLabel: string,
  initialsLabel: string,
  maxLength: number
) {
  if (longLabel === '' || shortLabel === '') return initialsLabel
  if (longLabel.length <= maxLength) return longLabel
  if (longLabel.length > maxLength && shortLabel.length <= maxLength) return shortLabel
  return initialsLabel
}

// Only for GORE
export function buildCambioMantienePactoGORE(
  gore: CandidatoGore[],
  gore2021: LocalGore2021,
  option: 'mantiene' | 'cambia',
  maxLength = 17,
  electionType: ElectionType,
  firstRound?: boolean
) {
  const electos = gore?.filter((candidato) => candidato.electo && !candidato.reelecto)

  const data = electos.reduce((acc: DataCambioMantieneGORE[], electo) => {
    const pacto = PACTOS[electionType][electo.cod_pacto] || {}
    const electo2021 = gore2021[electo.cod_region.toString()] || {}

    const optionIsTrue = electo2021
      ? option === 'mantiene'
        ? electo.cod_pacto === electo2021?.sigla_pacto
        : electo.cod_pacto !== electo2021?.sigla_pacto
      : false

    if (optionIsTrue) {
      const pacto2021 = PACTOS_GORE[electo2021.sigla_pacto] || {}

      const partido = PARTIDOS[electo.cod_partido] || {
        initials: '',
        name: '',
        shortName: '',
      }
      const partido2021 = PARTIDOS[electo2021.sigla_partido] || {}

      const partidoName = labelMaxLength(
        partido.name || '',
        partido.shortName || '',
        electo.cod_partido || '',
        maxLength
      )
      const partidoName2021 = labelMaxLength(
        partido2021.name || '',
        partido2021.shortName || '',
        electo2021.sigla_partido || '',
        maxLength
      )

      const pactoName = labelMaxLength(
        pacto?.name || '',
        pacto?.shortName || '',
        electo?.cod_pacto || '',
        maxLength
      )
      const pactoName2021 = labelMaxLength(
        pacto2021?.name || '',
        pacto2021?.shortName || '',
        electo2021?.sigla_pacto?.toUpperCase() || '',
        maxLength
      )

      const region = regiones.find(
        (region) => region.codigo.toString() === electo.cod_region.toString()
      )
      acc.push({
        region_pacto: option === 'mantiene' ? pacto.shortName : '',
        region_codigo: Number(electo.cod_region),
        region: region?.nombreCorto || region?.nombre || '',
        electo_2024: {
          nombre: formatNameLastName(electo.nombre, false),
          imagen: electo.imagen || '/userProfile.png',
          color: pacto.color || '',
          partido: option === 'mantiene' ? partidoName || '' : pactoName,
        },
        electo_2021: {
          nombre: formatNameLastName(electo2021.nombre, false),
          imagen: '/userProfile.png',
          color: pacto2021.color || '',
          partido: option === 'mantiene' ? partidoName2021 || '' : pactoName2021,
        },
        primera_vuelta: firstRound || false,
      })
    }
    return acc
  }, [])

  return data
}

export function buildRegionResultsByPacto(
  resultsByPactos: Record<string, number>,
  resultsByPactos2021: Record<string, number>,
  totalElectos: number,
  eleccion: ElectionType,
  totalElectos2021 = totalElectos
): DataElectosPactoRegion[] {
  return LIST_PACTOS[eleccion].map((initials) => {
    const pacto = PACTOS[eleccion][initials]

    if (!pacto)
      return {
        pacto: '',
        color: '',
        electos_2021: 0,
        electos_2024: 0,
        porcentaje_2021: '',
        porcentaje_2024: '',
        comparacion: 'equal',
      }

    const electos2024Count = resultsByPactos[pacto.initials] || 0
    const electos2021Count = resultsByPactos2021[pacto.initials] || 0

    return {
      pacto: pacto.name,
      color: pacto.color,
      electos_2021: electos2021Count,
      electos_2024: electos2024Count,
      porcentaje_2021: `${((electos2021Count / totalElectos2021) * 100)
        .toFixed(1)
        .replace('.', ',')}%`,
      porcentaje_2024: `${((electos2024Count / totalElectos) * 100).toFixed(1).replace('.', ',')}%`,
      comparacion:
        electos2021Count > electos2024Count
          ? 'down'
          : electos2021Count < electos2024Count
          ? 'up'
          : 'equal',
    }
  })
}
