import useRouting from '@/stores/useRouting'
import { regiones } from '@/stores/useTerritorios'
import useElectionData from '@/stores/useElectionData'
import { useEffect, useMemo } from 'react'
import { jointCandidatosColor } from '@/lib/dataProcess'
import { getTerritorioColorDictByMajority } from '@/lib/dataProcess'
import useTerritorios from '@/stores/useTerritorios'
import { GSComunas } from '@/stores/useTerritorios'
import { CandidatoGore } from '@/types/Files'
import { ElectionType } from '@/types/Elections'
import { PACTOS } from '@/constants'
import Versus2V from '@/components/MagicWall/GORE/Versus2V'
import { PARTIDOS } from '@/constants/pactos'
import { numberWithDots } from '@/helpers/formatters'
import { ELECTOS_1V_REGIONES } from '@/constants'

export function getRealRegionCode(regionCode: number | undefined) {
  switch (regionCode) {
    case 17:
      return 13
    default:
      return regionCode as number
  }
}

export function calculateSubRegion(comunas: number[], originalCandidates: CandidatoGore[]) {
  const total_votos_GS = comunas.reduce((acc, comuna) => {
    const comuna_string = comuna.toString()
    return (
      acc +
      originalCandidates[0].resultados_comuna[comuna_string]?.votos /
        (originalCandidates[0].resultados_comuna[comuna_string]?.porcentaje / 100)
    )
  })
  const SubRegionGores = originalCandidates.map((gore) => {
    const voto_GS = GSComunas.reduce((acc, comuna) => {
      return acc + gore.resultados_comuna[comuna]?.votos
    })
    const porcentaje_GS = (voto_GS / total_votos_GS) * 100
    return {
      ...gore,
      porcentaje: porcentaje_GS || 0,
      votos: voto_GS || 0,
    }
  })

  return SubRegionGores.sort((a, b) => b.porcentaje - a.porcentaje)
}

export default function GElectosRegion() {
  const { currentRegion } = useRouting()
  const linkToFirstRound = `/magic/gore/territorio/${currentRegion}`

  const {
    segunda_vuelta: { resultados, participacion: participacion2V },
    files: { gore: gore1V },
    participation: { gore: participacion1V },
  } = useElectionData()
  const { setComunaColor } = useTerritorios()

  const region = regiones.find((region) => region.codigo === currentRegion)
  const orderedGores = useMemo(() => {
    if (!currentRegion || !resultados || !gore1V) return []
    const firstRound = ELECTOS_1V_REGIONES.includes(currentRegion.toString())
    return (firstRound ? gore1V : resultados)
      .filter(
        (c) => c.cod_region.toString() === (getRealRegionCode(currentRegion) ?? '').toString()
      )
      .sort((a, b) => b.porcentaje - a.porcentaje)
  }, [currentRegion, resultados, gore1V])

  const processedData = useMemo(() => {
    if (orderedGores.length === 0 || !currentRegion) return []
    if (currentRegion === 17) {
      const GSGores = calculateSubRegion(GSComunas, orderedGores)
      return jointCandidatosColor(GSGores, ElectionType.GORE_2V).map((electo, i) => ({
        ...electo,
        pacto: PACTOS[ElectionType.GORE_2V][electo.cod_pacto]?.shortName || electo.cod_pacto,
        partido: PARTIDOS[electo.cod_partido]?.name || electo.partido || '',
        order: i + 1,
        imagen: electo.imagen || '/userProfile.png',
      }))
    }

    return jointCandidatosColor(orderedGores, ElectionType.GORE_2V).map((electo, i) => ({
      ...electo,
      order: i + 1,
      pacto: PACTOS[ElectionType.GORE_2V][electo.cod_pacto]?.shortName || electo.cod_pacto,
      partido: PARTIDOS[electo.cod_partido]?.name || electo.partido || '',
      imagen: electo.imagen || '/userProfile.png',
      primera_vuelta:
        (ELECTOS_1V_REGIONES.includes(currentRegion.toString()) && electo.electo) || false,
    }))
  }, [orderedGores, currentRegion])

  const participacionRegion = useMemo(() => {
    if (!participacion2V || !region || !participacion1V || !currentRegion) return undefined
    const firstRound = ELECTOS_1V_REGIONES.includes(currentRegion.toString())
    const participacionTotal = region.comunas.reduce(
      (acc, comuna) => {
        const participacionComuna = (firstRound ? participacion1V : participacion2V)
          .resultados_comuna[comuna]
        return {
          votes: acc.votes + (participacionComuna?.votos || 0),
          blankVotes: acc.blankVotes + (participacionComuna?.votos_blancos || 0),
          nullVotes: acc.nullVotes + (participacionComuna?.votos_nulos || 0),
          totalVoters: acc.totalVoters + (participacionComuna?.inscritos || 0),
        }
      },
      {
        votes: 0,
        blankVotes: 0,
        nullVotes: 0,
        totalVoters: 0,
      }
    )
    return {
      percentage: `${((participacionTotal.votes * 100) / participacionTotal.totalVoters)
        .toFixed(2)
        .replace('.', ',')}%`,
      votes: numberWithDots(participacionTotal.votes),
      blankVotes: numberWithDots(participacionTotal.blankVotes),
      nullVotes: numberWithDots(participacionTotal.nullVotes),
      totalVoters: numberWithDots(participacionTotal.totalVoters),
    }
  }, [currentRegion, participacion1V, participacion2V, region])

  useEffect(() => {
    if (resultados && currentRegion && gore1V) {
      const firstRound = ELECTOS_1V_REGIONES.includes(currentRegion.toString())
      const colorDict = getTerritorioColorDictByMajority(
        firstRound ? gore1V : resultados,
        currentRegion as number,
        ElectionType.GORE_2V
      )
      setComunaColor(colorDict)
    }
  }, [resultados, currentRegion, setComunaColor, gore1V])

  return (
    <Versus2V
      title={region?.nombreCorto || region?.nombre || ''}
      data={processedData}
      regionView={true}
      linkToFirstRound={linkToFirstRound}
      participation={participacionRegion}
    />
  )
}
