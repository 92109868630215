import { FileChileEnDatos } from '@/constants/chileEnDatos'
import { ElectionType } from '@/types/Elections'
import {
  AlcaldesFile,
  ConcejalesFile,
  COREFile,
  CurrentFile,
  GOREFile,
  Alcaldes2021,
  Concejales2021,
  Core2021,
  Gore2021,
  EscanosFile,
  ParticipationFile,
  CurrentFile2V,
} from '@/types/Files'
import { useQuery } from '@tanstack/react-query'
import ky from 'ky'
import { useEffect } from 'react'
import { create } from 'zustand'

const API_PATH = import.meta.env.VITE_API_URL
const API_CHILE_EN_DATOS = import.meta.env.VITE_API_CHILE_EN_DATOS
const CURRENT_PATH = import.meta.env.VITE_API_CURRENT_PATH
const CURRENT_2DA_VUELTA_PATH = import.meta.env.VITE_API_CURRENT_2DA_VUELTA

function getFile<T>({ path, chileEnDatos = false }: { path?: string; chileEnDatos?: boolean }) {
  if (!path) return

  const finalPath = chileEnDatos ? `${API_CHILE_EN_DATOS}/${path}` : `${API_PATH}/${path}`

  return () =>
    ky<T>(finalPath, {
      method: 'GET',
    }).json()
}

export type ElectionDataState = {
  files: {
    current?: CurrentFile
    [ElectionType.ALCALDES]?: AlcaldesFile
    [ElectionType.CONCEJALES]?: ConcejalesFile
    [ElectionType.CORE]?: COREFile
    [ElectionType.GORE]?: GOREFile
  }
  updated_at: string
  mesas_escrutadas: string
  static: {
    escanos_concejales?: EscanosFile
    escanos_core?: EscanosFile
  }
  comparador: {
    alcaldes?: Alcaldes2021
    concejales?: Concejales2021
    core?: Core2021
    gore?: Gore2021
  }
  participation: {
    alcaldes?: ParticipationFile
    concejales?: ParticipationFile
    core?: ParticipationFile
    gore?: ParticipationFile
  }
  chile_en_datos: FileChileEnDatos
  segunda_vuelta: {
    current?: CurrentFile2V
    updated_at?: string
    participacion?: ParticipationFile
    resultados?: GOREFile
  }
}

export const useUpdateElectionData = () => {
  // PRIMERA VUELTA
  const { data: currentFile } = useQuery<CurrentFile>({
    queryKey: ['currentFile'],
    queryFn: getFile({ path: CURRENT_PATH }),
    refetchInterval: 1000,
    staleTime: 0,
    gcTime: 0,
  })

  const { data: goreData } = useQuery<GOREFile>({
    queryKey: ['goreData', currentFile?.gore],
    queryFn: getFile<GOREFile>({ path: currentFile?.gore }),
    enabled: !!currentFile?.gore,
    placeholderData: (previousData) => previousData,
  })

  const { data: alcaldesData } = useQuery<AlcaldesFile>({
    queryKey: ['alcaldesData', currentFile?.alcaldes],
    queryFn: getFile<AlcaldesFile>({ path: currentFile?.alcaldes }),
    enabled: !!currentFile?.alcaldes,
    placeholderData: (previousData) => previousData,
  })

  const { data: concejalesData } = useQuery<ConcejalesFile>({
    queryKey: ['concejalesFile', currentFile?.concejales],
    queryFn: getFile<ConcejalesFile>({ path: currentFile?.concejales }),
    enabled: !!currentFile?.concejales,
    placeholderData: (previousData) => previousData,
  })

  const { data: coreData } = useQuery<COREFile>({
    queryKey: ['coreFile', currentFile?.core],
    queryFn: getFile<COREFile>({ path: currentFile?.core }),
    enabled: !!currentFile?.core,
    placeholderData: (previousData) => previousData,
  })

  const { data: escanosConcejales } = useQuery<EscanosFile>({
    queryKey: ['escanosConcejales', currentFile?.static.escanos_concejales],
    queryFn: getFile<EscanosFile>({ path: currentFile?.static.escanos_concejales }),
    enabled: !!currentFile?.static.escanos_concejales,
  })

  const { data: escanosCore } = useQuery<EscanosFile>({
    queryKey: ['escanosCore', currentFile?.static?.escanos_core],
    queryFn: getFile<EscanosFile>({ path: currentFile?.static?.escanos_core }),
    enabled: !!currentFile?.static?.escanos_core,
  })

  const { data: alcaldes2021 } = useQuery<Alcaldes2021>({
    queryKey: ['alcaldes2021', currentFile?.comparador?.alcaldes],
    queryFn: getFile<Alcaldes2021>({ path: currentFile?.comparador?.alcaldes }),
    enabled: !!currentFile?.comparador?.alcaldes,
  })

  const { data: gore2021 } = useQuery<Gore2021>({
    queryKey: ['gore2021', currentFile?.comparador?.gore],
    queryFn: getFile<Gore2021>({ path: currentFile?.comparador?.gore }),
    enabled: !!currentFile?.comparador?.gore,
  })

  const { data: concejales2021 } = useQuery<Concejales2021>({
    queryKey: ['concejales2021', currentFile?.comparador?.concejales],
    queryFn: getFile<Concejales2021>({ path: currentFile?.comparador?.concejales }),
    enabled: !!currentFile?.comparador?.concejales,
  })

  const { data: core2021 } = useQuery<Core2021>({
    queryKey: ['core2021', currentFile?.comparador?.core],
    queryFn: getFile<Core2021>({ path: currentFile?.comparador?.core }),
    enabled: !!currentFile?.comparador?.core,
  })

  const { data: chileEnDatos } = useQuery<FileChileEnDatos>({
    queryKey: ['chileEnDatos'],
    queryFn: getFile<FileChileEnDatos>({
      path: 'current_images_chile_en_datos.json',
      chileEnDatos: true,
    }),
  })

  const { data: participationAlcaldes } = useQuery<ParticipationFile>({
    queryKey: ['participationAlcaldes', currentFile?.participacion?.alcaldes],
    queryFn: getFile<ParticipationFile>({ path: currentFile?.participacion?.alcaldes }),
    enabled: !!currentFile?.participacion?.alcaldes,
  })

  const { data: participationConcejales } = useQuery<ParticipationFile>({
    queryKey: ['participationConcejales', currentFile?.participacion?.concejales],
    queryFn: getFile<ParticipationFile>({ path: currentFile?.participacion?.concejales }),
    enabled: !!currentFile?.participacion?.concejales,
  })

  const { data: participationCore } = useQuery<ParticipationFile>({
    queryKey: ['participationCore', currentFile?.participacion?.core],
    queryFn: getFile<ParticipationFile>({ path: currentFile?.participacion?.core }),
    enabled: !!currentFile?.participacion?.core,
  })

  const { data: participationGore } = useQuery<ParticipationFile>({
    queryKey: ['participationGore', currentFile?.participacion?.gore],
    queryFn: getFile<ParticipationFile>({ path: currentFile?.participacion?.gore }),
    enabled: !!currentFile?.participacion?.gore,
  })

  // SEGUNDA VUELTA

  const { data: currentFile2V } = useQuery<CurrentFile2V>({
    queryKey: ['currentFile2V'],
    queryFn: getFile({ path: CURRENT_2DA_VUELTA_PATH }),
    refetchInterval: 1000,
    staleTime: 0,
    gcTime: 0,
  })

  const { data: participation2V } = useQuery<ParticipationFile>({
    queryKey: ['participation2V', currentFile2V?.participacion],
    queryFn: getFile<ParticipationFile>({ path: currentFile2V?.participacion }),
    enabled: !!currentFile2V?.participacion,
  })

  const { data: resultados2V } = useQuery<GOREFile>({
    queryKey: ['resultados2V', currentFile2V?.resultados],
    queryFn: getFile<GOREFile>({ path: currentFile2V?.resultados }),
    enabled: !!currentFile2V?.resultados,
  })

  useEffect(() => {
    useElectionData.setState({
      files: {
        current: currentFile,
        alcaldes: alcaldesData,
        concejales: concejalesData,
        core: coreData,
        gore: goreData,
      },
      updated_at: currentFile?.updated_at,
      mesas_escrutadas: currentFile?.mesas_escrutadas,
      static: {
        escanos_concejales: escanosConcejales,
        escanos_core: escanosCore,
      },
      comparador: {
        alcaldes: alcaldes2021,
        concejales: concejales2021,
        core: core2021,
        gore: gore2021,
      },
      chile_en_datos: chileEnDatos,
      participation: {
        alcaldes: participationAlcaldes,
        concejales: participationConcejales,
        core: participationCore,
        gore: participationGore,
      },
      segunda_vuelta: {
        current: currentFile2V,
        updated_at: currentFile2V?.updated_at,
        participacion: participation2V,
        resultados: resultados2V,
      },
    })
  }, [
    // PRIMERA VUELTA
    currentFile,
    alcaldesData,
    concejalesData,
    coreData,
    goreData,
    escanosConcejales,
    escanosCore,
    alcaldes2021,
    concejales2021,
    core2021,
    gore2021,
    chileEnDatos,
    participationAlcaldes,
    participationConcejales,
    participationCore,
    participationGore,
    // SEGUNDA VUELTA
    currentFile2V,
    participation2V,
    resultados2V,
  ])
}

const useElectionData = create<ElectionDataState>((set) => ({
  files: {},
  updated_at: '',
  mesas_escrutadas: '',
  static: {},
  comparador: {},
  chile_en_datos: {},
  participation: {},
  segunda_vuelta: {},
}))

export default useElectionData
