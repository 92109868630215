import MReelecto from '@/components/MagicWall/GORE/Reelecto'
import { PACTOS_GORE } from '@/constants'
import { formatFullName } from '@/helpers/formatters'
import useElectionData from '@/stores/useElectionData'
import { regiones } from '@/stores/useTerritorios'
import { useMemo } from 'react'
import gore2021 from '@/assets/files/gore2021.json'

export default function GReelecto() {
  const {
    files: { gore },
  } = useElectionData()

  const data = useMemo(() => {
    if (!gore) return []
    const electos = gore
      ?.filter((candidato) => candidato.reelecto)
      .map((electo) => {
        const pacto = PACTOS_GORE[electo.cod_pacto] || {}
        const electo2021 = gore2021[electo.cod_region.toString() as keyof typeof gore2021] || {}

        const percentage2021 = electo2021?.porcentaje || 0
        const region = regiones.find((region) => region.codigo === electo.cod_region)
        return {
          region_code: Number(electo.cod_region) || 0,
          region: region?.nombreCorto || region?.nombre || electo?.region || '',
          region_pacto: pacto.shortName || electo?.cod_pacto || '',
          color: pacto.color || '',
          imagen: electo.imagen || '/userProfile.png',
          electo_2024: {
            nombre: formatFullName(electo.nombre || ''),
            imagen: electo.imagen || '/userProfile.png',
            color: pacto.color || '',
            partido: electo.cod_partido,
          },
          porcentaje_2024: (electo?.porcentaje || 0).toFixed(1).replace('.', ',') + '%',
          porcentaje_2021: percentage2021.toFixed(1).replace('.', ',') + '%',
        }
      })
    return electos
  }, [gore])

  const regionesData = regiones
    .filter((region) => region.codigo !== 17)
    .sort((a, b) => {
      if (a.orden && b.orden) {
        return a.orden - b.orden
      }
      return 0
    })
    .map((region) => ({
      code: region.codigo,
      name: region.acronimo || region.nombre,
      selectedColor: data.find((item) => item.region_code === region.codigo)?.color || '#B1B1BB',
    }))
  return <MReelecto pageSize={4} data={data} regiones={regionesData} />
}
