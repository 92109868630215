import useRouting from '@/stores/useRouting'
import useTerritorios, { comunas, regiones } from '@/stores/useTerritorios'
import { useEffect, useMemo } from 'react'
import useElectionData from '@/stores/useElectionData'
import { CandidatoGore } from '@/types/Files'
import { jointCandidatosColor } from '@/lib/dataProcess'
import { ElectionType } from '@/types/Elections'
import { PACTOS } from '@/constants'
import Versus2V from '@/components/Web/GORE/Versus2V'
import { ELECTOS_1V_REGIONES } from '@/constants'
import { PARTIDOS } from '@/constants/pactos'
import ComunaSelector from '@/components/ComunaSelector'
import RegionSelector from '@/components/RegionSelector'
import { useNavigate } from 'react-router-dom'
import { getRealRegionCode } from './2VElectosRegion'

export function getGOREResultadosComuna(candidatos: CandidatoGore[], comuna: number) {
  return candidatos.map((candidato) => ({
    ...candidato,
    votos: candidato.resultados_comuna[comuna.toString()]?.votos || 0,
    porcentaje: candidato.resultados_comuna[comuna.toString()]?.porcentaje || 0,
  }))
}

export default function GElectosComuna() {
  const { currentComuna, currentRegion } = useRouting()
  const comuna = comunas.find((comuna) => {
    return comuna.codigo === currentComuna
  })
  const navigate = useNavigate()
  const {
    segunda_vuelta: { resultados },
    files: { gore: gore1V },
  } = useElectionData()
  const { setComunaColor } = useTerritorios()

  const regionGores = useMemo(() => {
    if (!currentRegion || !resultados || !gore1V) return []
    const firstRound = ELECTOS_1V_REGIONES.includes(currentRegion.toString())
    return (firstRound ? gore1V : resultados).filter(
      (c) => c.cod_region.toString() === (getRealRegionCode(currentRegion) ?? '').toString()
    )
  }, [currentRegion, resultados, gore1V])

  const processedData = useMemo(() => {
    if (regionGores.length === 0 || !currentComuna || !currentRegion) return []
    const orderedComunaGores = getGOREResultadosComuna(regionGores, currentComuna as number).sort(
      (a, b) => b.porcentaje - a.porcentaje
    )

    return jointCandidatosColor(orderedComunaGores, ElectionType.GORE_2V).map((electo, i) => ({
      ...electo,
      pacto: PACTOS[ElectionType.GORE][electo.cod_pacto]?.shortName || electo.cod_pacto,
      order: i + 1,
      imagen: electo.imagen || '/userProfile.png',
      partido: PARTIDOS[electo.cod_partido]?.name || electo.partido || '',
      primera_vuelta:
        (ELECTOS_1V_REGIONES.includes(currentRegion.toString()) && electo.electo) || false,
    }))
  }, [regionGores, currentComuna, currentRegion])

  useEffect(() => {
    if (processedData.length > 0) {
      const goreElecto = processedData[0]
      setComunaColor({
        [currentComuna as number]: goreElecto.porcentaje > 0 ? goreElecto.color : '',
      })
    }
  }, [setComunaColor, processedData, currentComuna])

  const handleRegion = (region: string) => {
    const defaultComuna =
      regiones
        .find(({ codigo }) => codigo.toString() === region.toString())
        ?.comunaDefecto?.toString() || ''
    navigate(`/${ElectionType.GORE_2V}/territorio/${region}/${defaultComuna}`)
  }

  const handleComuna = (comuna: string) => {
    navigate(`/${ElectionType.GORE_2V}/territorio/${currentRegion}/${comuna}`)
  }

  return (
    <Versus2V
      title={comuna?.nombre || ''}
      data={processedData}
      regionView={false}
      selection={
        <div className="w-full xl:mt-[-5px] ">
          <div className="flex gap-3 w-full">
            <RegionSelector
              className="w-[50%] xl:flex hidden"
              onClick={handleRegion}
              value={String(currentRegion)}
            />
            <ComunaSelector
              className="w-[50%] xl:flex hidden"
              region={currentRegion}
              onClick={handleComuna}
              value={String(currentComuna)}
              eleccion={ElectionType.GORE_2V}
            />
          </div>
        </div>
      }
    />
  )
}
