import { useEffect, useMemo, useState } from 'react'
import RegionSelector from '@/components/RegionSelector'
import ComunaSelector from '@/components/ComunaSelector'
import useRouting from '@/stores/useRouting'
import { comunas, regiones } from '@/stores/useTerritorios'
import { useNavigate } from 'react-router-dom'
import useElectionData from '@/stores/useElectionData'
import styled from 'styled-components'
import { colors } from '@/constants'
import { LoaderCircle } from 'lucide-react'

export default function DatosComunales() {
  const { currentComuna, currentTema, currentSubtema } = useRouting()
  const { chile_en_datos } = useElectionData()
  const navigate = useNavigate()

  const regionValue = comunas.find((comuna) => comuna.codigo === currentComuna)?.region

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
  }, [currentComuna, currentSubtema, currentTema])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 3500)

    return () => clearTimeout(timeout)
  }, [])

  const graph = useMemo(() => {
    if (currentTema && currentSubtema && currentComuna && chile_en_datos) {
      return (
        chile_en_datos[currentTema]?.[currentSubtema]?.[currentComuna.toString()]?.link_img_web ||
        ''
      )
    }
  }, [currentComuna, currentSubtema, currentTema, chile_en_datos])

  const list_graph = useMemo(() => {
    if (currentTema && currentSubtema && currentComuna && chile_en_datos) {
      return Object.keys(chile_en_datos[currentTema] || {}).map(
        (subtema) => chile_en_datos[currentTema][subtema][currentComuna]?.link_img_web || ''
      )
    }
  }, [currentComuna, currentSubtema, currentTema, chile_en_datos])

  const handleRegionChange = (region: string) => {
    const defaultComuna = regiones.find((r) => r.codigo === Number(region))?.comunaDefecto || 13101
    navigate(`/datos-comunales/${currentTema}/${currentSubtema}/${defaultComuna}`)
  }

  const handleComunaChange = (comuna: string) => {
    navigate(`/datos-comunales/${currentTema}/${currentSubtema}/${comuna}`)
  }

  const imageLoaded = () => setLoading(false)

  return (
    <div className="w-vw xl:w-[870px] ">
      <div className="w-full flex gap-3">
        <RegionSelector
          className="w-[50%] xl:w-[30%]"
          useGS={false}
          value={regionValue?.toString()}
          onClick={handleRegionChange}
        />
        <ComunaSelector
          className="w-[50%] xl:w-[30%]"
          value={currentComuna?.toString()}
          region={regionValue}
          onClick={handleComunaChange}
          eleccion="datos-comunales"
        />
      </div>
      <div className="xl:h-[433px] h-auto">
        {loading && (
          <div className="w-full xl:h-full items-center justify-center flex h-[433px]">
            <div className="animate-spin">
              <LoaderCircle size={35} />
            </div>
          </div>
        )}
        <div className="hidden xl:block">
          {graph ? (
            <ImgWrapper $loading={loading} className="mt-4">
              <Img src={graph} alt="graph" onLoad={imageLoaded} />
            </ImgWrapper>
          ) : (
            <Text
              $loading={loading}
              className="w-full h-full flex items-center justify-center text-xl font-medium pb-20 mt-10"
            >
              No hay gráfico disponible
            </Text>
          )}
        </div>
        <div className="flex flex-col gap-3 mt-3 xl:hidden">
          {list_graph && list_graph?.length > 0 ? (
            list_graph.map((graph, index) => (
              <ImgWrapper $loading={loading} key={index}>
                <Img src={graph} alt="graph" onLoad={imageLoaded} />
              </ImgWrapper>
            ))
          ) : (
            <Text
              $loading={loading}
              className="w-full h-full flex items-center justify-center text-xl font-medium pb-20"
            >
              No hay gráfico disponible
            </Text>
          )}
        </div>
      </div>
    </div>
  )
}

const ImgWrapper = styled.div.attrs<{ $loading: boolean }>({
  className: 'xl:h-full xl:max-h-[820px] xl:min-w-[700px] w-vw',
})`
  display: ${({ $loading }) => ($loading ? 'none' : 'flex')};
`
const Img = styled.img`
  max-height: 100%;
`

const Text = styled.div<{ $loading: boolean }>`
  color: ${colors.blackDch};
  display: ${({ $loading }) => ($loading ? 'none' : 'flex')};
`
