import styled from 'styled-components'
import { colors } from '@/constants'
import { ReactNode } from 'react'

type PactoInformation = {
  color: string
  electos: number
  name: string
  children?: ReactNode
  includeVotes?: boolean
}

export default function PactoInformation({
  color,
  electos,
  name,
  children,
  includeVotes = true,
}: PactoInformation) {
  return (
    <WrapperPacto>
      <ElectosPacto color={color}>{electos}</ElectosPacto>
      <PactoName>{name}</PactoName>
      {includeVotes && (
        <>
          <HSeparatorPactos />
          {children}
        </>
      )}
    </WrapperPacto>
  )
}

const WrapperPacto = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const ElectosPacto = styled.div.attrs<{ color: string }>({
  className: 'mgw:text-[48px] text-[35px]',
})`
  font-weight: 700;
  color: ${({ color }) => color};
`

const PactoName = styled.div.attrs({
  className: 'mgw:text-[20px] mgw:leading-[26px] leading-[20px] text-[17px]',
})`
  height: 100%;
  padding-bottom: 12px;
  letter-spacing: 0.8px;
  font-weight: 500;
  color: ${colors.blackDch};
`

const HSeparatorPactos = styled.div.attrs({
  // className: 'mgw:mx-[-15px] mx-[-8px]',
})`
  height: 100%;
  border-bottom: 2.5px solid ${colors.gray2Dch};
`
