import Comparacion, { type Data } from '@/components/Web/Comparacion'
import useElectionData from '@/stores/useElectionData'
import { useMemo } from 'react'
import { getResultsByPacto } from '@/lib/dataProcess'
import { buildComparacionData } from '@/lib/buildDataViews'
import { ElectionType } from '@/types/Elections'
import { getResultsByPacto2021AG } from '@/lib/dataProcess2021'
import { ELECTOS_1V, ELECTOS_1V_PACTOS } from '@/constants'

export default function AComparacion() {
  const {
    segunda_vuelta: { resultados: resultados2V },
    comparador: { gore: gore2021 },
  } = useElectionData()

  const data: Data[] = useMemo(() => {
    let resultsByPacto2V = getResultsByPacto(resultados2V || [])
    if (Object.keys(resultsByPacto2V).length === 0) {
      resultsByPacto2V = ELECTOS_1V_PACTOS.reduce((acc, cod_pacto) => {
        if (!acc[cod_pacto]) {
          acc[cod_pacto] = { electos: 0, votos: 0 }
        }
        acc[cod_pacto].electos += ELECTOS_1V[cod_pacto]?.electos || 0
        return acc
      }, {} as Record<string, { [key: string]: number }>)
    } else {
      ELECTOS_1V_PACTOS.forEach((cod_pacto) => {
        resultsByPacto2V[cod_pacto].electos += ELECTOS_1V[cod_pacto]?.electos || 0
      })
    }
    const resultsByPacto2021 = gore2021 ? getResultsByPacto2021AG(gore2021) : {}
    resultsByPacto2021['RVL'] = 1
    return buildComparacionData(resultsByPacto2V, resultsByPacto2021, ElectionType.GORE_2V, false)
  }, [resultados2V, gore2021])

  return <Comparacion data={data} verticalSeparatorBefore="Otros" />
}
