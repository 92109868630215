import useIframeSync from '@/hooks/useIframeSync'
import { useRef } from 'react'
import LogoDCh from '@/assets/logoDch.svg'
import styled from 'styled-components'
import useRouting from '@/stores/useRouting'
import Header from './Header'
import { Link } from 'react-router-dom'
import { cn } from '@/lib/utils'
import ReactGA from 'react-ga4'

type Layout = {
  children: React.ReactNode
}

ReactGA.initialize(import.meta.env.VITE_GA_MEASUREMENT_ID)

export default function Layout({ children }: Layout) {
  const { isIframe } = useRouting()
  const ref = useRef(null)
  useIframeSync({ parentRef: ref })

  return (
    <Wrapper className="xl:px-[34px]">
      {!isIframe && (
        <Up className="md:flex block">
          <Link
            className="md:block hidden py-2 px-[20px]"
            to="https://decidechile.cl"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => ReactGA.event('live2024_decidechile_redireccion')}
          >
            <LogoDCh />
          </Link>
          <Link
            className="md:my-2"
            to="https://www.unholster.com/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => ReactGA.event('live2024_unholster_redireccion')}
          >
            <img className="w-full" src="banner.gif" alt="banner" />
          </Link>
          <Link
            className="md:hidden py-4"
            to="https://decidechile.cl"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => ReactGA.event('live2024_decidechile_redireccion')}
          >
            <div className="py-4 px-[20px]">
              <LogoDCh />
            </div>
          </Link>
        </Up>
      )}
      {!isIframe && <Header />}
      <Content
        ref={ref}
        className={cn('w-[95%] xl:w-[100%]', {
          'xl:my-16 my-4': !isIframe,
          'my-0': isIframe,
        })}
      >
        {children}
      </Content>
    </Wrapper>
  )
}

const Up = styled.div`
  width: 100%;
  height: fit-content;
  text-align: center;
  max-width: 1350px;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
`

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 1350px;
  align-items: center;
  overflow: auto;
`
