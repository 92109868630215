import {
  Select,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectContent,
} from '@/components/ui/select'
import ReactGA from 'react-ga4'
import { Link as LinkRouter, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

ReactGA.initialize(import.meta.env.VITE_GA_MEASUREMENT_ID)

export const NAV_LINKS = [
  {
    href: '/segunda_vuelta',
    key: 'segunda_vuelta',
    label: '2V Gores',
    matchRegex: /^\/segunda_vuelta(\/.*)?$/g,
    keyGA: 'live2024_vista_2v_gores_menu',
  },
  {
    href: '/gore',
    key: 'gore',
    label: '1V Gores',
    matchRegex: /^\/gore(\/.*)?$/g,
    keyGA: 'live2024_vista_gores_menu',
  },
  {
    href: '/alcaldes',
    key: 'alcaldes',
    label: 'Alcaldes',
    matchRegex: /^\/alcaldes(\/.*)?$/g,
    keyGA: 'live2024_vista_alcaldes_menu',
  },
  {
    href: '/concejales',
    key: 'concejales',
    label: 'Concejales',
    matchRegex: /^\/concejales(\/.*)?$/g,
    keyGA: 'live2024_vista_concejales_menu',
  },
  {
    href: '/core',
    key: 'core',
    label: 'Consejeros Regionales',
    matchRegex: /^\/core(\/.*)?$/g,
    keyGA: 'live2024_vista_cores_menu',
  },
  {
    href: '/datos-comunales',
    key: 'datos-comunales',
    label: 'Datos Comunales',
    matchRegex: /^\/datos-comunales(\/.*)?$/g,
    keyGA: 'live2024_vista_datoscomunales_menu',
  },
  {
    href: 'https://www.decidechile.cl/',
    key: 'analisis_electoral',
    label: 'Análisis Electoral',
    matchRegex: /nomatch$/g,
    newTab: true,
    keyGA: 'live2024_vista_analisis_menu',
  },
]

const SelectorMenu = ({
  currentLink,
  links,
  placeholder,
  onClick,
}: {
  currentLink: string
  links: { href: string; key: string; label: string }[]
  placeholder: string
  onClick: (value: string) => void
}) => {
  return (
    <Select onValueChange={onClick} defaultValue={currentLink} value={currentLink}>
      <SelectTrigger className="w-[190px] xl:hidden shadow-none border-none focus:ring-0 text-[#3C3C3C]">
        <SelectValue className="border-b border-black" placeholder={placeholder} />
      </SelectTrigger>
      <SelectContent>
        {links.map(({ href, key, label }) => (
          <SelectItem className="text-[#3C3C3C]" key={key} value={key}>
            <div>{label}</div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

const ButtonMenu = ({
  className,
  currentLink,
  links,
}: {
  className?: string
  currentLink: string
  links: { href: string; key: string; label: string; newTab?: boolean; keyGA: string }[]
}) => {
  const itemProps = ({ key, newTab }: { key: string; newTab?: boolean }) => {
    const defaultProps = {
      active: (currentLink === key).toString(),
      target: '',
      rel: '',
    }
    if (newTab) {
      defaultProps['target'] = '_blank'
      defaultProps['rel'] = 'noopener noreferrer'
    }
    return defaultProps
  }
  return (
    <ButtonMenuWrapper className={className}>
      {links.map(({ href, key, label, newTab, keyGA }) => (
        <Link
          to={href}
          onClick={() => ReactGA.event(keyGA)}
          key={key}
          {...itemProps({ key, newTab })}
        >
          {label}
        </Link>
      ))}
    </ButtonMenuWrapper>
  )
}

export default function NavigationLinks({ links = NAV_LINKS }) {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const selectedValue = links.find(({ matchRegex }) => pathname.match(matchRegex))?.key

  const handleSelectorClick = (value: string) => {
    const link = links.find(({ key }) => key === value)
    if (link) {
      ReactGA.event(link.keyGA)
      if (link.newTab) {
        window.location.href = link.href
        return null
      }
      navigate(link.href)
    }
  }

  return (
    <Wrapper>
      <ButtonMenu currentLink={selectedValue || ''} links={links} />
      <SelectorMenu
        onClick={handleSelectorClick}
        currentLink={selectedValue || ''}
        links={NAV_LINKS}
        placeholder=""
      />
    </Wrapper>
  )
}

const ButtonMenuWrapper = styled.div.attrs({
  className: 'hidden xl:flex',
})`
  align-items: center;
  justify-content: flex-start;
  gap: 2em;
`

const Wrapper = styled.div``

const Link = styled(LinkRouter)<{ active: string }>`
  color: ${({ active }) => (active === 'true' ? '#2150E8' : '#555555')};

  font-weight: 400;
  font-size: 14px;
  :hover {
    font-weight: 400;
    color: ${'#2150E8'};
  }
`
