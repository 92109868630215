import HorizontalMap from '@/components/HorizontalMap'
import MPactos, { type PactoResult } from '@/components/MagicWall/Pactos'
import { colors, PACTOS } from '@/constants'
import { regiones } from '@/stores/useTerritorios'
import styled from 'styled-components'
import useElectionData from '@/stores/useElectionData'
import { useMemo } from 'react'
import { ElectionType } from '@/types/Elections'
import { getResultsByPacto } from '@/lib/dataProcess'
import { buildPactosViewData } from '@/lib/buildDataViews'
import {
  ELECTOS_1V,
  ELECTOS_1V_PACTOS,
  ELECTOS_1V_REGIONES,
  ELECTOS_1V_REGION_PACTO,
} from '@/constants'

export default function Pactos() {
  const {
    segunda_vuelta: { resultados: resultados2V },
  } = useElectionData()

  const data: PactoResult[] = useMemo(() => {
    const resultByPactos = getResultsByPacto(resultados2V || [])
    const pactosViewData = buildPactosViewData(resultByPactos, ElectionType.GORE_2V, false)
    return pactosViewData.map((pacto) => {
      if (!ELECTOS_1V_PACTOS.includes(pacto.abreviatura)) return pacto
      const selected1V = ELECTOS_1V[pacto.abreviatura] || {}
      return {
        ...pacto,
        electos: pacto.electos + selected1V?.electos,
        electos_partido: pacto.electos_partido
          .map((partido) => ({
            ...partido,
            electos: partido.electos + selected1V?.partidos[partido.cod_partido] || partido.electos,
          }))
          .sort((a, b) => b.electos - a.electos),
      }
    })
  }, [resultados2V])

  // Set color of each region but GS
  const regionesData = useMemo(() => {
    const orderedRegiones = regiones
      // Remove GS
      .filter((region) => region.codigo !== 17)
      // Sort by order of appearance in the map
      .sort((a, b) => {
        if (a.orden && b.orden) {
          return a.orden - b.orden
        }
        return 0
      })

    return orderedRegiones.map((region) => {
      // Check if region has an electo
      const electosRegion = (resultados2V || []).filter(
        (electo) => Number(electo.cod_region) === Number(region.codigo) && electo.electo
      )

      const regionElectos1V = ELECTOS_1V_REGIONES.includes(region.codigo.toString())

      let selectedColor = ''
      if (electosRegion.length === 1) {
        selectedColor = PACTOS[ElectionType.GORE_2V][electosRegion[0]?.cod_pacto]?.color || ''
      }
      if (regionElectos1V) {
        selectedColor =
          PACTOS[ElectionType.GORE_2V][ELECTOS_1V_REGION_PACTO[region.codigo]]?.color || ''
      }

      return {
        name: region.acronimo || region.nombre,
        selectedColor,
        code: region.codigo,
        reverseBorderBackground: regionElectos1V,
      }
    })
  }, [resultados2V])

  return (
    <MPactos
      className="mgw:py-14 mgw:px-6 py-12 px-5"
      election="Gobernadores"
      data={data}
      verticalSeparatorBefore="Independientes"
      colVerticalSeparatorBefore={1}
      numberOfPartidosbyColumn={7}
      minWidthPactos="100px"
      minWidthPartidos="100px"
      includeVotes={false}
    >
      <div className="mgw:px-[80px] px-[50px]">
        <HorizontalMap
          selectionProperty="cod_comuna"
          position="center"
          regionResults={regionesData}
        />
        <div className="flex w-full h-full justify-center gap-2 items-center">
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="10.5"
              cy="10.5"
              r="9.18979"
              fill="#FAFAFA"
              stroke="#4F4F4F"
              stroke-width="2.62042"
            />
          </svg>
          <Text>Electos en 1ra V.</Text>
        </div>
      </div>
    </MPactos>
  )
}

const Text = styled.div`
  font-size: 18px;
  color: ${colors.blackDch};
  font-weight: 500;
  text-transform: uppercase;
`
