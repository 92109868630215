import IfrHorizontalMap from '@/components/Iframe/IfrHorizontalMap'
import PactoInfo from '@/components/Iframe/PactoInfo'
import { colors } from '@/constants/colors'
import { PACTOS_GORE } from '@/constants/pactos'
import { Fragment } from 'react/jsx-runtime'
import styled from 'styled-components'
import { getPactosSummary } from '@/helpers/iframe'
import { CandidatoGore } from '@/types/Files'
import { regiones } from '@/stores/useTerritorios'
import { useMemo } from 'react'

export default function GobernadoresPactos({ summaryGore }: { summaryGore: CandidatoGore[] }) {
  const pactosSummary = getPactosSummary(summaryGore)

  // Set color of each region but GS
  const regionesData = useMemo(() => {
    const orderedRegiones = regiones
      // Remove GS
      .filter((region) => region.codigo !== 17)
      // Sort by order of appearance in the map
      .sort((a, b) => {
        if (a.orden && b.orden) {
          return a.orden - b.orden
        }
        return 0
      })

    return orderedRegiones.map((region) => {
      // Check if region has an electo
      const electosRegion = (summaryGore || []).filter(
        (electo) => Number(electo.cod_region) === Number(region.codigo) && electo.electo
      )

      const selectedColor =
        electosRegion.length === 1
          ? // If there is only one electo, get the color of the pacto
            PACTOS_GORE[electosRegion[0].cod_pacto].color
          : // if no data, use gray, else empty string for 2 vuelta
            ''
      return {
        name: region.acronimo || region.nombre,
        selectedColor,
        code: region.codigo,
      }
    })
  }, [summaryGore])

  return (
    <Wrapper>
      <IfrHorizontalMap
        selectionProperty="cod_comuna"
        position="center"
        regionResults={regionesData}
      />
      <div className="flex relative gap-[8px] sifr:gap-[12px] ifr:gap-[18px] gap-y-[16px] sifr:gap-y-[32px] ifr:gap-y-[44px] flex-wrap justify-center">
        {Object.entries(pactosSummary).map(([codigoPacto, info], index) => (
          <Fragment key={`pactoGore-${codigoPacto}`}>
            <PactoInfo
              color={PACTOS_GORE[codigoPacto].color}
              electos={info.electos}
              shortName={PACTOS_GORE[codigoPacto].shortName}
              votos={info.votos}
            />
            {index === 8 && <VerticalDivider />}
          </Fragment>
        ))}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div.attrs({
  className:
    'gap-[20px] sifr:gap-[32px] ifr:gap-[40px] flex flex-col flex-grow justify-center items-center',
})``

const VerticalDivider = styled.div.attrs({
  className: `h-[70px] sifr:h-[100px] ifr:h-[124px]
    top-[118px] ifr:top-[150px]
    right-[176px] ifr:right-[240px]`,
})`
  width: 1px;
  background-color: ${colors.gray5Dch};
`
